class IndexedDBStorage {
    databaseName = "identity-db";
    storeName = "identity";
    get(key) {
        const self = this;
        return new Promise((resolve, reject) => {
            self.openDatabase().then(function (db) {
                const transaction = db.transaction(self.storeName, "readwrite");
                const objectStore = transaction.objectStore(self.storeName);
                const getRequest = objectStore.get(key);
                getRequest.onsuccess = () => {
                    resolve(getRequest.result);
                };
                getRequest.onerror = () => {
                    reject(getRequest.error);
                };
                transaction.oncomplete = () => {
                    db.close();
                };
            });
        });
    }
    async remove(key) {
        const self = this;
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.databaseName);
            request.onupgradeneeded = function (event) {
                const db = event.target.result;
                db.createObjectStore(self.storeName);
            };
            request.onerror = () => {
                reject(request.error);
            };
            request.onsuccess = (event) => {
                const db = event.target.result;
                const transaction = db.transaction(this.storeName, "readwrite");
                const objectStore = transaction.objectStore(this.storeName);
                const deleteRequest = objectStore.delete(key);
                deleteRequest.onsuccess = () => {
                    resolve(null);
                };
                deleteRequest.onerror = () => {
                    reject(deleteRequest.error);
                };
                transaction.oncomplete = () => {
                    db.close();
                };
            };
        });
    }
    set(key, value) {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.databaseName);
            request.onerror = () => {
                reject(request.error);
            };
            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                db.createObjectStore(this.storeName);
            };
            request.onsuccess = (event) => {
                const db = event.target.result;
                const transaction = db.transaction(this.storeName, "readwrite");
                const objectStore = transaction.objectStore(this.storeName);
                const setRequest = objectStore.put(value, key);
                setRequest.onsuccess = () => {
                    resolve(value);
                };
                setRequest.onerror = () => {
                    reject(setRequest.error);
                };
                transaction.oncomplete = () => {
                    db.close();
                };
            };
        });
    }
    openDatabase() {
        const self = this;
        return new Promise(function (resolve, reject) {
            const request = indexedDB.open(self.databaseName, 2);
            request.onupgradeneeded = function (event) {
                const db = event.target.result;
                db.createObjectStore(self.storeName);
            };
            request.onsuccess = function (event) {
                resolve(event.target.result);
            };
            request.onerror = function (event) {
                reject(event.error);
            };
        });
    }
}
export default IndexedDBStorage;
