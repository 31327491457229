@import '../../../common/styles/variables.scss';
:host {
  display: block;
  position: relative;
  @include default-css-variables;
}

.time-picker {
  display: inline-block;
  width: 150px;
  font-family: var(--nylas-font-family);
  @media #{$mobile} {
    width: auto;
  }
  position: relative;
  input {
    width: 150px;
    height: 48px;
    text-align: center;
    font-size: 16px;
    font-family: inherit;
    @media #{$mobile} {
      width: 88px;
    }
    @media #{$small-mobile} {
      width: 68px;
      font-size: 15px;
    }
    cursor: pointer;
    background: transparent;
    border: 1px solid var(--nylas-base-200);
    border-radius: var(--nylas-border-radius-2x);
    &.focus {
      background: transparent;
    }
    &:hover,
    &:active {
      border: 1px solid var(--nylas-primary);
    }
    &:active {
      outline: 2px solid var(--nylas-primary);
    }
    span {
      &.open {
        transform: rotate(90deg);
      }
      &.closed {
        transform: rotate(270deg);
      }
    }
    &.error {
      border: 1px solid var(--nylas-error);
    }
  }
  p.error {
    color: var(--nylas-error);
    font-size: 0.875rem;
    margin: 0;
  }
}
.times {
  display: block;
  margin-top: 0.5rem;
  background-color: var(--nylas-base-0);
  width: 100%;
  max-height: 336px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
  border-radius: 4px;
  position: absolute;
  box-shadow: 0px 4px 6px -2px #0000000d;
  box-shadow: 0px 10px 15px -3px #0000001a;
  ul {
    padding: 0;
    list-style-type: none;
    color: var(--nylas-base-900);
    max-height: 336px;
    li {
      padding: 16px, 12px, 16px, 12px;
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-family: inherit;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
      cursor: pointer;
      &.focused {
        background-color: var(--nylas-base-100);
      }
      &:hover,
      &:focus {
        background-color: var(--nylas-base-100);
      }
      label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        input {
          margin: 0;
        }
      }
    }
  }
}
