import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration } from '@nylas/core';

/**
 * The `nylas-page-name` component is a UI component that allows users to add a custom page name which will appear in the top left corner of the date picker.
 *
 * @part npn - The nylas-page-name container
 * @part npn__body - The body of the event communication section
 * @part npn__header - The header of the event communication section
 * @part npn__drawer-toggle--container - The card's drawer toggle container
 * @part npn__input-textfield - The page name input textfield
 */
@Component({
  tag: 'nylas-page-name',
  styleUrl: 'nylas-page-name.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasPageName {
  @Element() host!: HTMLNylasPageNameElement;

  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @standalone
   * The name of the component
   */
  @Prop() name: string = 'page-name';
  /**
   * @standalone
   * The default page name
   */
  @Prop() pageName?: string;
  /**
   * @standalone
   * Show toggle button that allows users to expand or collapse the card
   */
  @Prop() showToggle: boolean = true;
  /**
   * @standalone
   * Is the page name container card expanded
   */
  @Prop() isOpen: boolean = true;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The current page name state
   */
  @State() currentPageName!: string;

  /**
   * This event is fired when the page name value changes
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
    valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void;
  }>;

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-page-name', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-page-name', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-page-name', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-page-name', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.currentPageName = this.pageName ?? '';
    }
  }

  componentWillUpdate() {
    debug('nylas-page-name', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-page-name', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-page-name', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-page-name', 'componentDidRender');
  }

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-page-name', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-custom-booking-flow', 'selectedConfigurationChangedHandler', newValue);
    this.currentPageName = newValue?.name ?? this.pageName ?? '';
  }

  @Listen('nylasFormInputChanged')
  async nylasFormInputChangeHandler(event: CustomEvent<{ value: string; name: string }>) {
    const { name, value } = event.detail;

    if (name === 'page-name') {
      this.currentPageName = value;
      this.internals.setFormValue(JSON.stringify(value), this.name);
      this.valueChanged.emit({ value: JSON.stringify(value), name: this.name });
    }
  }

  toggleDrawer() {
    this.isOpen = !this.isOpen;
  }

  @RegisterComponent<NylasPageName, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-page-name',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {
      valueChanged: async (
        event: CustomEvent<{ value: string; name: string; valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void }>,
        _nylasSchedulerConfigConnector: NylasSchedulerConfigConnector,
      ) => {
        const { valueChanged } = event.detail;
        // If a handler is passed, call it.
        if (valueChanged) {
          valueChanged(event);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    const isComponentReady = typeof this.currentPageName !== 'undefined';
    return (
      <Host>
        <div class="nylas-page-name" part="nces">
          <div class="header" part="nces__header">
            <div>
              <h3>Page name</h3>
              <p>
                Set the Scheduling Page name.
                <tooltip-component>
                  <info-icon slot="tooltip-icon" />
                  <span slot="tooltip-content">
                    By default, Scheduling Pages use your user name as the Page name. The Page name is displayed in the top-left corner of the calendar view.
                  </span>
                </tooltip-component>
              </p>
            </div>

            {this.showToggle && (
              <div class="drawer-toggle" part="nces__drawer-toggle--container">
                <span class={`chevron ${this.isOpen ? 'open' : 'closed'} `} onClick={() => this.toggleDrawer()}>
                  <chevron-icon width="24" height="24" />
                </span>
              </div>
            )}
          </div>

          {this.isOpen && (
            <div class="nylas-page-name__body" part="nces__body">
              <div class="nylas-page-name__section">
                <div class="nylas-page-name__row">
                  <div class="input-container">
                    <div class="nylas-page-name__input-wrapper">
                      {isComponentReady && (
                        <input-component
                          name="page-name"
                          id="page-name"
                          type="text"
                          required={false}
                          exportparts="ic_input: npn__input-textfield"
                          defaultValue={this.currentPageName ?? ''}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Host>
    );
  }
}
