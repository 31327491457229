import { Storage } from "./client/store";
import IndexedDBStorage from "./client/indexedDB";
import { sha256 } from "js-sha256";
import { Buffer } from "buffer";
import { v4 as uuid } from "uuid";
import { fetchEventSource, } from "@microsoft/fetch-event-source";
import { Base64EncodeUrl, getTimestamp } from "./helpers";
import jwtDecode from "jwt-decode";
export class NylasSessions {
    clientId;
    redirectUri;
    accessType = "online";
    domain = "http://api.nylas.com";
    versioned = false;
    Storage;
    hosted = false;
    multiAccount = false;
    loginId = "";
    constructor(config) {
        this.clientId = config.clientId;
        this.redirectUri = config.redirectUri;
        if (config.domain) {
            this.domain = config.domain;
            const versionedPart = this.domain.substring(this.domain.length - 3);
            if (versionedPart.includes("/v")) {
                this.versioned = true;
            }
        }
        if (config.multiAccount) {
            this.multiAccount = config.multiAccount;
        }
        if (config.sw) {
            const storage = new IndexedDBStorage();
            this.Storage = new Storage(storage);
        }
        else {
            this.Storage = new Storage();
        }
        if (config.accessType) {
            this.accessType = config.accessType;
        }
        if (config.loginId) {
            this.loginId = config.loginId;
        }
        if (config.hosted) {
            this.hosted = config.hosted;
        }
        this.codeExchange(null);
        // check();
        // registerServiceWorker();
    }
    // Validates access token
    async validateAccessToken(grant_id = "") {
        const grant = await this.Storage.getGrant(grant_id, this.multiAccount);
        if (!grant) {
            return false;
        }
        const { access_token } = grant;
        try {
            const response = await fetch(`${this.domain}/connect/tokeninfo?access_token=${access_token}`, {
                method: "GET",
            });
            const responseData = await response.json();
            if (!responseData.data) {
                return false;
            }
            return true;
        }
        catch (error) {
            return false;
        }
    }
    // Validates id token
    async validateIDToken(grant_id = "") {
        const grant = await this.Storage.getGrant(grant_id, this.multiAccount);
        if (!grant) {
            return false;
        }
        const { id_token } = grant;
        try {
            const response = await fetch(`${this.domain}/connect/tokeninfo?id_token=${id_token}`, {
                method: "GET",
            });
            if (response.status !== 200) {
                return false;
            }
            const responseData = await response.json();
            if (!responseData.data) {
                return false;
            }
            return true;
        }
        catch (error) {
            return false;
        }
    }
    // Gets domain of UAS
    getDomain() {
        return this.domain;
    }
    // Gets auth link
    async auth(config) {
        if (this.hosted &&
            (this.domain === window.location.origin ||
                (this.versioned && this.domain.includes(window.location.origin)))) {
            await this.hostedSetCodeChallenge();
        }
        const url = await this.generateAuthURL(config);
        if (config.popup) {
            this.popUp(url);
            return;
        }
        return url;
    }
    // Generates auth URL
    async generateAuthURL(config) {
        const codeChallenge = await this.getCodeChallege();
        let url = `${this.domain}/connect/auth?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&access_type=${this.accessType}&response_type=code`;
        if (codeChallenge) {
            url += `&code_challenge=${codeChallenge}&code_challenge_method=S256&options=rotate_refresh_token`;
        }
        if (config.provider) {
            url += `&provider=${config.provider}`;
        }
        if (config.loginHint) {
            url += `&login_hint=${config.loginHint}`;
            if (config.includeGrantScopes) {
                url += `&include_grant_scopes=${config.includeGrantScopes}`;
            }
        }
        if (config.scope) {
            url += `&scope=${config.scope.join(" ")}`;
        }
        if (config.prompt) {
            url += `&prompt=${config.prompt}`;
        }
        if (config.metadata) {
            url += `&metadata=${config.metadata}`;
        }
        if (config.state || this.loginId) {
            url += `&state=${this.loginId ? this.loginId : config.state}`;
        }
        return url;
    }
    // Generates auth URL
    async generateReauthURL(grant_id, scopes) {
        if (!grant_id) {
            throw new Error("Grant ID is required");
        }
        const userToken = await this.Storage.getUserToken(grant_id);
        const codeChallenge = await this.getCodeChallege();
        let url = `${this.domain}/connect/auth?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&access_type=${this.accessType}&response_type=code`;
        if (codeChallenge) {
            url += `&code_challenge=${codeChallenge}&code_challenge_method=S256&options=rotate_refresh_token`;
        }
        if (userToken?.provider) {
            url += `&provider=${userToken.provider}`;
        }
        if (userToken?.email) {
            url += `&login_hint=${userToken.email}`;
        }
        if (scopes) {
            url += `&scope=${scopes.join(" ")}`;
        }
        return url;
    }
    // Generates UUID code challenge
    async generateCodeChallenge() {
        const code_verifier = await this.Storage.getPKCE();
        if (code_verifier) {
            return;
        }
        const codeChallenge = uuid();
        this.Storage.setPKCE(codeChallenge);
        return;
    }
    // Gets code challenge from URL query params
    async hostedSetCodeChallenge() {
        if (!this.hosted) {
            throw console.error("Method only used with hosted flag enabled");
        }
        const params = new URLSearchParams(window.location.search);
        const codeChallenge = params.get("code_challenge");
        if (!codeChallenge) {
            const code_verifier = await this.Storage.getPKCE();
            if (code_verifier) {
                return;
            }
            console.warn("Code challenge is recomended");
            return;
        }
        this.Storage.setPKCE(codeChallenge);
    }
    // Gets code challenge from store
    async getCodeChallege() {
        if (this.hosted &&
            (this.domain === window.location.origin ||
                (this.versioned && this.domain.includes(window.location.origin)))) {
            const params = new URLSearchParams(window.location.search);
            const codeChallenge = params.get("code_challenge");
            if (!codeChallenge) {
                console.warn("Code challenge is recomended");
                return "";
            }
            return codeChallenge;
        }
        const code_verifier = await this.Storage.getPKCE();
        if (code_verifier) {
            const codeChallengeHashed = sha256(code_verifier);
            let codeChallengeEncrypted = Buffer.from(codeChallengeHashed).toString("base64");
            codeChallengeEncrypted = Base64EncodeUrl(codeChallengeEncrypted);
            return codeChallengeEncrypted;
        }
        return "";
    }
    // checks if user is logged in
    async isLoggedIn() {
        // if hosted identity isLoggedIn always returns false
        if (this.hosted &&
            (this.domain === window.location.origin ||
                (this.versioned && this.domain.includes(window.location.origin)))) {
            return false;
        }
        const tokens = await this.Storage.getUserTokens();
        if (tokens && tokens.length > 0) {
            if (this.multiAccount) {
                await this.generateCodeChallenge();
            }
            return true;
        }
        await this.generateCodeChallenge();
        return false;
    }
    // Checks if user is multi account
    isMultiAccount() {
        return this.multiAccount;
    }
    // Logs user out
    async logout() {
        const profile = await this.getProfile();
        await this.Storage.clearSession();
        const payload = { detail: profile };
        window.dispatchEvent(new CustomEvent("onLogoutSuccess", payload));
    }
    // Gets profile info from ID token
    async getProfile(grant_id = "") {
        let tok;
        if (grant_id) {
            tok = await this.Storage.getUserToken(grant_id);
        }
        else {
            tok = await this.Storage.getUserToken();
        }
        if (tok) {
            return tok;
        }
        return null;
    }
    // Gets profile info from ID tokens
    async getProfiles() {
        const tok = await this.Storage.getUserTokens();
        if (tok) {
            return tok;
        }
        return null;
    }
    // Remove a specific profile
    async removeProfile(id) {
        await this.Storage.removeUserToken(id);
        await this.Storage.removeGrant(id);
        return null;
    }
    // IMAP authentication
    async authIMAP(data) {
        const code_challenge = await this.getCodeChallege();
        const payload = {
            imap_username: data.imap_username,
            imap_password: data.imap_password,
            imap_host: data.imap_host,
            imap_port: data.imap_port,
            type: data.type,
            smtp_host: data.smtp_host,
            smtp_port: data.smtp_port,
            provider: data.provider,
            redirect_uri: this.redirectUri,
            state: data.state,
            public_application_id: this.clientId,
            access_type: this.accessType,
        };
        if (this.loginId) {
            payload.id = this.loginId;
        }
        if (data.smtp_username && data.smtp_password) {
            payload.smtp_username = data.smtp_username;
            payload.smtp_password = data.smtp_password;
        }
        if (code_challenge != "") {
            payload.code_challenge = code_challenge;
            payload.code_challenge_method = "S256";
        }
        const response = await fetch(`${this.domain}/connect/login/imap`, {
            method: "POST",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify(payload),
        });
        const responseData = await response.json();
        return responseData;
    }
    // EWS authentication
    async authEWS(data) {
        const code_challenge = await this.getCodeChallege();
        const payload = {
            ...data,
            provider: "ews",
            redirect_uri: this.redirectUri,
            public_application_id: this.clientId,
            access_type: this.accessType,
        };
        if (this.loginId) {
            payload.id = this.loginId;
        }
        if (code_challenge != "") {
            payload.code_challenge = code_challenge;
            payload.code_challenge_method = "S256";
        }
        const response = await fetch(`${this.domain}/connect/login/ews`, {
            method: "POST",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify(payload),
        });
        const responseData = await response.json();
        return responseData;
    }
    // Detects email
    async detectEmail(email) {
        const response = await fetch(`${this.versioned ? this.domain : this.domain + "/connect"}/providers/detect?client_id=${this.clientId}&email=${email}`, {
            method: "POST",
            headers: new Headers({ "content-type": "application/json" }),
        });
        const responseData = await response.json();
        return responseData;
    }
    // Gets app info from UAS
    async applicationInfo() {
        const response = await fetch(`${this.versioned ? this.domain : this.domain + "/connect"}/applications?client_id=${this.clientId}`, {
            method: "GET",
            headers: new Headers({ "content-type": "application/json" }),
        });
        const responseData = await response.json();
        return responseData.data;
    }
    // Gets providers form UAS
    async getAvailableProviders() {
        const response = await fetch(`${this.domain}/connect/providers/find?client_id=${this.clientId}`, {
            method: "GET",
            headers: new Headers({ "content-type": "application/json" }),
        });
        if (response) {
            const responseData = await response.json();
            const providers = responseData.data;
            return providers;
        }
        return null;
    }
    // EVENT HOOKS
    onLoginSuccess(callback) {
        window.addEventListener("onLoginSuccess", (e) => callback(e));
    }
    onLogoutSuccess(callback) {
        window.addEventListener("onLogoutSuccess", (e) => callback(e));
    }
    onLoginFail(callback) {
        window.addEventListener("onLoginFail", (e) => callback(e));
    }
    onTokenRefreshSuccess(callback) {
        window.addEventListener("onTokenRefreshSuccess", (e) => callback(e));
    }
    onTokenRefreshFail(callback) {
        window.addEventListener("onTokenRefreshFail", (e) => callback(e));
    }
    onSessionExpired(callback) {
        window.addEventListener("onSessionExpired", (e) => callback(e));
    }
    // Exchanges code for ID token and refresh and access tokens
    async codeExchange(search) {
        let params = new URLSearchParams(window.location.search);
        if (search) {
            params = new URLSearchParams(search);
        }
        const code = params.get("code");
        const state = params.get("state");
        const error = params.get("error");
        const error_description = params.get("error_description");
        const error_code = params.get("error_code");
        if (error && error_description && error_code) {
            const payload = {
                detail: { error, error_description, error_code },
            };
            window.dispatchEvent(new CustomEvent("onLoginFail", payload));
            window.history.pushState({}, document.title, window.location.pathname);
            return false;
        }
        if (!code) {
            console.warn("No code found");
            return false;
        }
        // If popup window stop internal code exchange
        if (window.opener && window.name === "uas-popup") {
            console.warn("Popup window detected");
            return false;
        }
        // Get PKCE code_challenge from local storage
        const code_verifier = await this.Storage.getPKCE();
        if (!code_verifier) {
            console.warn("No code verifier found");
            return false;
        }
        // Prepare request
        try {
            const payload = {
                client_id: this.clientId,
                redirect_uri: this.redirectUri,
                code: code,
                grant_type: "authorization_code",
                code_verifier: code_verifier,
            };
            const response = await fetch(`${this.domain}/connect/token`, {
                method: "POST",
                headers: new Headers({ "content-type": "application/json" }),
                body: JSON.stringify(payload),
            });
            const responseData = await response.json();
            // Parse response
            // Store ID token
            if (responseData) {
                if (responseData.error) {
                    const payload = { detail: responseData };
                    window.dispatchEvent(new CustomEvent("onLoginFail", payload));
                    return true;
                }
                const exchangeResponse = await this.handleCodeExchangeResponse(responseData);
                if (!exchangeResponse.valid) {
                    const payload = { detail: exchangeResponse.data };
                    window.dispatchEvent(new CustomEvent("onLoginFail", payload));
                    return true;
                }
                else {
                    if (state) {
                        exchangeResponse.data.state = state;
                    }
                    const payload = { detail: exchangeResponse.data };
                    window.dispatchEvent(new CustomEvent("onLoginSuccess", payload));
                    window.history.pushState({}, document.title, window.location.pathname);
                }
            }
            this.Storage.removePKCE();
            return true;
            // Remove PKCE code_challenge from local storage
        }
        catch (error) {
            const payload = { detail: error };
            window.dispatchEvent(new CustomEvent("onLoginFail", payload));
            window.history.pushState({}, document.title, window.location.pathname);
            return false;
        }
    }
    // Token Exchange for session  maintenece
    async tokenExchange(grant_id = "") {
        const grant = await this.Storage.getGrant(grant_id, this.multiAccount);
        if (!grant) {
            return false;
        }
        const refresh_token = grant.refresh_token;
        try {
            const payload = {
                client_id: this.clientId,
                redirect_uri: this.redirectUri,
                refresh_token,
                grant_type: "refresh_token",
            };
            const response = await fetch(`${this.domain}/connect/token`, {
                method: "POST",
                headers: new Headers({ "content-type": "application/json" }),
                body: JSON.stringify(payload),
            });
            const responseData = await response.json();
            // Parse response
            // Store ID token
            if (responseData) {
                if (responseData.error) {
                    const payload = { detail: responseData };
                    window.dispatchEvent(new CustomEvent("onTokenRefreshFail", payload));
                    return true;
                }
                const now = getTimestamp();
                responseData.expires_in = now + responseData.expires_in;
                this.Storage.setGrant(responseData, this.multiAccount);
                const isValidToken = await this.validateIDToken(grant_id);
                if (!isValidToken) {
                    const payload = { detail: responseData };
                    window.dispatchEvent(new CustomEvent("onTokenRefreshFail", payload));
                    return true;
                }
                const payload = { detail: responseData };
                window.dispatchEvent(new CustomEvent("onTokenRefreshSuccess", payload));
                return true;
            }
            // Remove PKCE code_challenge from local storage
            this.Storage.removePKCE();
        }
        catch (error) {
            const payload = { detail: error };
            window.dispatchEvent(new CustomEvent("onTokenRefreshFail", payload));
            return false;
        }
    }
    // Handles the response of code exchange
    async handleCodeExchangeResponse(responseData) {
        const isValid = true;
        if (responseData.error) {
            return {
                data: responseData,
                valid: false,
            };
        }
        const now = getTimestamp();
        responseData.expires_in = now + responseData.expires_in;
        this.Storage.setGrant(responseData, this.multiAccount);
        const user = jwtDecode(responseData.id_token);
        user.status = "authenticated";
        this.Storage.setUserToken(user);
        const isValidToken = await this.validateIDToken(user.sub);
        if (!isValidToken) {
            return {
                data: responseData,
                valid: false,
            };
        }
        return {
            data: responseData,
            valid: isValid,
        };
    }
    // Regulates POPUP behaivior
    async popUp(url) {
        const width = 500;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2;
        const top = window.screenY + (window.outerHeight - height) / 2.5;
        const title = `uas-popup`;
        const popupURL = url;
        const externalPopup = window.open(popupURL, title, `width=${width},height=${height},left=${left},top=${top}`);
        if (!externalPopup) {
            return;
        }
        const timer = setInterval(async () => {
            if (externalPopup.closed) {
                const payload = {
                    detail: { error_description: "OAuth provider window closed" },
                };
                window.dispatchEvent(new CustomEvent("onLoginFail", payload));
                timer && clearInterval(timer);
                return;
            }
            try {
                const currentUrl = externalPopup.location.href.split("?");
                if (!currentUrl[0]) {
                    return;
                }
                const search = externalPopup.location.search;
                externalPopup.history.pushState({}, document.title, window.location.pathname);
                if (currentUrl[0] === this.redirectUri && currentUrl.length > 1) {
                    const success = await this.codeExchange(search);
                    externalPopup.close();
                    if (success) {
                        location.reload();
                    }
                    timer && clearInterval(timer);
                    return;
                }
            }
            catch (error) {
                return;
            }
        }, 1000);
    }
    // Returns access token
    async getAccessToken(grant_id = "") {
        const now = getTimestamp();
        const grantResponse = await this.Storage.getGrant(grant_id, this.multiAccount);
        if (!grantResponse) {
            return null;
        }
        const { access_token, expires_in } = grantResponse;
        const token = access_token;
        if (token) {
            if (expires_in && expires_in > getTimestamp()) {
                const timeLeft = expires_in - now;
                // If more then 30 secounds remain return access token
                if (timeLeft > 30) {
                    return token;
                }
            }
        }
        await this.tokenExchange(grant_id);
        const grant = await this.Storage.getGrant(grant_id, this.multiAccount);
        return grant.access_token;
    }
    async sse(url, request) {
        const headers = request.headers || {};
        const grant_id = headers["Grant-ID"];
        let token = "";
        if (this.multiAccount) {
            if (!grant_id) {
                this.logout();
                throw new Error("Grant ID is required for multi account calls");
            }
            token = await this.getAccessToken(grant_id);
        }
        else {
            token = await this.getAccessToken();
        }
        delete headers["Grant-ID"];
        headers["Authorization"] = `Bearer ${token}`;
        if (!headers["content-type"]) {
            headers["content-type"] = "application/json";
        }
        return fetchEventSource(`${this.domain}/${url}`, {
            ...request,
            headers,
        });
    }
    // Used to call Nylas API endpoints
    async fetch(url, request, parseJSON = true, domain) {
        let token = "";
        let profileCount = 1;
        if (this.multiAccount) {
            if (!request.grant_id) {
                this.logout();
                throw new Error("Grant ID is required for multi account calls");
            }
            const profiles = await this.getProfiles();
            profileCount = profiles ? profiles.length : 1;
            token = await this.getAccessToken(request.grant_id);
        }
        else {
            token = await this.getAccessToken();
        }
        if (!token) {
            throw new Error("Access token not found");
        }
        let headers = request.headers;
        if (!Headers.prototype.isPrototypeOf(headers)) {
            headers = new Headers({
                Authorization: `Bearer ${token}`,
                "content-type": "application/json",
            });
        }
        else {
            headers.append("Authorization", `Bearer ${token}`);
            if (!headers.has("content-type")) {
                headers.append("content-type", "application/json");
            }
        }
        try {
            if (request?.body) {
                request.body = JSON.stringify(request?.body);
            }
            const response = await fetch(`${domain || this.domain}/${url}`, {
                ...request,
                headers,
            });
            if (!parseJSON) {
                return response;
            }
            const responseData = await response?.json();
            const isAuthenticated = await this.isAuthenticatedResponse(response.status, responseData);
            if (!isAuthenticated) {
                if (profileCount > 1) {
                    const user = await this.getProfile(request.grant_id);
                    if (user) {
                        user.status = "unauthorized";
                        await this.Storage.setUserToken(user);
                        const payload = {
                            detail: { user },
                        };
                        window.dispatchEvent(new CustomEvent("onSessionExpired", payload));
                    }
                }
                else {
                    const user = await this.Storage.getUserToken();
                    const payload = {
                        detail: { user },
                    };
                    window.dispatchEvent(new CustomEvent("onSessionExpired", payload));
                }
                this.logout();
                return;
            }
            return responseData;
        }
        catch (e) {
            throw e;
        }
    }
    async fetchRequest(path, method, body, parseJSON = true, domain, request) {
        const isLoggedIn = await this.isLoggedIn();
        const url = new URL(path, domain ?? this.domain);
        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        headers.append("User-Agent", "nylas-identity");
        if (isLoggedIn) {
            const accessToken = await this.getAccessToken();
            headers.append("Authorization", `Bearer ${accessToken}`);
        }
        const response = await fetch(url.toString(), {
            method: method || "GET",
            headers,
            mode: "cors",
            referrer: location.origin,
            body: body ? JSON.stringify(body) : undefined,
            ...(request || {}),
        });
        // If we don't want to parse the response as JSON, return the raw response
        if (!parseJSON) {
            return response.body;
        }
        const json = await response.json();
        return json;
    }
    // Checks if the response is not 401
    async isAuthenticatedResponse(status, responseData) {
        if (status == 401 &&
            responseData?.error?.type == "token.unauthorized_access") {
            return false;
        }
        return true;
    }
    addAPIKey(grant_id, email, key) {
        this.Storage.setUserToken({
            aud: "https://api-staging.us.nylas.com/",
            exp: 2000000000,
            email_verified: true,
            iat: getTimestamp(),
            iss: "",
            email,
            provider: "virtual-calendar",
            status: "authenticated",
            name: email,
            sub: grant_id,
        });
        this.Storage.setGrant({
            grant_id,
            access_token: key,
            expires_in: 2000000000,
        }, this.multiAccount);
    }
}
