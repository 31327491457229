@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.nylas-scheduling-method {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: var(--nylas-border-radius-2x);
  border: 1px solid var(--nylas-base-200);

  .header {
    padding: 1rem;
    border-bottom: 1px solid var(--nylas-base-200);

    h3 {
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      line-height: 20px;
      color: var(--nylas-base-900);
      text-align: left;
    }
  }

  .nylas-scheduling-method__dropdown {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 4px;

    span.nylas-scheduling-method__dropdown-label {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--nylas-base-800);
      margin: 0.25rem 0 0 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;

      tooltip-component {
        display: flex;
      }
    }

    select-dropdown::part(sd_dropdown) {
      width: 100%;
    }

    select-dropdown::part(sd_dropdown-content) {
      width: 100%;
      max-width: unset;
    }

    select-dropdown::part(sd_dropdown-button) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid var(--nylas-base-200);
      border-radius: var(--nylas-border-radius-2x);
    }

    select-dropdown::part(sd_dropdown-button-selected-label) {
      max-width: calc(100% - 2rem);
      font-family: var(--nylas-font-family);
      font-size: 16px;
      line-height: 24px;
    }

    input-component::part(ic__input) {
      background: var(--nylas-base-50);
      outline: none;
      cursor: not-allowed;
    }
  }
}
