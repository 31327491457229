@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.nylas-page-styling {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: var(--nylas-border-radius-2x);
  border: 1px solid var(--nylas-base-200);
  text-align: left;

  &.no-border {
    border: none;
  }

  .header {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr auto;
    border-bottom: 1px solid var(--nylas-base-200);

    h3 {
      border-radius: var(--nylas-border-radius-2x);
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
      line-height: 20px;
      color: var(--nylas-base-900);
      text-align: left;
    }

    p {
      margin: 0.25rem 0 0 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      color: var(--nylas-base-600);
      text-align: left;
    }

    .drawer-toggle {
      display: flex;
      gap: 24px;
      align-items: center;

      .chevron {
        display: flex;
        align-self: center;
        cursor: pointer;

        &:hover,
        &:active {
          color: var(--nylas-primary);
        }

        &.open {
          transform: rotate(90deg);
        }

        &.closed {
          transform: rotate(270deg);
        }

        &.disabled {
          cursor: not-allowed;
          color: var(--nylas-base-300);

          &:hover {
            color: var(--nylas-base-300);
          }
        }
      }
    }
  }

  .nylas-page-styling__body {
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: var(--nylas-base-25);
    border-radius: 0 0 var(--nylas-border-radius-2x);

    @media #{$mobile} {
      grid-template-columns: 1fr;
    }

    .nylas-page-styling__section {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: var(--nylas-base-800);

      &.no-padding {
        padding: 0;
      }

      @media #{$mobile} {
        border: none;
      }

      .nylas-page-styling__row {
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;

        label {
          display: flex;
          align-items: center;

          span.required {
            color: var(--nylas-error, #cc4841);
          }

          span.label-icon {
            margin-left: 4px;

            tooltip-component {
              display: flex;
            }
          }
        }

        p {
          margin: 0.25rem 0 0 0;
          font-size: 1rem;
          font-weight: 500;
          line-height: 24px;
          color: var(--nylas-base-900);
        }

        .subsection {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          &.hide {
            display: none;
          }

          h3 {
            margin: 0;
            font-size: 1rem;
            font-weight: 600;
            line-height: 20px;
            color: var(--nylas-base-900);
            text-align: left;
          }

          .input-container,
          > * {
            display: flex;
            flex-direction: column;
            column-gap: 2rem;
            row-gap: 0.25rem;
            width: 100%;
            flex: 1;

            textarea {
              padding: 12px 16px;
              border-width: 1;
              resize: vertical;
              border-radius: 8px;
              font-family: var(--nylas-font-family);
              font-size: 16px;
              line-height: 24px;
              border: 1px solid var(--nylas-base-200);
            }
          }
        }
      }
    }

    span.error-message {
      color: var(--nylas-error);
    }

    span.help-text {
      margin: 0.25rem 0 0 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      color: var(--nylas-base-600);
      text-align: left;
    }
  }
}
