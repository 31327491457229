@import '../../../common/styles/variables.scss';
@import '../../../common/mixins/buttons.scss';

:host {
  display: flex;
  flex-direction: column;
  overflow: visible;
  border-radius: var(--nylas-border-radius-2x);
  width: fit-content;

  @media #{$mobile} {
    width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    min-width: 325px;
  }

  background: var(--nylas-base-0);
  position: relative;
  box-shadow:
    0px 0px 6px -2px rgba(0, 0, 0, 0.05),
    0px 5px 15px -3px rgba(0, 0, 0, 0.1);
  @include default-css-variables;
}

div.scheduler-editor-content {
  height: 610px;

  @media #{$mobile} {
    height: auto;
  }
}

div.scheduler-editor-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid var(--nylas-base-200);
  color: var(--nylas-base-900);

  h1.scheduler-editor-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 858px;
    margin: 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;

    @media #{$mobile} {
      width: auto;
      min-width: calc(325px - 1rem);
    }
  }

  button {
    @include invisible-button;
  }
}

nylas-editor-tabs {
  width: auto;
  height: inherit;

  .composable {
    height: inherit;
    overflow: auto;
  }

  nylas-event-info,
  nylas-event-limits {
    div[slot='inputs'] {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: var(--nylas-base-0);
  border: 1px solid var(--nylas-base-200);
  border-radius: 5px;

  max-width: fit-content;

  align-self: center;
}

.login-required {
  height: inherit;

  @media #{$mobile} {
    height: auto;
  }

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1rem;
  background-color: var(--nylas-base-25);
  border-radius: var(--nylas-border-radius-2x);

  p {
    margin: 0 0 1rem;
  }

  .microsoft-button,
  .google-button {
    @include button;
    display: flex;
    margin-bottom: 1rem;
    background-color: var(--nylas-base-0);
    border: 1px solid var(--nylas-base-200);
    border-radius: 5px;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;

    &:hover {
      background-color: var(--nylas-base-25);
    }
  }

  .other-provider-button {
    @include button;
    display: flex;
    border: 0;
    background-color: var(--nylas-base-0);
    height: 48px;
    margin-bottom: 1rem;
    font-size: 0.95rem;
    justify-content: center;
    gap: 0.25rem;
    align-items: center;

    svg {
      transform: rotate(180deg);
    }

    &:hover {
      background-color: var(--nylas-base-25);
    }
  }

  .disclaimer-text {
    font-size: 0.75rem;
    color: var(--nylas-base-600);
    align-self: center;
  }
}

.list-configurations {
  padding: 2rem;
  height: inherit;

  @media #{$mobile} {
    height: auto;
    padding: 2rem 1rem;
  }

  background: var(--nylas-base-25);
  border-radius: var(--nylas-border-radius-2x);

  nylas-list-configurations {
    height: inherit;
    display: flex;
    flex-direction: column;
  }
}
