import { useState, useEffect } from "react";
export function useNylasSessions(identity) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        const cb = async () => {
            const is = await identity.isLoggedIn();
            if (is) {
                setIsLoggedIn(true);
            }
            else {
                setIsLoggedIn(false);
            }
        };
        cb();
        identity.onLoginSuccess(() => {
            setIsLoggedIn(true);
        });
        identity.onLogoutSuccess(() => {
            setIsLoggedIn(false);
        });
    });
    return {
        isLoggedIn,
    };
}
