import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Availability, Configuration, OpenHours, Participant } from '@nylas/core';
import { DEFAULT_OPEN_HOURS } from '@/common/constants';

/**
 * The `nylas-participants-custom-availability` component is a form input for setting custom availability for participants.
 *
 * @part npca - The participants custom availability container
 * @part npca__header - The header of the participants custom availability
 * @part npca__content - The content of the participants custom availability
 * @part npca__participant-container - The participant container
 * @part npca__participant-title - The title of the participant
 * @part npca__participant-toggle--container - The toggle container for the participant
 * @part npca__toggle-label - The label of the toggle
 * @part npca_toggle-input - The input of the toggle
 * @part npca_toggle-slider - The slider of the toggle
 */
@Component({
  tag: 'nylas-participants-custom-availability',
  styleUrl: 'nylas-participants-custom-availability.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasParticipantsCustomAvailability {
  @Element() host!: HTMLNylasParticipantsCustomAvailabilityElement;
  private participantFormRef!: HTMLFormElement;
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;

  /**
   * @standalone
   * The name of the participants custom availability.
   */
  @Prop() name: string = 'participant-custom-availability';

  /**
   * @standalone
   * The participants selected in the add participants section.
   */
  @Prop() participants: Participant[] = this.selectedConfiguration?.participants || [];

  /**
   * This event is fired when the participants custom availability changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The state to store the custom availability setting for participants.
   */
  @State() selectedParticipants: {
    [key: string]: {
      setCustom: boolean;
      isOpen: boolean;
      openHours: OpenHours[];
      name: string;
      availability?: Availability;
      timezone?: string;
    };
  } = this.setParticipants(this.participants);

  // Watchers
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-participants-custom-availability', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('participants')
  participantsChangedHandler(newValue: Participant[], oldValue: Participant[]) {
    debug('nylas-participants-custom-availability', 'participantsChangedHandler', newValue, oldValue);
    this.selectedParticipants = this.setParticipants(newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration, oldValue: Configuration) {
    debug('nylas-participants-custom-availability', 'selectedConfigurationChangedHandler', newValue, oldValue);
    if (newValue?.participants) {
      this.selectedParticipants = this.setParticipants(newValue?.participants);
    }
  }

  // Lifecycle Methods
  connectedCallback() {
    debug('nylas-participants-custom-availability', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-participants-custom-availability', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-participants-custom-availability', 'componentWillLoad');
  }

  componentDidLoad() {
    debug('nylas-participants-custom-availability', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedParticipants = this.setParticipants(this.selectedConfiguration?.participants);
    } else {
      this.selectedParticipants = this.setParticipants(this.participants);
    }
  }

  // Event Handlers
  @Listen('nylasFormSwitchToggled')
  nylasFormSwitchToggledHandler(event: CustomEvent<{ checked: boolean; name: string; label: boolean }>) {
    debug('nylas-participants-custom-availability', 'nylasFormSwitchToggledHandler', event.detail);
    const openHours = this.selectedConfiguration?.participants.find(participant => participant.email === event.detail.name)?.availability?.open_hours || [];
    this.selectedParticipants[event.detail.name]['isOpen'] = event.detail.checked;
    this.selectedParticipants[event.detail.name]['setCustom'] = event.detail.checked;
    this.selectedParticipants[event.detail.name]['openHours'] = event.detail.checked ? (openHours?.length > 0 ? openHours : DEFAULT_OPEN_HOURS) : [];
    this.selectedParticipants = { ...this.selectedParticipants };
    this.updateFormValue();
  }

  @Listen('valueChanged')
  handleValueChanged(event: CustomEvent) {
    debug('[nylas-editor-tabs]', 'handleValueChanged', event);
    const { name, value } = event.detail;
    if (!name.startsWith('participant-')) {
      return;
    }
    // Validate the form
    if (!this.participantFormRef.checkValidity()) {
      this.internals.setValidity({ customError: true }, 'Please fix the overlapping time ranges.');
      return;
    } else {
      this.internals.setValidity({});
    }

    const jsonValue = JSON.parse(value);

    const key = name.split('-')[2];
    if (!this.selectedParticipants[key]) return;
    this.selectedParticipants[key]['openHours'] = jsonValue.openHours;
    this.selectedParticipants = { ...this.selectedParticipants };

    this.updateFormValue();
  }

  updateFormValue() {
    const participantOpenHours = {};
    const participants = this.selectedConfiguration?.participants || this.participants;
    participants.forEach(participant => {
      participantOpenHours[participant.email] = this.selectedParticipants[participant.email].openHours;
    });
    this.internals.setFormValue(JSON.stringify(participantOpenHours), this.name);
    this.valueChanged.emit({ value: JSON.stringify(participantOpenHours), name: this.name });
  }

  setParticipants(participants: Participant[]) {
    const selectedParticipants = {};
    participants?.forEach(participant => {
      selectedParticipants[participant.email] = {
        isOpen: false,
        setCustom: !!participant.availability?.open_hours ? true : false,
        openHours: participant.availability?.open_hours || [],
        name: participant.name || participant.email,
        availability: participant.availability,
        timezone: participant.timezone,
      };
    });
    return selectedParticipants;
  }
  @RegisterComponent<NylasParticipantsCustomAvailability, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-participants-custom-availability',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <div class="nylas-participants-custom-availability" part="npca">
          <div class="header" part="npca__header">
            <h3>Participant open hours</h3>
            <p>If not set, the default open hours will be used to calculate availability for this participant.</p>
          </div>
          <div class="content" part="npca__content">
            <form ref={el => (this.participantFormRef = el as HTMLFormElement)}>
              {Object.keys(this.selectedParticipants).map((key, index) => {
                const participant = this.selectedParticipants[key];
                if (!participant || !participant.name || !participant.availability) return;
                return (
                  <div class="participant-container" part="npca__participant-container" key={`participant-conatiner-${index}`}>
                    <div class="participant-title" part="npca__participant-title">
                      <p>{participant.name}'s open hours</p>
                      <div class="participant-toggle" part="npca__participant-toggle--container">
                        <toggle-switch
                          exportparts="ts_label: npca__toggle-label, ts_input: npca_toggle-input, ts_slider: npca_toggle-slider"
                          name={key}
                          checked={!!participant?.openHours && participant?.openHours.length > 0}
                        />
                        <span
                          class={`chevron ${participant.isOpen ? 'open' : 'closed'} ${participant.setCustom ? '' : 'disabled'}`}
                          onClick={() => {
                            if (!participant.setCustom) return;
                            this.selectedParticipants[key].isOpen = !participant.isOpen;
                            this.selectedParticipants = { ...this.selectedParticipants };
                          }}
                        >
                          <chevron-icon width="24" height="24" />
                        </span>
                      </div>
                    </div>
                    {participant.isOpen && (
                      <nylas-availability-picker
                        key={index}
                        name={`participant-${index}-${key}`}
                        openHours={participant.openHours}
                        defaultTimezone={participant.timezone}
                        hideHeader={true}
                      />
                    )}
                  </div>
                );
              })}
            </form>
          </div>
        </div>
      </Host>
    );
  }
}
