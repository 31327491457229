import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration, EventReminder, Participant } from '@nylas/core';
/**
 * The `nylas-reminder-emails` component is a UI component that allows users to set buffer time before and after an event.
 *
 * @part nre__tab-heading - The nylas-reminder-emails main tab heading
 * @part nre - The nylas-reminder-emails container
 * @part nre__header - The header of the event communication section
 * @part nre__body - The body of the event communication section
 * @part nre__summary - The reminder card summary container
 * @part nre__reminder-input_dropdown - The dropdown container for selecting reminder time
 * @part nre__reminder-input_dropdown-input - The dropdown for selecting reminder time value
 * @part nre__reminder-input_dropdown-content - The dropdown for selecting reminder time unit (e.g. min, hrs, days)
 */
@Component({
  tag: 'nylas-reminder-emails',
  styleUrl: 'nylas-reminder-emails.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasReminderEmails {
  @Element() host!: HTMLNylasReminderEmailsElement;

  /**
   * The selected configuration.
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * The event reminders prop.
   */
  @Prop() eventReminders?: (EventReminder & { editing?: boolean })[];
  /**
   * The name of the component
   */
  @Prop() name: string = 'reminder-overrides';
  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The event reminders state. Defaults to an empty array if no event reminders are present.
   */
  @State() reminders: (EventReminder & { editing?: boolean })[] = this.selectedConfiguration?.event_booking?.reminders ?? this.eventReminders ?? [];
  @State() reminderErrors: { [key: string]: string } = {};
  @State() participants: Participant[] = this.selectedConfiguration?.participants ?? [];

  /**
   * This event is fired when the email reminders change.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
    valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void;
  }>;

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-reminder-emails', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-reminder-emails', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-reminder-emails', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-reminder-emails', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.reminders = this.eventReminders ?? [];
      this.updateRemindersFormValue();
    }
  }

  componentWillUpdate() {
    debug('nylas-reminder-emails', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-reminder-emails', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-reminder-emails', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-reminder-emails', 'componentDidRender');
  }

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-reminder-emails', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newVal: Configuration) {
    debug('nylas-reminder-emails', 'selectedConfigurationChangedHandler', newVal);
    this.reminders = newVal?.event_booking?.reminders ?? [];
    this.participants = newVal?.participants ?? [];
    this.updateRemindersFormValue();
  }

  @Listen('valueChanged')
  handleValueChanged(event: CustomEvent) {
    debug('nylas-reminder-emails', 'handleValueChanged', event);
    const { name, value } = event.detail;

    if (name.startsWith('reminder-time-')) {
      // Extract the reminder index from the name attribute (e.g. reminder-time-0)
      const [_, reminderIndex] = name.split(/(\d+)/);
      const updatedReminders = [...this.reminders];
      updatedReminders[reminderIndex].minutes_before_event = parseInt(value);
      this.reminders = [...updatedReminders];
      this.updateRemindersFormValue();
    }
  }

  @Listen('nylasFormDropdownChanged')
  handleNylasFormDropdownChanged(event: CustomEvent) {
    debug('nylas-reminder-emails', 'handleNylasFormDropdownChanged', event);
    const { name, value } = event.detail;
    if (!name.startsWith('recipient-')) {
      return;
    }
    // Extract the reminder index from the name attribute (e.g. recipient-0)
    const [_, reminderIndex] = name.split(/(\d+)/);
    const updatedReminders = [...this.reminders];
    updatedReminders[reminderIndex].recipient = value;
    this.reminders = [...updatedReminders];
    this.updateRemindersFormValue();
  }

  @Listen('nylasFormInputChanged')
  handleNylasFormInputChanged(event: CustomEvent) {
    debug('nylas-reminder-emails', 'handleNylasFormInputChanged', event);
    const { name, value } = event.detail;
    if (!name.startsWith('reminder-subject-')) {
      return;
    }
    // Extract the reminder index from the name attribute (e.g. reminder-subject-0)
    const [_, reminderIndex] = name.split(/(\d+)/);
    const updatedReminders = [...this.reminders];
    updatedReminders[reminderIndex].email_subject = value;
    this.reminders = [...updatedReminders];
    this.updateRemindersFormValue();
  }

  addReminder() {
    debug('nylas-reminder-emails', 'addReminder');
    this.reminders = [
      ...this.reminders,
      {
        type: 'email',
        minutes_before_event: 30,
        editing: true,
        recipient: 'all',
      },
    ];
    this.updateRemindersFormValue();
  }

  deleteReminder(index: number) {
    this.reminders = this.reminders.filter((_, i) => i !== index).map(p => ({ ...p }));
    this.updateRemindersFormValue();
  }

  get isInternalsAvailable() {
    return this.internals !== undefined && typeof this.internals.setFormValue === 'function';
  }

  updateRemindersFormValue() {
    debug('nylas-reminder-emails', 'updateRemindersFormValue');
    const eventReminders = this.reminders.map(reminder => {
      const rem = { ...reminder };
      rem?.editing && delete rem.editing;
      return rem;
    });
    this.valueChanged.emit({
      value: JSON.stringify(eventReminders),
      name: 'reminder-overrides',
    });
    this.isInternalsAvailable && this.internals.setFormValue(JSON.stringify(eventReminders), 'reminder-overrides');
  }

  formatReminderTime(reminderTime: number) {
    switch (true) {
      case reminderTime > 24 * 60:
        return `${reminderTime / 60 / 24} days`;
      case reminderTime === 24 * 60:
        return '1 day';
      case reminderTime > 90 || reminderTime === 60:
        return `${reminderTime / 60} hours`;
      default:
        return `${reminderTime} minute${reminderTime > 1 ? 's' : ''} `;
    }
  }

  @RegisterComponent<NylasReminderEmails, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-reminder-emails',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {
      valueChanged: async (
        event: CustomEvent<{ value: string; name: string; valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void }>,
        _nylasSchedulerConfigConnector: NylasSchedulerConfigConnector,
      ) => {
        const { valueChanged } = event.detail;
        // If a handler is passed, call it.
        if (valueChanged) {
          valueChanged(event);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    const organizerName = this.participants.find(p => p.is_organizer)?.name;
    const recipientOptions = [
      {
        label: 'All host & guests',
        value: 'all',
      },
      {
        label: 'All event guests',
        value: 'guest',
      },
      {
        label: 'Only event host',
        value: 'host',
      },
    ];

    return (
      <Host>
        <div class="cards-container">
          {this.reminders.map((reminder, index) => {
            return reminder.type !== 'webhook' ? (
              <div class="nylas-reminder-email" part="nre">
                <div class="header" part="nre__header">
                  <div>
                    <h3>Upcoming meeting{organizerName ? ` with ${organizerName} ` : ''}</h3>
                    <p>Reminder before event</p>
                  </div>
                  {!reminder.editing ? (
                    <button-component
                      variant={'basic'}
                      key={`edit-${index} `}
                      clickHandler={event => {
                        event.preventDefault();
                        reminder.editing = true;
                        this.reminders = [...this.reminders];
                      }}
                    >
                      <edit-icon width="16" height="16"></edit-icon>
                      Edit
                    </button-component>
                  ) : (
                    <div class="reminder-open-close-toggle">
                      <button-component
                        variant={'basic'}
                        class="delete-btn"
                        tooltip="Delete reminder"
                        key={`delete -${index} `}
                        clickHandler={event => {
                          event.preventDefault();
                          this.deleteReminder(index);
                        }}
                      >
                        <trash-icon width="16" height="16"></trash-icon>
                      </button-component>
                      <span
                        class={`chevron ${reminder.editing ? 'open' : 'closed'}`}
                        onClick={() => {
                          reminder.editing = !reminder.editing;
                          this.reminders = [...this.reminders];
                        }}
                      >
                        <chevron-icon width="24" height="24" />
                      </span>
                    </div>
                  )}
                </div>

                {reminder.editing ? (
                  <div class="nylas-reminder-email__body" part="nre__body">
                    <div class="nylas-reminder-email__section">
                      <div class="nylas-reminder-email__row">
                        <label>Email recipients</label>
                        <select-dropdown
                          name={`recipient-${index}`}
                          options={recipientOptions}
                          defaultSelectedOption={recipientOptions.find(i => i.value === reminder.recipient) ?? recipientOptions[0]}
                          withSearch={false}
                        />
                      </div>
                      <div class="nylas-reminder-email__row">
                        <label>
                          Send confirmation email when
                          <span class="label-icon">
                            <tooltip-component>
                              <info-icon slot="tooltip-icon" />
                              <span slot="tooltip-content">The recipients will receive a reminder email at the specified time before the event.</span>
                            </tooltip-component>
                          </span>
                        </label>
                        <nylas-reminder-time
                          name={`reminder-time-${index}`}
                          eventReminderTimeMinutes={reminder.minutes_before_event}
                          exportparts="nrt, nrt__input_dropdown: nre__reminder-input_dropdown,
                          nrt__input_dropdown-input: nre__reminder-input_dropdown-input,
                          nrt__input_dropdown-content: nre__reminder-input_dropdown-content,
                          "
                        />
                      </div>
                      <div class="nylas-reminder-email__block">
                        <label>Email subject</label>
                        <input-component
                          name={`reminder-subject-${index}`}
                          maxLength={60}
                          placeholder={`Upcoming meeting ${organizerName ? `with ${organizerName}` : ''}`}
                          defaultValue={reminder?.email_subject || ''}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div class="nylas-reminder-email__summary" part="nre__summary">
                    <p>
                      <span class="summary-icon">
                        <person-clipboard-icon></person-clipboard-icon>
                      </span>
                      {recipientOptions.find(i => i.value === reminder.recipient)?.label}
                    </p>
                    <p>
                      <span class="summary-icon">
                        <clock-icon></clock-icon>
                      </span>
                      {this.formatReminderTime(reminder.minutes_before_event)} before the event
                    </p>
                  </div>
                )}
              </div>
            ) : null;
          })}
        </div>

        <button-component
          class="create-reminder"
          variant={'basic'}
          clickHandler={event => {
            event.preventDefault();
            this.addReminder();
          }}
        >
          <plus-icon width="16" height="16"></plus-icon>
          New reminder
        </button-component>
      </Host>
    );
  }
}
