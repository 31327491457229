import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { AdditionalParticipant, Calendar, Configuration, Participant } from '@nylas/core';

/**
 * The `nylas-participant-booking-calendars` component is a form input for selecting calendars to check availability for participants.
 *
 * @part npbc - The participant booking calendars host.
 * @part npbc__header - The header.
 * @part npbc__content - The content.
 * @part npbc__participant-container - The participant container.
 * @part npbc__participant-title - The participant title.
 * @part npbc__participant-toggle-container - The participant toggle container.
 */
@Component({
  tag: 'nylas-participant-booking-calendars',
  styleUrl: 'nylas-participant-booking-calendars.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasParticipantBookingCalendars {
  @Element() host!: HTMLNylasParticipantBookingCalendarsElement;
  private bookingCalendarsFormRef!: HTMLFormElement;
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @standalone
   * The name of the participants custom availability.
   */
  @Prop() name: string = 'participant-booking-calendars';

  /**
   * @standalone
   * The participants selected in the add participants section.
   */
  @Prop() participants: Participant[] = this.selectedConfiguration?.participants || [];

  /**
   * @standalone
   * The calendars to choose from for the organizer / logged in user.
   */
  @Prop() calendars?: Calendar[];

  /**
   * @standalone
   * The participant options passed in the additionalParticipants prop
   * from the nylas-scheduler-editor component.
   */
  @Prop() participantOptions?: AdditionalParticipant[];

  /**
   * This event is fired when the participants custom availability changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The state to store the custom availability setting for participants.
   */
  @State() selectedCalendars: {
    [key: string]: {
      isOpen: boolean;
      name: string;
      calendar?: Calendar;
    };
  } = this.setParticipants(this.participants);

  /**
   * Participants calendar options.
   */
  @State() participantCalendars: {
    [key: string]: Calendar[];
  } = {};

  /**
   * The state to store the default selected calendars for each participant
   */
  @State() participantDefaultSelectedCalendar: {
    [key: string]: string;
  } = {};

  // Watchers
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-participant-booking-calendars', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('calendars')
  calendarsChangedHandler(newValue: Calendar[]) {
    debug('nylas-participant-booking-calendars', 'calendarsChangedHandler', newValue);
    this.participantCalendars = this.getParticipantCalendarOptions(this.participants, this.participantOptions);
  }

  @Watch('participants')
  participantsChangedHandler(newValue: Participant[]) {
    debug('nylas-participant-booking-calendars', 'participantsChangedHandler', newValue);
    this.selectedCalendars = this.setParticipants(newValue);
    this.participantCalendars = this.getParticipantCalendarOptions(newValue, this.participantOptions);
  }

  @Watch('participantOptions')
  participantOptionsChangedHandler(newValue: AdditionalParticipant[]) {
    debug('nylas-calendar-picker', 'participantOptionsChangedHandler', newValue);
    this.participantCalendars = this.getParticipantCalendarOptions(this.participants, newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-participant-booking-calendars', 'selectedConfigurationChangedHandler', newValue);
    const participants = newValue?.participants || this.participants;
    if (participants && participants.length > 0) {
      this.selectedCalendars = this.setParticipants(participants);
      this.participantCalendars = this.getParticipantCalendarOptions(participants, this.participantOptions);
    }
  }

  getParticipantCalendarOptions(addedParticipants: Participant[], availableParticipantOptions: AdditionalParticipant[] | undefined) {
    // Get the participants from the availableParticipantOptions prop that are in the addedParticipants prop
    const organizer = addedParticipants?.find(participant => participant.is_organizer);
    const participantCalendars = {};
    const showAdditionalParticipantBookingCalendars = this.selectedConfiguration?.availability?.availability_rules?.availability_method !== 'collective';
    if (availableParticipantOptions && showAdditionalParticipantBookingCalendars) {
      const participants = addedParticipants?.filter(participant => availableParticipantOptions.some(availableParticipant => availableParticipant.email === participant.email));
      const remainingParticipants = addedParticipants?.filter(
        participant => !availableParticipantOptions.some(availableParticipant => availableParticipant.email === participant.email),
      );

      // Get the calendar options for each participant with email as the key
      participants?.forEach(participant => {
        const participantOption = availableParticipantOptions?.find(participantOption => participantOption.email === participant.email);
        if (!participantOption) return;
        this.participantDefaultSelectedCalendar[participant.email] = participant.booking?.calendar_id ?? 'primary';
        const calendars = participantOption.calendars ?? [];
        participantCalendars[participant.email] = calendars;
      });
      // If it is round robin config, add the remaining participant calendars to the participantCalendars
      // (Round-robin does not have an organizer, and we filtered out the participants not passed in the participantOptions prop,
      // so we need to add the remaining participants calendars to the participantCalendars)
      remainingParticipants?.forEach(participant => {
        if (participant?.booking?.calendar_id) {
          participantCalendars[participant.email] = this.calendars ?? [{ id: 'primary', name: participant.email }];
          this.participantDefaultSelectedCalendar[participant.email] = participant?.booking?.calendar_id ?? 'primary';
        }
      });
    }
    // Add the organizer's calendars to the participantCalendars
    if (organizer) {
      participantCalendars[organizer.email] = this.calendars ?? [{ id: 'primary', name: organizer.email }];
      this.participantDefaultSelectedCalendar[organizer.email] = organizer?.booking?.calendar_id ?? 'primary';
    }
    return participantCalendars;
  }

  // Lifecycle Methods
  connectedCallback() {
    debug('nylas-participant-booking-calendars', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-participant-booking-calendars', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-participant-booking-calendars', 'componentWillLoad');
  }

  componentDidLoad() {
    debug('nylas-participant-booking-calendars', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedCalendars = this.setParticipants(this.selectedConfiguration?.participants);
      this.participantCalendars = this.getParticipantCalendarOptions(this.selectedConfiguration?.participants, this.participantOptions);
    } else {
      this.selectedCalendars = this.setParticipants(this.participants);
      this.participantCalendars = this.getParticipantCalendarOptions(this.participants, this.participantOptions);
    }
  }

  @Listen('valueChanged')
  handleValueChanged(event: CustomEvent) {
    debug('[nylas-editor-tabs]', 'handleValueChanged', event);
    const { name, value } = event.detail;
    if (!name.startsWith('participant-booking-')) {
      return;
    }
    // Validate the form
    if (!this.bookingCalendarsFormRef.checkValidity()) {
      this.internals.setValidity({ customError: true }, 'Please select at least one calendar for each participant.');
      return;
    } else {
      this.internals.setValidity({});
    }

    const key = name.split('participant-booking-')[1];
    if (!this.selectedCalendars[key]) return;
    this.selectedCalendars[key]['calendar'] = value;
    this.selectedCalendars = { ...this.selectedCalendars };

    const participantsCalendars = {};
    Object.keys(this.selectedCalendars).forEach(key => {
      participantsCalendars[key] = this.selectedCalendars[key]?.calendar;
    });
    this.internals.setFormValue(JSON.stringify(participantsCalendars), this.name);
    this.valueChanged.emit({ value: JSON.stringify(participantsCalendars), name: this.name });
  }

  setParticipants(participants: Participant[]) {
    const selectedParticipants = {};
    participants?.forEach(participant => {
      if (participant?.booking?.calendar_id) {
        const isOpen = participant.is_organizer ? true : false;
        selectedParticipants[participant.email] = {
          isOpen: isOpen,
          calendar: participant.booking?.calendar_id || 'primary',
          name: participant.name || participant.email,
        };
      }
    });
    return selectedParticipants;
  }
  @RegisterComponent<NylasParticipantBookingCalendars, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-participant-booking-calendars',
    stateToProps: new Map([
      ['schedulerConfig.additionalParticipants', 'participantOptions'],
      ['schedulerConfig.calendars', 'calendars'],
      ['schedulerConfig.selectedConfiguration', 'selectedConfiguration'],
    ]),
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <div class="nylas-participant-booking-calendars" part="npbc">
          <div class="header" part="npbc__header">
            <h3>Book to this calendar</h3>
            <p>Select the calendar where you want to add event bookings.</p>
          </div>
          <div class="content" part="npbc__content">
            <form ref={el => (this.bookingCalendarsFormRef = el as HTMLFormElement)}>
              {this.participantCalendars &&
                Object.keys(this.participantCalendars).map((key, index) => {
                  const participant = this.selectedCalendars[key];
                  const participantCalendars = this.participantCalendars[key];

                  if (!participant || !participant.name) return;
                  return (
                    <div class="participant-container" part="npbc__participant-container" key={`participant-conatiner-${index}`}>
                      <div class="participant-title" part="npbc__participant-title">
                        <p>{participant.name}'s booking calendar</p>
                        <div class="participant-toggle" part="npbc__participant-toggle-container">
                          <span
                            class={`chevron ${participant.isOpen ? 'open' : 'closed'}`}
                            onClick={() => {
                              this.selectedCalendars[key].isOpen = !participant.isOpen;
                              this.selectedCalendars = { ...this.selectedCalendars };
                            }}
                          >
                            <chevron-icon width="24" height="24" />
                          </span>
                        </div>
                      </div>
                      {participant.isOpen && (
                        <nylas-booking-calendar-picker
                          key={key}
                          name={`participant-booking-${key}`}
                          calendars={participantCalendars}
                          hideHeader={true}
                          defaultBookingCalendar={this.participantDefaultSelectedCalendar[key]}
                        />
                      )}
                    </div>
                  );
                })}
            </form>
          </div>
        </div>
      </Host>
    );
  }
}
