import { NylasBaseProvider } from '@/common/abstract-provider';
import type { SchedulerEditorEventOverride } from '@/common/component-types';
import { AuthArgs, NylasIdentityRequestWrapper, User, type NylasApiRequest } from '@/common/nylas-api-request';
import { RegisteredComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { CreateNylasSchedulerConfigStore, NylasSchedulerConfigStoreState, NylasSchedulerConfigStoreType } from '@/stores/scheduler-config-store';
import { debug } from '@/utils/utils';
import { Config, NylasSessions } from '@nylas/identity';
import { Component, Element, Event, EventEmitter, Host, Listen, Method, Prop, State, Watch, h } from '@stencil/core';
import { LoginRequired } from './LoginRequired';
import ExpressFlow from './ExpressFlow';
import { AdditionalParticipant } from '@nylas/core';
import { Tab } from '../nylas-editor-tabs/tab-contents';
/**
 * The `nylas-scheduler-editor` component is a form that allows users to configure the settings for the Nylas Scheduler.
 *
 * @slot login-required - This slot is used to display a message when the user is not logged in.
 * @slot custom-page-style-inputs - This slot is used in "app" mode to pass a custom page style form to the nylas-page-styling component.
 *
 * @part nse__header - The header of the scheduler editor.
 * @part nse__title - The title of the scheduler editor.
 * @part nse__close-button - The close button of the scheduler editor. Use this part to hide the close button.
 * @part nse__content - The content of the scheduler editor. Use this part to adjust the height of the editor.
 * @part nse__list-configurations - The list of configurations. Use this part to adjust the height of the list.
 */
@Component({
  tag: 'nylas-scheduler-editor',
  styleUrl: 'nylas-scheduler-editor.scss',
  shadow: true,
})
export class NylasSchedulerEditor {
  /**
   * Stores to be used by the provider.
   */
  private stores?: {
    schedulerConfig: NylasSchedulerConfigStoreType;
  };

  /**
   * This is a base provide that defines the common methods and properties
   * that all providers should have.
   *
   * Because stencil does not support abstract classes, we have to use a
   * property to store the base provider instance.
   */
  private baseProvider?: NylasBaseProvider<Exclude<typeof this.stores, undefined>>;

  /**
   * The Nylas Scheduler Config Connector instance.
   * The brains of the provider. It manages data fetching and state synchronization.
   */
  private nylasSchedulerConfigConnector?: NylasSchedulerConfigConnector;

  /**
   * The host element.
   * Used to manage the host element of the provider.
   */
  @Element() private host!: HTMLNylasSchedulerEditorElement;

  /***
   * The configuration id to use for the scheduler editor.
   * If provided, then the scheduler editor will try and fetch the configuration
   * with the provided id. If found, it will automatically load the editor
   * with the configuration settings for editing.
   */
  @Prop({ mutable: true }) configurationId?: string;

  /**
   * The Nylas Api Request instance.
   * Used to make requests to the Nylas API.
   */
  @Prop({ mutable: true }) nylasApiRequest?: NylasApiRequest;

  /**
   * The Nylas Sessions configuration.
   * Used to configure the Nylas Sessions instance.
   */
  @Prop() nylasSessionsConfig?: Config;

  /**
   * The default authentication arguments to use when authenticating a user.
   */
  @Prop() defaultAuthArgs?: AuthArgs[];

  /**
   * This provides a way to override the default event handlers.
   */
  @Prop() eventOverrides: SchedulerEditorEventOverride = {};

  /**
   * The scheduler preview link used when the user clicks the preview button.
   * If `requiresSlug` is true, you can use the placeholder `{slug}` to insert the slug in the link for Nylas hosted scheduling pages.
   * Additionally, you can use the placeholder `{config.id}` to insert the configuration ID anywhere in the link.
   * For example: `https://book.nylas.com/us/<YOUR_NYLAS_APP_CLIENT_ID>/{slug}`.
   * `us` denotes the region. Replace it with the appropriate region of your application.
   * Replace `<YOUR_NYLAS_APP_CLIENT_ID>` with your Nylas app client ID.
   * Note: Nylas hosted scheduling pages are only available for public configurations.
   */
  @Prop({ attribute: 'scheduler-preview-link' }) schedulerPreviewLink: string = '';

  /**
   * The default scheduler config store state.
   * Used to set the initial state of the scheduler config store.
   * This state can be used to pass defaults to newly created configurations.
   */
  @Prop({ attribute: 'default-scheduler-config-store-state' }) readonly defaultSchedulerConfigState?: Partial<NylasSchedulerConfigStoreState>;

  /**
   * This is used to set the mode for the scheduler config.
   * The mode can be 'app' or 'composable'.
   * The default mode is 'app'.
   */
  @Prop({ attribute: 'mode' }) readonly mode: 'app' | 'composable' = 'app';

  /**
   * This prop is used to populate the additional participants and
   * their availability in the scheduler editor.
   */
  @Prop({ attribute: 'additional-participants' }) additionalParticipants?: AdditionalParticipant[];

  /**
   * This optional prop is used to hide tabs in the editor. Available tabs are:
   * eventInfo, availability, participants, bookingOptions, bookingForm
   */
  @Prop({ attribute: 'hide-editor-tabs' }) hideEditorTabs?: Tab[] = [];

  /**
   * This prop will be used to populate the conference provider options in the editor.
   * The key is the provider name ('zoom') and the value is the grant id.
   * We currently support same provider ('google', 'microsoft') for all participants and 'zoom' for cross-provider conferencing.
   */
  @Prop({ attribute: 'conference-providers' }) conferenceProviders?: Record<string, string>;

  /**
   * Indicates if a slug is required for the configuration.
   * When set to true, the user must enter a slug when creating or editing a configuration.
   * The slug is used for hosted pages. If using Nylas hosted scheduling pages, the `schedulerPreviewLink` prop can be set to redirect to a public configuration as follows:
   * `https://book.nylas.com/us/<YOUR_NYLAS_APP_CLIENT_ID>/{slug}`.
   * `us` denotes the region. Replace it with the appropriate region of your application.
   * Replace `<YOUR_NYLAS_APP_CLIENT_ID>` with your Nylas app client ID. The `{slug}` placeholder is replaced with the appropriate slug when the scheduler preview button is clicked.
   * Note: Nylas hosted scheduling pages are only available for public configurations.
   */
  @Prop({ attribute: 'requires-slug' }) requiresSlug: boolean = false;

  /**
   * Indicates if the user feedback feature is enabled.
   * By default, this is set to true. When enabled, the user feedback
   * feature will be available in the edit mode.
   * To disable the user feedback feature, set this prop to false.
   */
  @Prop() readonly enableUserFeedback?: boolean = true;

  /**
   * This event is fired when the provider is initialized.
   * By default, this event handles determining if the user is logged in or not.
   * In addition, it fetches the provided configuration if a valid configuration id is provided.
   */
  @Event({ cancelable: true }) readonly init!: EventEmitter<HTMLNylasSchedulerEditorElement>;

  /**
   * This event is fired when the the NylasAuth isAuthenticated state changes
   * to true.
   */
  @Event({ cancelable: true }) readonly loggedIn!: EventEmitter<HTMLNylasSchedulerEditorElement>;

  /**
   * This event is fired when the the NylasAuth isAuthenticated state changes
   * to false.
   */
  @Event({ cancelable: true }) readonly loggedOut!: EventEmitter<HTMLNylasSchedulerEditorElement>;

  /**
   * This event is fired when the close button on scheduler config is clicked.
   */
  @Event({ cancelable: true }) readonly schedulerConfigCloseClicked!: EventEmitter<HTMLNylasSchedulerEditorElement>;

  /**
   * Automatically register components that have the `@RegisterComponent` decorator.
   * If this is set to false, you will need to manually register components using the
   * `registerComponent` method.
   * @default true
   */
  @State() automaticComponentRegistration: boolean = true;

  connectedCallback() {
    debug(`[nylas-scheduler-editor] connectedCallback`);
  }

  async componentWillLoad() {
    debug(`[nylas-scheduler-editor] componentWillLoad`);
    const filteredAdditionalParticipants = this.additionalParticipants?.filter(participant => {
      const filteredCalendars = participant.calendars?.length > 0 ? participant.calendars.filter(calendar => calendar.id && calendar.name) : [];
      if (participant.name && participant.email && filteredCalendars.length > 0) {
        return {
          name: participant.name,
          email: participant.email,
          calendars: filteredCalendars,
        };
      }
    });
    this.stores = {
      schedulerConfig: CreateNylasSchedulerConfigStore({
        ...this.defaultSchedulerConfigState,
        additionalParticipants: filteredAdditionalParticipants,
        conferenceProviders: this.conferenceProviders,
        requiresSlug: this.requiresSlug,
      }),
    };

    if (!this.nylasApiRequest && this.nylasSessionsConfig) {
      const nylasSession = new NylasSessions(this.nylasSessionsConfig);

      // We must check if the user is logged in before continuing
      // otherwise, a PCKE code will not be generated and the user
      // will not be able to log in.
      await nylasSession.isLoggedIn();

      // Watch for login events so that we can automatically update the state
      nylasSession.onLoginSuccess(async () => {
        const currentUser = await this.nylasSchedulerConfigConnector?.schedulerConfig?.currentUser();
        if (currentUser) {
          const loggedInEvent = this.loggedIn.emit(this.host);
          if ('loggedIn' in this.eventOverrides) {
            await this.eventOverrides.loggedIn(loggedInEvent, this.nylasSchedulerConfigConnector);
          }
          if (!loggedInEvent.defaultPrevented) {
            await this.loggedInHandler(currentUser);
          }
        }
      });

      // Watch for logout events so that we can automatically update the state
      nylasSession.onLoginFail(async () => {
        this.stores?.schedulerConfig?.set('currentUser', null);
        const loggedOutEvent = this.loggedOut.emit(this.host);
        if ('loggedOut' in this.eventOverrides) {
          await this.eventOverrides.loggedOut(loggedOutEvent, this.nylasSchedulerConfigConnector);
          if (loggedOutEvent.defaultPrevented) {
            return;
          }
        }
      });

      this.nylasApiRequest = new NylasIdentityRequestWrapper(nylasSession);
    }

    if (this.nylasApiRequest && this.defaultAuthArgs) {
      this.nylasApiRequest.setDefaultAuthArgs(this.defaultAuthArgs);
    }

    this.baseProvider = new NylasBaseProvider(this.host, this.stores, this.automaticComponentRegistration, this.eventOverrides);

    const nylasSchedulerConfigStore = this.baseProvider.getStore('schedulerConfig');
    this.nylasSchedulerConfigConnector = new NylasSchedulerConfigConnector({
      nylasApiRequest: this.nylasApiRequest,
      nylasSchedulerConfigStore: nylasSchedulerConfigStore,
    });

    const initEvent = this.init.emit(this.host);
    if ('init' in this.eventOverrides) {
      await this.eventOverrides.init(initEvent, this.nylasSchedulerConfigConnector);
    }

    if (!initEvent.defaultPrevented) {
      const currentUser = await this.nylasSchedulerConfigConnector?.schedulerConfig?.currentUser();
      if (currentUser) {
        const loggedInEvent = this.loggedIn.emit(this.host);
        if ('loggedIn' in this.eventOverrides) {
          await this.eventOverrides.loggedIn(loggedInEvent, this.nylasSchedulerConfigConnector);
        }
        if (!loggedInEvent.defaultPrevented) {
          await this.loggedInHandler(currentUser);
        }
      } else {
        this.stores?.schedulerConfig?.set('currentUser', null);
        const loggedOutEvent = this.loggedOut.emit(this.host);
        if ('loggedOut' in this.eventOverrides) {
          await this.eventOverrides.loggedOut(loggedOutEvent, this.nylasSchedulerConfigConnector);
          if (loggedOutEvent.defaultPrevented) {
            return;
          }
        }
      }
    }

    // Watch for store changes
    this.stores?.schedulerConfig?.onChange('selectedConfiguration', async config => {
      if (config && config.id) {
        this.configurationId = config.id;
      }
    });

    this.baseProvider?.componentWillLoad(this.nylasSchedulerConfigConnector);
  }

  async componentDidLoad() {
    debug(`[nylas-scheduler-editor] componentDidLoad`);
    await this.baseProvider?.componentDidLoad();
  }

  async componentDisconnected() {
    await this.baseProvider?.componentDisconnected();
  }

  /**
   * Watch for changes to the configuration id and automatically fetch the configuration
   * when the configuration id changes. If the configuration id is cleared, then the
   * configuration is cleared as well.
   * @param newConfigurationId The new configuration id
   */
  @Watch('configurationId')
  async configurationIdChanged(newConfigurationId: string) {
    if (newConfigurationId) {
      await this.nylasSchedulerConfigConnector?.schedulerConfig?.getConfigurationById(newConfigurationId);
      this.stores?.schedulerConfig?.set('action', 'edit');
    } else {
      // Clear the configuration
      this.stores?.schedulerConfig?.set('selectedConfiguration', {});
      this.stores?.schedulerConfig?.set('action', null);
    }
  }

  /**
   * This method is used to get the NylasSchedulerConfigStore instance.
   * You can use this instance to update or get the state of the store.
   * @returns Promise<NylasSchedulerConfigStoreType | undefined>
   */
  @Method()
  async store(): Promise<NylasSchedulerConfigStoreType | undefined> {
    return this.stores?.schedulerConfig;
  }

  /**
   * This method is used to get the NylasSchedulerConfigConnector instance.
   * You can use this instance to make requests to the Nylas API.
   * @returns Promise<NylasSchedulerConfigConnector | undefined>
   */
  @Method()
  async schedulerConnector(): Promise<NylasSchedulerConfigConnector | undefined> {
    return this.nylasSchedulerConfigConnector;
  }

  /**
   * This is a custom event handler that is used to register a component with the provider.
   * It is used by components that have the `@RegisterComponent` decorator.
   * @param event A custom event that contains the component to register
   * @returns Promise<void>
   */
  @Listen('registerComponent')
  async registerComponentHandler(event: CustomEvent<RegisteredComponent<any, any>>): Promise<void> {
    this.baseProvider?.registerComponent(event.detail);
  }

  /**
   * This is a custom event handler that is used to unregister a component with the provider.
   * It is used by components that have the `@RegisterComponent` decorator.
   * @param event A custom event that contains the component to unregister
   * @returns Promise<void>
   */
  @Listen('unregisterComponent')
  async unregisterComponentHandler(event: CustomEvent<RegisteredComponent<any, any>>): Promise<void> {
    this.baseProvider?.unregisterComponent(event.detail);
  }

  /**
   * Upon login, set the current user and fetch the configuration and calendars.
   * @param currentUser The current user that is logged in
   */
  private async loggedInHandler(currentUser: User) {
    this.stores?.schedulerConfig?.set('currentUser', currentUser);

    if (!this.nylasSchedulerConfigConnector) {
      return;
    }

    await this.nylasSchedulerConfigConnector.schedulerConfig?.getCalendars();

    if (this.configurationId) {
      const data = await this.nylasSchedulerConfigConnector.schedulerConfig?.getConfigurationById(this.configurationId);
      if (data && data[0]?.id) {
        this.stores?.schedulerConfig?.set('action', 'edit');
      }
    }
  }

  /**
   * This is a custom event handler that is used to update the state of the provider.
   */
  private handleSchedulerConfigCloseClicked = (_event: Event) => {
    const event = this.schedulerConfigCloseClicked.emit(this.host);
    if ('schedulerConfigCloseClicked' in this.eventOverrides) {
      this.eventOverrides.schedulerConfigCloseClicked(event, this.nylasSchedulerConfigConnector);
    }

    if (!event.defaultPrevented) {
      localStorage.removeItem('grant');
      localStorage.removeItem('user');
      window.location.reload();
    }
  };

  /**
   * This method is used to get the authentication URL for a provider.
   * @param provider The provider to get the authentication URL for (e.g. google, microsoft, etc.)
   * @returns Promise<string> The authentication URL to redirect the user for the provider
   */
  private authenticationUrl = async (provider?: string) => {
    if (!this.nylasApiRequest) {
      console.error(`[nylas-scheduler-editor] Nylas API Request is not initialized.`);
      return '';
    }
    const url = await this.nylasApiRequest.authenticationUrl({ provider });

    return url || '';
  };

  render() {
    const hasSlot = this.host.querySelector('[slot="custom-page-style-inputs"]');
    const selectedConfigId = this.stores?.schedulerConfig?.state.selectedConfiguration?.id;

    return (
      <Host>
        <div class="scheduler-editor-header" part="nse__header">
          <h1 class="scheduler-editor-title" part="nse__title">
            <calendar-icon width="18" height="18" />
            Scheduler Editor
          </h1>
          <button class="scheduler-editor-close" title="logout" onClick={e => this.handleSchedulerConfigCloseClicked(e)} part="nse__close-button">
            <close-icon width="18" height="18" />
          </button>
        </div>
        <div class="scheduler-editor-content" part="nse__content">
          {this.stores?.schedulerConfig?.state.currentUser && this.stores.schedulerConfig.state.action === null && (
            <div class="list-configurations" part="nse__list-configurations">
              <nylas-list-configurations exportparts="nlc__create-new-cta"></nylas-list-configurations>
            </div>
          )}
          {this.stores?.schedulerConfig?.state.currentUser && this.stores.schedulerConfig.state.action === 'create' && (
            // If the mode is 'app', then render the editor tabs in a composable mode with children for creating a new configuration (Express flow).
            <nylas-editor-tabs mode="composable">
              <ExpressFlow
                currentUser={this.stores?.schedulerConfig?.state.currentUser}
                calendars={this.stores?.schedulerConfig?.state?.calendars}
                selectedConfiguration={this.stores?.schedulerConfig?.state?.selectedConfiguration}
              />
            </nylas-editor-tabs>
          )}
          {this.stores?.schedulerConfig?.state.currentUser && this.stores.schedulerConfig.state.action === 'edit' && (
            <nylas-editor-tabs key={selectedConfigId} mode={this.mode}>
              {this.mode === 'composable' && <slot />}
              {this.mode === 'app' && hasSlot && (
                <div style={{ display: 'none' }} slot="custom-page-style-inputs" key={selectedConfigId}>
                  <slot name="custom-page-style-inputs" />
                </div>
              )}
            </nylas-editor-tabs>
          )}
          {!this.stores?.schedulerConfig?.state.currentUser && (
            <slot name="login-required">
              <LoginRequired authenticationUrl={this.authenticationUrl} />
            </slot>
          )}
        </div>
      </Host>
    );
  }
}
