import { Fragment, FunctionalComponent, h } from '@stencil/core';

export type SchedulerViewProps = {
  isLoading?: boolean;
  showNotification?: boolean;
  nylasBranding?: boolean;
};

/**
 * The Scheduler view component.
 */
export const SchedulerView: FunctionalComponent<SchedulerViewProps> = (props: SchedulerViewProps, children) => {
  const isLoading = props.isLoading;
  const showNotification = props.showNotification;
  const nylasBranding = props.nylasBranding;

  return (
    <Fragment>
      {showNotification && <nylas-notification></nylas-notification>}

      {isLoading && (
        <div class={'loading'}>
          <div class={'left'}>
            <div class={'skeleton'} />
          </div>
          <div class={'right'}>
            <div class={'skeleton'} />
            <div class={'skeleton'} />
            <div class={'skeleton'} />
            <div class={'skeleton'} />
            <div class={'skeleton'} />
          </div>
        </div>
      )}
      {!isLoading && children}
      <div class={'footer'}>
        {nylasBranding && (
          <a
            href="https://scheduler.nylas.com?utm_source=powered-by-nylas-logoutm_medium=link&utm_campaign=get-scheduler&utm_id=get-scheduler"
            class="powered-by-nylas"
            target="_blank"
            rel="noreferrer noopener"
          >
            Powered by&nbsp;<span class="sr-only">Nylas</span>
            <nylas-logo aria-hidden={true} />
          </a>
        )}
      </div>
    </Fragment>
  );
};
