import { Buffer } from "buffer";
import local from "./localStorage";
const PKCE_KEY = "pkce";
const GRANT_KEY = "grant";
const USER_KEY = "user";
export class Storage {
    Storage = new local();
    constructor(store) {
        if (store) {
            this.Storage = store;
            return;
        }
    }
    setPKCE(value) {
        const encrypt = Buffer.from(value);
        this.Storage.set(PKCE_KEY, encrypt.toString("base64"));
    }
    async getPKCE() {
        try {
            const pkce = await this.Storage.get(PKCE_KEY);
            if (pkce) {
                const b = Buffer.from(pkce, "base64");
                return b.toString("utf8");
            }
        }
        catch (error) {
            return null;
        }
        return null;
    }
    removePKCE() {
        this.Storage.remove(PKCE_KEY);
    }
    setGrant(resp, multiAccount = false) {
        const { grant_id } = resp;
        this.Storage.set(`${GRANT_KEY}${multiAccount ? "-" + grant_id : ""}`, resp);
    }
    removeGrant(id, multiAccount = false) {
        this.Storage.remove(`${GRANT_KEY}${multiAccount ? "-" + id : ""}`);
    }
    async getGrant(id, multiAccount = false) {
        const grant = await this.Storage.get(`${GRANT_KEY}${multiAccount ? "-" + id : ""}`);
        if (grant) {
            return grant;
        }
        return null;
    }
    // setUserToken is used to store the IDToken in storage
    async setUserToken(token) {
        const userTokens = await this.getUserTokens();
        // If userTokens exist, check if the token is already in the array
        if (userTokens) {
            const userToken = userTokens.find((t) => t.sub === token.sub);
            // If the token is not in the array, add it
            if (!userToken) {
                const newTokens = [...userTokens, token];
                this.Storage.set(USER_KEY, newTokens);
            }
            else {
                // If the token is in the array, update it
                const newUserTokens = userTokens.map((t) => {
                    if (t.sub === token.sub) {
                        t = token;
                    }
                    return t;
                });
                this.Storage.set(USER_KEY, newUserTokens);
            }
        }
        else {
            // If userTokens do not exist, create a new array with the token
            this.Storage.set(USER_KEY, [token]);
        }
    }
    // getUserToken is used to retrieve a specific IDToken from storage
    async getUserToken(id = "") {
        const tokens = await this.Storage.get(USER_KEY);
        if (tokens) {
            if (id === "") {
                // If no id is passed, return the first token
                return tokens[0];
            }
            // Find the token with the matching sub
            const userToken = tokens.find((token) => token.sub === id);
            return userToken;
        }
        return null;
    }
    // getUserTokens is used to retrieve all IDTokens from storage
    async getUserTokens() {
        const tokens = await this.Storage.get(USER_KEY);
        if (tokens) {
            return tokens;
        }
        return null;
    }
    // removeUserTokens is used to remove all IDTokens from storage
    removeUserTokens() {
        this.Storage.remove(USER_KEY);
    }
    // removeUserToken is used to remove a specific IDToken from storage
    async removeUserToken(id) {
        const userTokens = await this.getUserTokens();
        // Filter out the token with the matching sub
        const tokens = userTokens?.filter((token) => token.sub !== id);
        // If there are tokens left, store them
        this.Storage.set(USER_KEY, tokens);
    }
    async clearSession() {
        const tokens = await this.getUserTokens();
        tokens?.forEach((token) => {
            this.removeGrant(token.sub);
        });
        this.removePKCE();
        this.removeUserTokens();
    }
}
