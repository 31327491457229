import { h } from '@stencil/core';

export type LoginRequiredProps = {
  authenticationUrl: (provider?: string) => Promise<string>;
};

export const LoginRequired = ({ authenticationUrl }: LoginRequiredProps) => (
  <div class={'login-required'} part="login-component">
    <div class={'card'}>
      <p>Log into your account to use the Scheduler Editor.</p>

      <button
        class="google-button"
        onClick={async event => {
          event.preventDefault();
          const authUrl = await authenticationUrl('google');
          if (authUrl) {
            window.location.href = authUrl;
          } else {
            console.error(`[nylas-scheduler-editor] Unable to get authentication URL.`);
          }
        }}
      >
        <span class="icon">
          <google-logo-icon width="15" height="15" />
        </span>
        Continue with Google
      </button>

      <button
        class="microsoft-button"
        onClick={async event => {
          event.preventDefault();
          const authUrl = await authenticationUrl('microsoft');
          if (authUrl) {
            window.location.href = authUrl;
          } else {
            console.error(`[nylas-scheduler-editor] Unable to get authentication URL.`);
          }
        }}
      >
        <span class="icon">
          <microsoft-logo-icon width="15" height="15" />
        </span>
        Continue with Microsoft
      </button>

      <button
        class="other-provider-button"
        onClick={async event => {
          event.preventDefault();
          const authUrl = await authenticationUrl();
          if (authUrl) {
            window.location.href = authUrl;
          } else {
            console.error(`[nylas-scheduler-editor] Unable to get authentication URL.`);
          }
        }}
      >
        View all providers <chevron-icon width="17" height="17" />
      </button>

      <small class="disclaimer-text">* You will be redirected to authenticate your account.</small>
    </div>
  </div>
);
