import { NylasSchedulerStoreType } from '@/stores/scheduler-store';
import { debug } from '@/utils/utils';
import { NylasSchedulerConnectorInterface } from '../connector-interface';
import { NylaSchedulerAPIConnector } from '../shared/api/scheduler';

type NylasSchedulerConnectorOptions = {
  schedulerAPIURL: string;
  nylasSchedulerStore: NylasSchedulerStoreType;
  sessionId?: string;
  configId?: string;
  slug?: string;
  clientId?: string;
};
/**
 * Base class for the Nylas connector.
 */
export class NylasSchedulerConnector implements NylasSchedulerConnectorInterface {
  public schedulerAPIURL: string;
  public nylasSchedulerStore: NylasSchedulerStoreType;
  public sessionId: string | undefined;
  public configId: string | undefined;
  public slug: string | undefined;
  public clientId: string | undefined;

  private _schedulerAPIConnector: NylaSchedulerAPIConnector | undefined;

  constructor({ schedulerAPIURL, nylasSchedulerStore, sessionId, configId, slug, clientId }: NylasSchedulerConnectorOptions) {
    if (!schedulerAPIURL) {
      throw new Error('schedulerAPIURL is required');
    }

    if (!nylasSchedulerStore) {
      throw new Error('nylasSchedulerStore is required');
    }

    if (!sessionId && !configId && !(slug || clientId)) {
      throw new Error('sessionId is required for private configs. If using public config either pass a configurationId or a combination of slug and clientId.');
    }

    // If the url is only /, then we need to add the base url.
    // We assume that the base url is the same as the current url.
    if (schedulerAPIURL === '/') {
      const url = new URL(window.location.href);
      schedulerAPIURL = url.origin;
    }

    this.configId = configId;
    this.sessionId = sessionId;
    this.schedulerAPIURL = schedulerAPIURL;
    this.nylasSchedulerStore = nylasSchedulerStore;
    this.slug = slug;
    this.clientId = clientId;
  }

  public init() {
    debug('NylasConnector init');
  }

  public get schedulerStore() {
    return this.nylasSchedulerStore;
  }

  public get scheduler() {
    if (this._schedulerAPIConnector) {
      return this._schedulerAPIConnector;
    }
    this._schedulerAPIConnector = new NylaSchedulerAPIConnector({
      schedulerAPIURL: this.schedulerAPIURL,
      schedulerStore: this.schedulerStore,
      sessionId: this.sessionId,
      configId: this.configId,
      slug: this.slug,
      clientId: this.clientId,
    });
    return this._schedulerAPIConnector;
  }

  public resetStores() {
    this.nylasSchedulerStore.reset();
  }
}
export type PublicMethods<T> = {
  [K in keyof T]: T[K] extends Function ? (string extends K ? never : K) : never;
}[keyof T];

export type NylasSchedulerConnectorPublicMethods = PublicMethods<NylasSchedulerConnector>;
