import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration } from '@nylas/core';

const defaultRoundToMinutes = 15;
/**
 * The `nylas-timeslot-interval` component allows users to set their time slot interval preferences.
 * This component provides an option to set both `interval_minutes` and `round_to` in the configuration.
 *
 * @part nti - The timeslot interval container
 * @part nti__header - The header of the timeslot interval picker
 * @part nti__input-label - The input label of the timeslot interval picker
 * @part nti__dropdown - The dropdown container
 * @part nti__dropdown-button - The dropdown button
 * @part nti__dropdown-content - The dropdown content
 */
@Component({
  tag: 'nylas-timeslot-interval',
  styleUrl: 'nylas-timeslot-interval.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasTimeslotInterval {
  @Element() host!: HTMLNylasTimeslotIntervalElement;
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @standalone
   * The name of the calendar picker.
   */
  @Prop() name: string = 'timeslot-picker';

  /**
   * @standalone
   * The event duration in minutes.
   */
  @Prop() eventDurationMinutes?: number = this.selectedConfiguration?.availability?.duration_minutes;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The internal event duration minutes state.
   */
  @State() internalEventDurationMinutes!: number;

  /**
   * The selected interval minutes.
   */
  @State() selectedInterval!: number;

  /**
   * The selected round to minutes.
   */
  @State() selectedRoundTo!: boolean;

  /***
   * The state to store that component is ready to render.
   */
  @State() componentLoaded: boolean = false;

  /**
   * This event is fired when the selected booking calendar changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-timeslot-interval', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-timeslot-interval', 'selectedConfigurationChangedHandler', newValue);
    this.internalEventDurationMinutes = newValue?.availability?.duration_minutes ?? this.eventDurationMinutes ?? 30;
    const internalIntervalMinutes = newValue?.availability?.interval_minutes ?? this.internalEventDurationMinutes ?? 30;
    this.selectedInterval = newValue?.availability?.interval_minutes ?? internalIntervalMinutes ?? 10;
    const roundToMinutes = newValue?.availability?.round_to || null;
    this.selectedRoundTo = roundToMinutes && roundToMinutes === internalIntervalMinutes ? true : false;
  }

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-timeslot-interval', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-timeslot-interval', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-timeslot-interval', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-timeslot-interval', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.internalEventDurationMinutes = this.eventDurationMinutes ?? 30;
      const internalIntervalMinutes = this.internalEventDurationMinutes;
      this.selectedInterval = internalIntervalMinutes ?? 10;
      this.selectedRoundTo = false;
    }
    this.componentLoaded = true;
  }

  componentWillUpdate() {
    debug('nylas-timeslot-interval', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-timeslot-interval', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-timeslot-interval', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-timeslot-interval', 'componentDidRender');
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-timeslot-interval', 'nylasFormDropdownChangedHandler', event.detail);
    const value = event.detail.value;
    if (value === 'duration') {
      this.selectedInterval = this.internalEventDurationMinutes ?? 10;
    } else {
      this.selectedInterval = parseInt(event.detail.value);
    }

    this.valueChanged.emit({
      value: JSON.stringify({
        interval: this.selectedInterval,
        roundTo: this.selectedRoundTo ? this.selectedInterval : defaultRoundToMinutes,
      }),
      name: this.name,
    });
    if (typeof this.internals.setFormValue === 'function') {
      this.internals.setFormValue(JSON.stringify({ interval: this.selectedInterval, roundTo: this.selectedRoundTo ? this.selectedInterval : defaultRoundToMinutes }), this.name);
    }
  }

  @Listen('nylasFormRadioChanged')
  nylasFormRadioChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-timeslot-interval', 'nylasFormRadioChangedHandler', event.detail);
    const value = event.detail.value;
    if (value === 'no-rounding') {
      this.selectedRoundTo = false;
    } else {
      this.selectedRoundTo = true;
    }

    this.valueChanged.emit({
      value: JSON.stringify({
        interval: this.selectedInterval,
        roundTo: this.selectedRoundTo ? this.selectedInterval : defaultRoundToMinutes,
      }),
      name: this.name,
    });
    if (typeof this.internals.setFormValue === 'function') {
      this.internals.setFormValue(JSON.stringify({ interval: this.selectedInterval, roundTo: this.selectedRoundTo ? this.selectedInterval : defaultRoundToMinutes }), this.name);
    }
  }

  @RegisterComponent<NylasTimeslotInterval, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-timeslot-interval',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    fireRegisterEvent: true,
  })
  render() {
    const intervalPreferenceOptions = [
      { value: 'duration', label: 'Length of the booking' },
      { value: '5', label: '5 mins' },
      { value: '10', label: '10 mins' },
      { value: '15', label: '15 mins' },
      { value: '20', label: '20 mins' },
      { value: '30', label: '30 mins' },
      { value: '45', label: '45 mins' },
      { value: '60', label: '60 mins' },
    ];

    const defaultSelectedInterval =
      typeof this.selectedInterval !== 'undefined'
        ? this.selectedInterval == this.internalEventDurationMinutes
          ? intervalPreferenceOptions[0]
          : intervalPreferenceOptions.find(op => op.value == this.selectedInterval.toString()) ?? intervalPreferenceOptions[0]
        : undefined;

    return (
      <Host>
        <div class="nylas-timeslot-interval" part="nti">
          <div class="header" part="nti__header">
            <h3>Time slot settings</h3>
          </div>

          <div class="nylas-timeslot-interval__dropdown">
            <span class="label" part="nti__input-label">
              Time slot interval.
            </span>
            {defaultSelectedInterval?.label && (
              <select-dropdown
                name="interval-minutes"
                options={intervalPreferenceOptions}
                withSearch={false}
                exportparts="sd_dropdown: nti__dropdown, sd_dropdown-button: nti__dropdown-button, sd_dropdown-content: nti__dropdown-content"
                defaultSelectedOption={defaultSelectedInterval}
              />
            )}
            <span slot="label" class="label" part="nti__input-label">
              Time slot rounding type
              <tooltip-component>
                <info-icon slot="tooltip-icon" />
                <span slot="tooltip-content">Select if you want to round the time slots to the nearest interval.</span>
              </tooltip-component>
            </span>
            {this.componentLoaded && (
              <radio-button-group
                name="interval-preference"
                defaultSelectedValue={this.selectedRoundTo ? 'rounding' : 'no-rounding'}
                options={[
                  { value: 'no-rounding', label: 'Default', description: 'Time slots will be rounded to the nearest quarter-hour.' },
                  {
                    value: 'rounding',
                    label: 'Interval rounding',
                    description: 'Time slots will begin at times rounded to the interval value.',
                  },
                ]}
              />
            )}
          </div>
        </div>
      </Host>
    );
  }
}
