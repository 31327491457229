import { RouterInterface } from '@/routers/router-interface';
import { NylasAuthStoreType } from '@/stores/auth-store';
import { NylasSchedulerConfigStoreType } from '@/stores/scheduler-config-store';
import { NylasSchedulerStoreType } from '@/stores/scheduler-store';
import { debug } from '@/utils/utils';
import type { NylasAuthType } from '@nylas/core';
import { NylasConnectorInterface } from '../connector-interface';
import { NylasAuthConnector } from '../shared/api/auth';
import { NylaSchedulerAPIConnector } from '../shared/api/scheduler';
/**
 * Base class for the Nylas connector.
 */
export class NylasConnector implements NylasConnectorInterface {
  public router: RouterInterface;
  public nylasAuth: NylasAuthType;
  public nylasAuthStore: NylasAuthStoreType;
  public nylasSchedulerStore: NylasSchedulerStoreType;
  public nylasSchedulerConfigStore: NylasSchedulerConfigStoreType;
  public schedulerAPIURL: string;

  private _authConnector: NylasAuthConnector | undefined;
  private _schedulerAPIConnector: NylaSchedulerAPIConnector | undefined;

  constructor(
    router: RouterInterface,
    nylasAuth: NylasAuthType,
    nylasAuthStore: NylasAuthStoreType,
    nylasSchedulerStore: NylasSchedulerStoreType,
    nylasSchedulerConfigStore: NylasSchedulerConfigStoreType,
    schedulerAPIURL: string = 'https://api.us.nylas.com',
  ) {
    this.router = router;
    this.schedulerAPIURL = schedulerAPIURL;
    this.nylasAuth = nylasAuth;
    this.nylasAuthStore = nylasAuthStore;
    this.nylasSchedulerStore = nylasSchedulerStore;
    this.nylasSchedulerConfigStore = nylasSchedulerConfigStore;
  }

  public init() {
    debug('NylasConnector init');
  }

  public get authStore() {
    return this.nylasAuthStore;
  }

  public get schedulerStore() {
    return this.nylasSchedulerStore;
  }

  public get schedulerConfigStore() {
    return this.nylasSchedulerConfigStore;
  }

  public get auth() {
    if (this._authConnector) {
      return this._authConnector;
    }
    this._authConnector = new NylasAuthConnector(this.nylasAuth, this.nylasAuthStore);
    return this._authConnector;
  }

  public get scheduler() {
    if (this._schedulerAPIConnector) {
      return this._schedulerAPIConnector;
    }
    this._schedulerAPIConnector = new NylaSchedulerAPIConnector({
      schedulerAPIURL: this.schedulerAPIURL,
      schedulerStore: this.schedulerStore,
    });
    return this._schedulerAPIConnector;
  }

  public getAuth() {
    return this.nylasAuth;
  }

  public resetStores() {
    this.nylasAuthStore.reset();
  }

  public async logout() {
    await this.nylasAuth.logout();
    this.resetStores();
  }
}

export type PublicMethods<T> = {
  [K in keyof T]: T[K] extends Function ? (string extends K ? never : K) : never;
}[keyof T];

export type NylasConnectorPublicMethods = PublicMethods<NylasConnector>;
