@import '../../../common/styles/variables.scss';

:host {
  display: flex;
  flex-direction: column;
  overflow: visible;
  border-radius: var(--nylas-border-radius-2x);
  width: fit-content;

  @media #{$mobile} {
    width: auto;
    min-width: 325px;
  }

  background: var(--nylas-base-0);
  position: relative;
  box-shadow:
    0px 4px 8px 2px rgba(0, 0, 0, 0.05),
    0px 8px 16px -2px rgba(0, 0, 0, 0.1);

  @include default-css-variables;
  font-family: var(--nylas-font-family);
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: 'slnt' 0;
}

:root {
  font-family: inherit;
}

.error {
  padding: 8rem 12rem;
  color: var(--nylas-base-0);
  background-color: var(--nylas-error);
  margin: 1.5rem;
  border-radius: var(--nylas-border-radius-2x);
}

.message-banner {
  text-align: right;
  color: var(--nylas-base-800);
  padding: 1rem;
  border-bottom: 1px solid var(--nylas-color-primary-200);
  margin: 0;
}

.loading {
  margin: 1.5rem;
  display: flex;
  gap: 1rem;

  .skeleton {
    border-radius: var(--nylas-border-radius-2x);
    animation: pulsate 1.5s infinite;

    &:nth-child(2) {
      animation-delay: -100ms;
    }

    &:nth-child(3) {
      animation-delay: -200ms;
    }

    &:nth-child(4) {
      animation-delay: -300ms;
    }

    &:nth-child(5) {
      animation-delay: -400ms;
    }
  }

  .left .skeleton {
    height: 100%;
    width: 400px;
  }

  .right .skeleton {
    height: 5rem;
    width: 300px;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@keyframes pulsate {
  0% {
    background-color: var(--nylas-base-50);
  }

  50% {
    background-color: var(--nylas-base-100);
  }

  100% {
    background-color: var(--nylas-base-50);
  }
}

.select-date-page,
.additional-data-page,
.booked-event-page,
.manual-confirmation-page,
.cancelled-event-page,
.cancel-flow-page {
  border-top-left-radius: var(--nylas-border-radius-2x);
  border-top-right-radius: var(--nylas-border-radius-2x);
}

.select-date-page,
.additional-data-page {
  display: flex;

  @media #{$mobile} {
    flex-direction: column;
  }
}

.booked-event-page,
.manual-confirmation-page {
  background-color: var(--nylas-base-50);
}

.cancelled-event-page,
.manual-confirmation-page,
.cancel-flow-page {
  padding: 1rem;
  height: 496px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.booked-event-page,
.manual-confirmation-page,
.cancelled-event-page,
.cancel-flow-page {
  min-width: 784px;

  @media #{$mobile} {
    min-width: 90%;
  }

  background: var(--nylas-base-50);
}

.left-panel {
  display: flex;
  flex-direction: column;
  min-width: 424px;
  border-top-left-radius: inherit;
  background: var(--nylas-base-0);
  position: relative;
  padding: 1rem 0 0 0;
  height: 624px;

  @media #{$mobile} {
    border-top-right-radius: inherit;
    min-width: unset;
    height: auto;
  }
}

.select-date-page .left-panel {
  @media #{$mobile} {
    padding: 0.5rem 4px 0;
    height: 624px;
  }
}

.border {
  margin-left: -20px; // Offset left padding
  margin-right: -20px; // Offset right padding
  border-top-width: 1px;
  height: 0.5px;
  background-color: var(--nylas-base-200);

  @media #{$mobile} {
    display: none;
  }
}

.right-panel {
  min-width: 360px;
  width: 360px;
  background-color: var(--nylas-base-50);
  height: 624px;
  padding-top: 16px;
  border-left: 1px solid var(--nylas-base-200);
  border-top-right-radius: inherit;

  &.reschedule {
    border-top-right-radius: 0px;
  }

  @media #{$mobile} {
    border-top-right-radius: 0px;
    width: inherit;
    min-width: unset;
    height: auto;
  }

  h2 {
    display: flex;
    margin: 0;
    height: 48px;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    width: auto;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    color: var(--nylas-base-800);
  }
}

.additional-data-page {
  .left-panel {
    background-color: var(--nylas-base-50);
  }

  .right-panel {
    background-color: var(--nylas-base-0);
  }
}

nylas-selected-event-card {
  height: calc(640px - 36px);
  padding: 0 1rem;

  @media #{$mobile} {
    height: 250px;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  font-size: 14px;
  font-weight: 300;
  color: var(--nylas-base-500);
  border-top: 1px solid var(--nylas-base-200);
  min-height: 24px;

  .powered-by-nylas {
    display: flex;
    align-items: center;
    text-decoration: none;

    color: var(--nylas-base-500);

    &:hover {
      color: var(--nylas-base-800);
    }
  }

  svg {
    fill: var(--nylas-base-500);
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.footer svg {
  margin-top: 1px;
}

nylas-selected-event-card::part(nsec__timezone) {
  position: absolute;
  bottom: 0;
}

nylas-notification {
  margin: 1.5rem;
  width: -webkit-fill-available;
  width: -moz-available;
  position: absolute;
}

nylas-locale-switch {
  position: absolute;
  padding: 18px 20px;
  bottom: 0;
  width: -webkit-fill-available;
  width: -moz-available;
  border-top: 1px solid var(--nylas-base-200);

  @media #{$mobile} {
    height: auto;
  }
}

nylas-date-picker {
  padding: 0 20px;
}

button-component#report-issue {
  width: auto;
  position: absolute;
  bottom: 4px;
  left: 16px;
}

nylas-feedback-form {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  border-radius: inherit;
}
