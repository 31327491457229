import { RegisterComponent } from '@/common/register-component';
import { REMINDER_MINUTE_OPTIONS, REMINDER_HOUR_OPTIONS, REMINDER_DAY_OPTIONS } from '@/common/constants';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Host, Listen, State, h, Element, Prop, Watch, Event, EventEmitter } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';

const reminderTimeOptions = [
  { value: 60 * 24, label: 'day' },
  { value: 60, label: 'hour' },
  { value: 1, label: 'minute' },
];

function getInitialTimeValue(timeInMinutes: number | undefined) {
  if (!timeInMinutes) return 30; // Default to 30 minutes if undefined

  // Loop through the reminderTimeOptions to find the appropriate division
  for (const option of reminderTimeOptions) {
    if (timeInMinutes % option.value === 0) {
      return timeInMinutes / option.value; // Return the divided value
    }
  }

  // If no exact division is found, return the time in minutes
  return timeInMinutes;
}

function getInitialTimeIncrement(timeInMinutes: number | undefined) {
  if (!timeInMinutes) return 1; // Default to minutes if undefined

  // Loop through the reminderTimeOptions and check divisibility
  for (const option of reminderTimeOptions) {
    if (timeInMinutes % option.value === 0) {
      return option.value;
    }
  }

  // If no suitable option is found, default to minutes
  return 1;
}

/**
 * The `nylas-reminder-time` component is a form input for the reminder time before an event.
 *
 * @part nrt - The event reminder container
 * @part nrt - The dropdown container for the reminder time increment
 * @part nrt__dropdown-button - The dropdown button for the reminder time increment
 * @part nrt__dropdown-content - The dropdown content for the reminder time increment
 * @part nrt__input_dropdown - The input dropdown container for the reminder time minutes
 * @part nrt__input_dropdown-input - The input for the reminder time minutes
 * @part nrt__input_dropdown-content - The dropdown content for the input reminder time minutes
 */
@Component({
  tag: 'nylas-reminder-time',
  styleUrl: 'nylas-reminder-time.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasReminderTime {
  /**
   * The host element <nylas-reminder-time>
   */
  @Element() host!: HTMLElement;
  /**
   * The name of the reminder time. Default is 'reminder time'.
   */
  @Prop() name: string = 'reminder-time';
  /**
   * The event reminder time in minutes as set in the configuration.
   */
  @Prop() eventReminderTimeMinutes?: number;
  /**
   * The selected event reminder time state. This defaults to the reminder time from the configuration or 30 minutes.
   */
  @State() reminderTime: number = getInitialTimeValue(this.eventReminderTimeMinutes);
  /**
   * The reminder time in minutes. This defaults to the reminder time from the configuration or 30 minutes.
   */
  @State() reminderTimeMinutes: number = this.eventReminderTimeMinutes ?? 30;
  /**
   * The reminder time increment. This defaults to minute(s).
   */
  @State() reminderTimeincrement: number = getInitialTimeIncrement(this.eventReminderTimeMinutes);
  /**
   * The reminderTimeMinutesOptions for the dropdown.
   */
  @State() reminderTimeMinutesOptions = REMINDER_MINUTE_OPTIONS;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-reminder-time', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('reminderTimeMinutes')
  reminderTimeMinutesChangedHandler(newValue: number, oldValue: number) {
    debug('nylas-reminder-time', 'reminderTimeMinutesChangedHandler', newValue);
    if (newValue === oldValue) {
      return;
    }
    this.valueChanged.emit({ value: newValue.toString(), name: this.name });
  }

  /**
   * Event emitted when the event reminder time changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  connectedCallback() {
    debug('nylas-reminder-time', 'connectedCallback');
  }

  componentWillLoad() {
    debug('nylas-reminder-time', 'componentWillLoad');
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-reminder-time', 'componentDidLoad');
    this.setReminderTimeMinuteOptions();
  }

  disconnectedCallback() {
    debug('nylas-reminder-time', 'disconnectedCallback');
  }

  /**
   * The unit tests in stencil does not support the internals API.
   * This method checks if the internals API is available.
   * @returns boolean
   */
  get isInternalsAvailable() {
    return this.internals !== undefined && typeof this.internals.setFormValue === 'function';
  }

  setReminderTimeMinuteOptions() {
    if (this.reminderTimeincrement === 1) {
      this.reminderTimeMinutesOptions = REMINDER_MINUTE_OPTIONS;
    } else if (this.reminderTimeincrement === 60) {
      this.reminderTimeMinutesOptions = REMINDER_HOUR_OPTIONS;
    } else this.reminderTimeMinutesOptions = REMINDER_DAY_OPTIONS;
    return this.reminderTimeMinutesOptions;
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-calendar-picker', 'nylasFormDropdownChangedHandler', event.detail);
    const { value } = event.detail;
    this.reminderTimeincrement = parseInt(value);

    if (this.reminderTimeincrement === 1) {
      this.reminderTime = 60;
    } else {
      this.reminderTime = 1;
    }

    this.setReminderTimeMinuteOptions();
    this.reminderTimeMinutes = this.reminderTime * this.reminderTimeincrement;
    this.isInternalsAvailable && this.internals.setFormValue(this.reminderTimeMinutes.toString(), this.name);
  }

  @Listen('inputOptionChanged')
  inputOptionChangedHandler(event: CustomEvent<{ value: number; name: string }>) {
    debug('nylas-calendar-picker', 'inputOptionChangedHandler', event.detail);
    const { value } = event.detail;
    this.reminderTime = value;
    this.reminderTimeMinutes = this.reminderTime * this.reminderTimeincrement;
    this.isInternalsAvailable && this.internals.setFormValue(this.reminderTimeMinutes.toString(), this.name);
  }

  @RegisterComponent<NylasReminderTime, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-reminder-time',
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <div class="nylas-reminder-time" part="nrt">
          <div class="nylas-reminder-time__wrapper">
            <input-dropdown
              name={'reminder-time'}
              options={this.reminderTimeMinutesOptions}
              inputValue={this.reminderTime.toString()}
              exportparts="id_dropdown: nrt__input_dropdown, id_dropdown-input: nrt__input_dropdown-input, id_dropdown-content: nrt__input_dropdown-content"
              defaultInputOption={this.reminderTimeMinutesOptions.find(i => i.value == this.reminderTime) ?? reminderTimeOptions[0]}
            />
            <select-dropdown
              name={'reminder-time-unit'}
              options={[...reminderTimeOptions].sort((a, b) => a.value - b.value)}
              pluralizedLabel={this.reminderTime > 1 ? 's' : ''}
              exportparts="sd_dropdown: nrt__dropdown, sd_dropdown-button: nrt__dropdown-button, sd_dropdown-content: nrt__dropdown-content"
              defaultSelectedOption={reminderTimeOptions.find(i => i.value == this.reminderTimeincrement) ?? reminderTimeOptions[0]}
              withSearch={false}
            />
          </div>
        </div>
      </Host>
    );
  }
}
