import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug, sanitize } from '@/utils/utils';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Appearance, Configuration } from '@nylas/core';

/**
 * The `nylas-page-styling` component is a UI component that allows users to customize the styling of the scheduling page.
 * To use this component, pass a slot `custom-page-style-inputs` to the `nylas-scheduler-editor` component with the input
 * fields you want to display. The component will automatically update the appearance object when the input fields are changed.
 * Ensure that the input fields have the `name` attribute set to the key in the appearance object.
 *
 * If you want to style the Nylas hosted scheduler page, you can use this component to customize the appearance of the page.
 * The fields that can be customized in the Nylas hosted scheduler page are:
 * - Primary color: (name: color)
 * - Company logo: (name: company_logo_url)
 * - Submit button label: (name: submit_button_label)
 * - Thank you message: (name: thank_you_message)
 *
 * This component cannot be used as an independent component. It must be used within the `nylas-scheduler-editor` component.
 *
 * @slot custom-page-style-inputs - This slot is used to pass a custom page style form to the Nylas Scheduler Editor component.
 * @part nps - The nylas-page-styling container
 * @part nps__header - The header of the page styling section
 * @part nps__drawer-toggle--container - The page styling drawer toggle container
 * @part nps__body - The body of the page styling section
 * @part nps__title-input-textfield - The page styling page title input textfield
 * @part nps__company-name-input-textfield - The page styling company name input textfield
 * @part nps__input-image-url - The page styling company logo input textfield
 * @part nps__color-picker - The page styling color picker
 * @part nps__color-picker-button - The page styling color picker button
 * @part nps__color-picker-button-label - The page styling color input field label *
 * @part nps__color-picker-button-selected-label - The page styling color picker label denoting which color is selected
 * @part nps__submit-button-label-input-textfield - The page styling submut button label input textfield
 * @part nps__message-textarea - The page styling thank you message textarea
 */
@Component({
  tag: 'nylas-page-styling',
  styleUrl: 'nylas-page-styling.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasPageStyling {
  @Element() host!: HTMLNylasPageStylingElement;
  /**
   * @internal
   * The selected configuration.
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @internal
   * The name of the component
   */
  @Prop() name: string = 'page-styling';
  /**
   * @internal
   * The appearance data to display
   */
  @Prop() appearance?: Appearance;
  /**
   * @internal
   * Is the page styling card open
   */
  @Prop() isOpen: boolean = true;
  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The confirmation email template state.
   */
  @State() currentAppearance!: Appearance;
  /**
   * The confirmation email template state.
   */
  @State() customInputsSlot: Element | null = null;

  /**
   * This event is fired when the email reminders change.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
    valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void;
  }>;

  /**
   * This event is fired when the form is submitted in the parent component.
   */
  @Event() bookingFormSubmitted!: EventEmitter<void>;

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-page-styling', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-page-styling', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-page-styling', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-page-styling', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    }
  }

  componentWillUpdate() {
    debug('nylas-page-styling', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-page-styling', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-page-styling', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-page-styling', 'componentDidRender');
  }

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-page-styling', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  customInputsSlotChangedHandler(newValue: Element | null) {
    debug('nylas-page-styling', 'customInputsSlotChangedHandler', newValue);

    const appearance = this.currentAppearance;

    function updateInputDefaultValues(element: HTMLElement) {
      // Process elements with a 'name' attribute
      if (element.hasAttribute && element.hasAttribute('name')) {
        const key = element.getAttribute('name');
        if (appearance && key && key in appearance && appearance[key] !== undefined) {
          // Update attributes based on component type
          const componentType = element.getAttribute('component-type');

          element.setAttribute('data-page-styling', 'true');

          if (componentType === 'checkbox') {
            element.setAttribute('checked', appearance[key]);
          } else if (componentType === 'radio-group') {
            element.setAttribute('default-selected-value', appearance[key]);
          } else if (componentType === 'color-picker') {
            element.setAttribute('default-selected-color', appearance[key]);
          } else if (componentType === 'select-dropdown') {
            const options = element.getAttribute('options');
            const selectedOption = options ? JSON.parse(options).find((option: { value: string }) => option.value === appearance[key]) : null;
            element.setAttribute('default-selected-option', JSON.stringify(selectedOption));
          } else if (componentType === 'image-url') {
            element.setAttribute('image-url', appearance[key]);
          } else {
            // Default case: set the default value
            element.setAttribute('default-value', appearance[key]);
          }
        }
      }

      // Recursively process child elements (skip text nodes)
      element.childNodes.forEach(child => {
        if (child.nodeType === Node.ELEMENT_NODE) {
          updateInputDefaultValues(child as HTMLElement); // Cast child to HTMLElement
        }
      });
    }

    // Start the recursive process with the cloned element (newValue)
    if (newValue) {
      updateInputDefaultValues(newValue as HTMLElement);
    }
  }

  cloneAndCopyProps(element: HTMLElement): HTMLElement {
    // Clone the current element
    const clonedElement = element.cloneNode(false) as HTMLElement; // Do a shallow clone first
    const componentType = element.getAttribute('component-type');
    const key = element.getAttribute('name');

    // Copy properties like 'options' and 'value' for custom elements
    if ((element as any).options) {
      const options = (element as any).getAttribute('options');
      console.log('page-styling options', element, options);
      if (typeof options === 'string') {
        try {
          const parsedOptions = JSON.parse(options);
          (clonedElement as any).options = parsedOptions; // Parse the 'options' string
          if (componentType === 'select-dropdown' && key && key in this.currentAppearance) {
            const selectedOption = parsedOptions.find((option: { value: string }) => option.value === this.currentAppearance[key]);
            (clonedElement as any).setAttribute('default-selected-option', JSON.stringify(selectedOption));
          }
        } catch (e) {
          console.error('Error parsing options', e);
        }
      } else {
        (clonedElement as any).options = (element as any).options; // Copy 'options' property
      }
    }

    if ((element as any).value) {
      (clonedElement as any).value = (element as any).value; // Copy 'value' property
    }

    // Recursively process child elements
    element.childNodes.forEach(child => {
      if (child.nodeType === Node.ELEMENT_NODE) {
        // If the child is an element, recursively clone and process it
        const clonedChild = this.cloneAndCopyProps(child as HTMLElement);
        clonedElement.appendChild(clonedChild); // Append the cloned child to the parent clone
      } else if (child.nodeType === Node.TEXT_NODE) {
        // For text nodes, just clone and append the text content
        clonedElement.appendChild(child.cloneNode(true));
      }
    });

    return clonedElement; // Return the cloned element with its children
  }

  selectedConfigurationChangedHandler(newValue: Configuration) {
    debug('nylas-page-styling', 'selectedConfigurationChangedHandler', newValue);
    this.currentAppearance = newValue?.appearance || {};
    // Get the slot element
    const editor = document.querySelector('nylas-scheduler-editor');
    const slotElement = editor?.shadowRoot?.querySelector('slot[name="custom-page-style-inputs"]') as HTMLSlotElement;
    if (!slotElement) {
      return;
    }

    const divElement = document.createElement('div');

    // Get the slotted content (the nodes passed into the slot)
    const slottedElements = slotElement?.assignedElements({ flatten: true });
    // Clone each of the slotted nodes and append them to the cloned slot
    slottedElements?.forEach(element => {
      const clonedElement = this.cloneAndCopyProps(element as HTMLElement); // Clone recursively and copy properties

      // Clone the slotted element (deep clone)
      this.customInputsSlotChangedHandler(clonedElement);
      // Append the cloned element to the document fragment
      divElement.appendChild(clonedElement);
    });

    // Replace the original content with the new cloned and updated content
    const bodyElement = this.host.shadowRoot?.querySelector('.nylas-page-styling');
    const slotContainer = bodyElement?.querySelector('.nylas-page-styling__body .subsection');

    if (slotContainer) {
      // Remove existing children (if necessary) and append the new content
      slotContainer.innerHTML = ''; // Clear existing content
      divElement.childNodes.forEach(child => {
        slotContainer.appendChild(child); // Append each child of divElement
      });
      this.customInputsSlot = divElement;
    }
  }

  checkIfElementIsInSlot(name: string): boolean {
    const findElement = document?.querySelector(`[slot="custom-page-style-inputs"] [name="${name}"]`);
    return findElement ? true : false;
  }

  @Listen('nylasFormInputChanged', { target: 'document' })
  async nylasFormInputChangeHandler(event: CustomEvent<{ value: string; name: string; type?: string }>) {
    const { name, value } = event.detail;
    const type = event.detail?.type;

    if (!this.checkIfElementIsInSlot(name)) {
      return;
    }
    switch (name) {
      case 'submit_button_label':
        this.currentAppearance = { ...this.currentAppearance, submit_button_label: value };
        break;
      case 'thank_you_message':
        if (type === 'multi_line_text') {
          const value = sanitize(event.detail.value);
          this.currentAppearance = { ...this.currentAppearance, thank_you_message: value };
        }
        break;
      default:
        this.currentAppearance = { ...this.currentAppearance, [name]: value };
        break;
    }
    this.updateConfirmationFormValue();
  }

  @Listen('nylasFormDropdownChanged', { target: 'document' })
  nylasFormDropdownChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-page-styling', 'nylasFormDropdownChangedHandler', event.detail);
    const { value, name } = event.detail;

    if (!this.checkIfElementIsInSlot(name)) {
      return;
    }
    if (name === 'color') {
      this.currentAppearance = { ...this.currentAppearance, color: value };
    } else {
      this.currentAppearance = { ...this.currentAppearance, [name]: value };
    }
    this.updateConfirmationFormValue();
  }

  @Listen('valueChanged', { target: 'document' })
  valueChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-page-styling', 'valueChangedHandler', event.detail);
    const { value, name } = event.detail;

    if (!this.checkIfElementIsInSlot(name)) {
      return;
    }
    if (name === 'company_logo_url') {
      this.currentAppearance = { ...this.currentAppearance, company_logo_url: value };
    } else {
      this.currentAppearance = { ...this.currentAppearance, [name]: value };
    }
    this.updateConfirmationFormValue();
  }

  @Listen('nylasFormCheckboxToggled', { target: 'document' })
  checkboxToggledHandler(event: CustomEvent<{ checked: boolean; name: string; label: string }>) {
    const { checked, name } = event.detail;
    if (!this.checkIfElementIsInSlot(name)) {
      return;
    }
    this.currentAppearance = { ...this.currentAppearance, [name]: checked };
    this.updateConfirmationFormValue();
  }

  @Listen('nylasFormRadioChanged', { target: 'document' })
  radioChangedHandler(event: CustomEvent<{ value: string; name: string; label: string; type: string }>) {
    const { value, name } = event.detail;
    if (!this.checkIfElementIsInSlot(name)) {
      return;
    }
    this.currentAppearance = { ...this.currentAppearance, [name]: value };
    this.updateConfirmationFormValue();
  }

  updateConfirmationFormValue() {
    debug('nylas-page-styling', 'updateConfirmationFormValue');
    this.internals.setFormValue(JSON.stringify(this.currentAppearance), this.name);
    this.valueChanged.emit({ value: JSON.stringify(this.currentAppearance), name: this.name });
  }

  toggleConfirmationEmail() {
    this.isOpen = !this.isOpen;
  }

  @RegisterComponent<NylasPageStyling, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-page-styling',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {
      valueChanged: async (
        event: CustomEvent<{ value: string; name: string; valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void }>,
        _nylasSchedulerConfigConnector: NylasSchedulerConfigConnector,
      ) => {
        const { valueChanged } = event.detail;
        // If a handler is passed, call it.
        if (valueChanged) {
          valueChanged(event);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <div part="nps" class={{ 'nylas-page-styling': true, 'no-border': !this.customInputsSlot }}>
          {this.customInputsSlot ? (
            <div class="header" part="nps__header">
              <div>
                <h3>Page styling and customization</h3>
              </div>
              <div class="drawer-toggle" part="nps__drawer-toggle--container">
                <span class={`chevron ${this.isOpen ? 'open' : 'closed'} `} onClick={() => this.toggleConfirmationEmail()}>
                  <chevron-icon width="24" height="24" />
                </span>
              </div>
            </div>
          ) : null}

          <div id="nylas-page-styling__body" class="nylas-page-styling__body" part="nps__body">
            <div class={{ 'nylas-page-styling__section': true, 'no-padding': !this.customInputsSlot }}>
              <div class="nylas-page-styling__row">
                <div class={{ subsection: true, hide: !this.isOpen }}></div>
              </div>
            </div>
          </div>
        </div>
      </Host>
    );
  }
}
