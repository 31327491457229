import { autocompleteTimeFormat, roundToNearest15Minutes, validateExactTimeFormat, validateTimeFormatInput } from '@/utils/utils';
import { Component, h, State, Prop, Event, EventEmitter, Listen, Element, Host, Watch } from '@stencil/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

type Time = { id: number; value: string };

/**
 * The `nylas-time-window-picker` component is a time picker that can be used to select a time.
 * @part time-picker - The time picker container
 * @part time-input - The time input
 * @part times - The list of times
 */
@Component({
  tag: 'nylas-time-window-picker',
  styleUrl: 'nylas-time-window-picker.scss',
  shadow: true,
})
export class TimeInput {
  @Element() el!: HTMLElement;
  private timeInput!: HTMLInputElement;
  private timeMenu!: HTMLElement;

  /**
   * The time to display in the input.
   * This is passed by the parent component and can be updated
   * using the setTime prop function provided by the parent component.
   */
  @Prop() time!: string;
  /**
   * This is the start time value if one is set by the parent component.
   * It is useful if this component is used to render an end time which
   * should not be before the start time, defining the earliest selectable time.
   */
  @Prop() minimumStartTime: string | null = null;

  /**
   * The placeholder text for the input.
   */
  @Prop() placeholder: string = 'hh:mmam/pm';
  /**
   * The name of the input.
   */
  @Prop() name!: string;
  /**
   * This sets the error state of the input.
   */
  @Prop() hasError: boolean = false;

  /**
   * The error message to display if the time is invalid.
   */
  @State() err: string = '';
  /**
   * Toggle to show the list of times.
   */
  @State() showTimes: boolean = false;
  /**
   * The aria-activedescendant attribute for the listbox element to indicate the currently active
   * option in the list box to screen readers. The value of aria-activedescendant is the ID of
   * the active option.
   */
  @State() ariaActivedescendant: string = '';
  /**
   * The list of times to display in the dropdown.
   */
  @State() times: Time[] = this.generateTimes();
  /**
   * This is used to scroll to the selected time when the time is changed.
   */
  @State() shouldAutoScroll: boolean = false;

  /**
   * This event is fired when the time is changed.
   */
  @Event() timeChange!: EventEmitter<{
    key: string;
    value: string;
  }>;

  /**
   * This event is fired when the form has an error. The parent component
   * can listen for this event and display an error message or set form validity.
   */
  @Event() formError!: EventEmitter<{
    key: string;
    message: string;
  }>;

  // Event listeners
  @Listen('click', { target: 'document', capture: true })
  handleOutsideClick(event: MouseEvent) {
    // Get the path of the event
    const path = event.composedPath();

    // Check if the path includes the host element
    const isClickInside = path.includes(this.el);

    if (!isClickInside && this.showTimes) {
      this.showTimes = false;
    }
  }

  @Watch('minimumStartTime')
  minimumStartTimeChangedHandler() {
    if (this.minimumStartTime) {
      let formattedTime = dayjs()
        .hour(parseInt(this.minimumStartTime))
        .minute(parseInt(this.minimumStartTime.slice(-4, -2)));
      if (this.minimumStartTime.slice(-2).toLowerCase() === 'pm' && parseInt(this.minimumStartTime) !== 12) {
        formattedTime = formattedTime.add(12, 'hour');
      } else if (this.minimumStartTime.slice(-2).toLowerCase() === 'am' && parseInt(this.minimumStartTime) === 12) {
        formattedTime = formattedTime.subtract(12, 'hour');
      }

      this.times = this.generateTimes();
      const firstTime = dayjs(this.times[0].value, 'hh:mma');
      let selectedTimeFormatted = dayjs(this.time, 'hh:mma');
      if (selectedTimeFormatted.isBefore(firstTime)) {
        this.err = 'Invalid';
        this.formError.emit({
          key: this.el.id,
          message: 'Invalid',
        });
      }
    }
  }

  componentDidRender() {
    if (this.showTimes && this.shouldAutoScroll) {
      const autocompletedTime = autocompleteTimeFormat(this.time);
      const optionIndex = this.times.findIndex(time => time.value === autocompletedTime);
      if (optionIndex > -1) {
        this.shouldAutoScroll = false;
        this.scrollToViewWithinParent(optionIndex);
      }
      return;
    }
  }

  private handleTimeChange(_e: Event, input: string) {
    const timePart = input.split(':');
    if (timePart[0] === '00' && timePart[1].slice(-2) == 'pm') {
      this.err = 'Invalid';
      this.formError.emit({
        key: this.el.id,
        message: 'Invalid',
      });
    }
    if (!validateTimeFormatInput(input)) {
      this.err = 'Invalid';
      this.formError.emit({
        key: this.el.id,
        message: 'Invalid',
      });
    } else {
      this.err = '';
      this.formError.emit({
        key: this.el.id,
        message: '',
      });
    }
    this.timeChange.emit({
      key: this.el.id,
      value: input,
    });
  }

  private handleTimeAutocomplete(event: Event) {
    const input = (event.target as HTMLInputElement)?.value;
    if (!validateTimeFormatInput(input)) {
      this.err = 'Invalid';
      this.formError.emit({
        key: this.el.id,
        message: 'Invalid',
      });
      return;
    }
    if (input === '') {
      const newTime = roundToNearest15Minutes().format('hh:mma');
      this.timeChange.emit({
        key: this.el.id,
        value: newTime,
      });
      return;
    }
    if (!validateExactTimeFormat(input)) {
      const autocompletedTime = autocompleteTimeFormat(input);
      this.timeChange.emit({
        key: this.el.id,
        value: autocompletedTime,
      });
      return;
    }
    this.timeChange.emit({
      key: this.el.id,
      value: input,
    });
  }

  private handleOnInput(event: Event) {
    const input = (event.target as HTMLInputElement)?.value;
    if (!validateTimeFormatInput(input)) {
      return;
    }
    if (input === '') {
      const newTime = roundToNearest15Minutes().format('hh:mma');
      const optionIndex = this.times.findIndex(time => time.value === newTime);
      if (optionIndex > -1) {
        this.scrollToViewWithinParent(optionIndex);
      }
      return;
    }
    if (!validateExactTimeFormat(input)) {
      const autocompletedTime = autocompleteTimeFormat(input);
      const optionIndex = this.times.findIndex(time => time.value === autocompletedTime);
      if (optionIndex > -1) {
        this.scrollToViewWithinParent(optionIndex);
      }
      return;
    }
  }

  private generateTimes() {
    const times: Time[] = [];
    let startTime = dayjs().set('hour', 0).set('minute', 0).set('second', 0); // Set to 12:00 am
    if (this.minimumStartTime) {
      startTime = dayjs(this.minimumStartTime, 'hh:mma');
    }
    const diff = startTime.endOf('day').diff(startTime, 'minutes');
    const iterations = Math.round(diff / 15);
    for (let i = 0; i < iterations + 1; i++) {
      // 96 represents the total number of 15-minute increments in a day (24 hours * 60 minutes / 15 minutes)
      const time = startTime.add(i * 15, 'minute');
      if (i == iterations && time.format('hh:mma').includes('am')) {
        break;
      }
      times.push({ id: i, value: time.format('hh:mma') });
    }
    return times;
  }

  private handleComboboxKeyDown(event: KeyboardEvent): void {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (!this.showTimes) {
        this.showTimes = true;
        this.shouldAutoScroll = true;
        return;
      }
      if (this.ariaActivedescendant === '') {
        this.ariaActivedescendant = this.times[0].id.toString();
        this.focusOption(0);
      } else {
        const currentIndex = this.times.findIndex(time => time.id.toString() === this.ariaActivedescendant);
        const nextIndex = currentIndex + 1 < this.times.length ? currentIndex + 1 : 0;
        this.ariaActivedescendant = this.times[nextIndex].id.toString();
        this.focusOption(nextIndex);
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (this.ariaActivedescendant === '') {
        this.ariaActivedescendant = this.times[this.times.length - 1].id.toString();
        this.focusOption(this.times.length - 1);
      } else {
        const currentIndex = this.times.findIndex(time => time.id.toString() === this.ariaActivedescendant);
        const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : this.times.length - 1;
        this.ariaActivedescendant = this.times[prevIndex].id.toString();
        this.focusOption(prevIndex);
      }
    } else if (event.key === 'Escape') {
      this.showTimes = false;
      this.timeInput.focus();
    }
  }

  private handleListboxKeydown(e: KeyboardEvent) {
    const items = this.times;
    const currentIndex = items.findIndex(item => item.id.toString() === this.ariaActivedescendant);

    if (e.key === 'ArrowDown' || (e.key === 'Tab' && !e.shiftKey)) {
      e.preventDefault();
      const nextIndex = currentIndex + 1 < items.length ? currentIndex + 1 : 0;
      this.ariaActivedescendant = items[nextIndex].id.toString();
      this.focusOption(nextIndex);
    } else if (e.key === 'ArrowUp' || (e.key === 'Tab' && e.shiftKey)) {
      e.preventDefault();
      const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : items.length - 1;
      this.ariaActivedescendant = items[prevIndex].id.toString();
      this.focusOption(prevIndex);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (this.ariaActivedescendant) {
        const option = items[currentIndex];
        this.handleTimeChange(e, option.value);
        this.showTimes = false;
        this.ariaActivedescendant = '';
        this.timeInput.focus();
      }
    } else if (e.key === 'Escape') {
      this.showTimes = false;
      this.timeInput.focus();
    }
  }

  scrollToViewWithinParent(optionIndex: number) {
    const option = this.times[optionIndex];
    const childElement = this.el.shadowRoot?.getElementById(option.id.toString()) as HTMLLIElement;
    const parentElement = this.timeMenu;

    this.ariaActivedescendant = option.id.toString();

    // Scroll child into view within parent
    const childRect = childElement.getBoundingClientRect();
    const parentRect = parentElement.getBoundingClientRect();

    if (childRect.top < parentRect.top) {
      // Child is above the visible area of the parent
      parentElement.scrollTop -= parentRect.top - childRect.top;
    } else if (childRect.bottom > parentRect.bottom) {
      // Child is below the visible area of the parent
      parentElement.scrollTop += childRect.bottom - parentRect.bottom;
    }

    if (childRect.left < parentRect.left) {
      // Child is to the left of the visible area of the parent
      parentElement.scrollLeft -= parentRect.left - childRect.left;
    } else if (childRect.right > parentRect.right) {
      // Child is to the right of the visible area of the parent
      parentElement.scrollLeft += childRect.right - parentRect.right;
    }
  }

  focusOption(index: number) {
    const option = this.times[index];
    if (!option) return; // Guard clause in case index is out of bounds

    const elementId = option.id.toString();
    const element = this.el.shadowRoot?.getElementById(elementId) as HTMLLIElement;

    if (element) {
      element.focus(); // Set focus on the element
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }

  render() {
    return (
      <Host>
        <div class="time-picker" part="time-picker">
          <input
            type="text"
            name={this.name}
            id={this.name}
            part="time-input"
            class={{
              'time-input': true,
              'error': !!this.err || this.hasError,
            }}
            ref={el => (this.timeInput = el as HTMLInputElement)}
            value={this.time}
            onClick={() => {
              this.showTimes = !this.showTimes;
              this.shouldAutoScroll = true;
            }}
            aria-haspopup="listbox"
            aria-label={this.name}
            aria-expanded={this.showTimes ? 'true' : 'false'}
            placeholder={this.placeholder}
            onKeyDown={e => this.handleComboboxKeyDown(e)}
            onInput={event => this.handleOnInput(event)}
            onBlur={event => this.handleTimeAutocomplete(event)}
          />
          {this.err && <div class="invalid-time-icon">{/* Icon here */}</div>}
          {this.showTimes && (
            <div class="times" part="times" ref={el => (this.timeMenu = el as HTMLElement)}>
              <ul tabindex="-1" role="listbox" aria-label={this.name} aria-activedescendant={this.ariaActivedescendant} onKeyDown={e => this.handleListboxKeydown(e)}>
                {this.times.map(option => (
                  <li
                    tabindex="0"
                    key={option.id}
                    id={option.id.toString()}
                    class={{
                      focused: this.ariaActivedescendant === option.id.toString(),
                    }}
                    onClick={e => {
                      this.handleTimeChange(e, option.value);
                      this.showTimes = false;
                      this.timeInput.focus();
                    }}
                    role="option"
                  >
                    {`${option.value}`}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!this.showTimes && this.err && (
            <p class="error" id="email-error">
              {this.err}
            </p>
          )}
        </div>
      </Host>
    );
  }
}
