import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AdditionalParticipant, Configuration } from '@nylas/core';
import { AttachInternals, Component, Element, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';

/**
 * The `nylas-scheduling-method` component is a UI component that displays the booking calendar picker.
 * @part nsm - The booking calendar picker container
 * @part nsm__header - The header of the booking calendar picker
 * @part nsm__input-label - The input label of the booking calendar picker
 * @part nsm__dropdown - The dropdown container
 * @part nsm__dropdown-button - The dropdown button
 * @part nsm__dropdown-content - The dropdown content
 */
@Component({
  tag: 'nylas-scheduling-method',
  styleUrl: 'nylas-scheduling-method.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasSchedulingMethod {
  @Element() host!: HTMLNylasSchedulingMethodElement;

  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @standalone
   * The name of the booking type picker.
   */
  @Prop() name: string = 'availability-method';
  /**
   * @standalone
   * The participant options passed in the additionalParticipants prop
   * from the nylas-scheduler-editor component.
   */
  @Prop() participantOptions?: AdditionalParticipant[];
  /**
   * This prop is used to display the component in a disabled state / read-only mode.
   */
  @Prop() readOnly: boolean = false;

  /**
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;

  /**
   * The selected booking type.
   */
  @State() selectedBookingType: string = '';

  /**
   * This event is fired when the selected booking calendar changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
    valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void;
  }>;

  // Lifecycle methods
  connectedCallback() {
    debug('nylas-scheduling-method', 'connectedCallback');
  }

  disconnectedCallback() {
    debug('nylas-scheduling-method', 'disconnectedCallback');
  }

  componentWillLoad() {
    debug('nylas-scheduling-method', 'componentWillLoad');
    // See comment in the @Watch('name') decorator for more information.
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-scheduling-method', 'componentDidLoad');
    const bookingtType = this.selectedConfiguration?.availability?.availability_rules?.availability_method?.toString();

    // TODO: Remove this when the internals in tests are fixed.
    if (bookingtType && typeof this.internals.setFormValue === 'function') {
      this.internals.setFormValue(bookingtType, 'booking-calendar');
    }
    this.selectedBookingType = bookingtType || 'collective';
    this.valueChanged.emit({ value: this.selectedBookingType, name: this.name });
  }

  componentWillUpdate() {
    debug('nylas-scheduling-method', 'componentWillUpdate');
  }

  componentDidUpdate() {
    debug('nylas-scheduling-method', 'componentDidUpdate');
  }

  componentWillRender() {
    debug('nylas-scheduling-method', 'componentWillRender');
  }

  componentDidRender() {
    debug('nylas-scheduling-method', 'componentDidRender');
  }

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-scheduling-method', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Listen('nylasFormDropdownChanged')
  nylasFormDropdownChangedHandler(event: CustomEvent<{ value: string; name: string }>) {
    debug('nylas-scheduling-method', 'nylasFormDropdownChangedHandler', event.detail);
    // Pass as handler so that if event.defaultPrevented by parent app, this will be skipped.
    const valueChanged = (event: CustomEvent<{ value: string; name: string }>) => {
      const { value } = event.detail;
      if (value) {
        this.selectedBookingType = value;
        this.internals.setFormValue(value, this.name);
      }
    };
    this.valueChanged.emit({ value: event.detail.value, name: this.name, valueChanged });
  }

  @RegisterComponent<NylasSchedulingMethod, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-scheduling-method',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    localPropsToProp: new Map([['additionalParticipants', 'participantOptions']]),
    eventToProps: {
      valueChanged: async (
        event: CustomEvent<{ value: string; name: string; valueChanged?: (event: CustomEvent<{ value: string; name: string }>) => void }>,
        _nylasSchedulerConfigConnector: NylasSchedulerConfigConnector,
      ) => {
        const { valueChanged } = event.detail;
        // If a handler is passed, call it.
        if (valueChanged) {
          valueChanged(event);
        }
      },
    },
    fireRegisterEvent: true,
  })
  render() {
    const bookingTypeOptions = [
      { label: 'Standard booking', value: 'collective' },
      { label: 'Round-robin max fairness', value: 'max-fairness' },
      { label: 'Round-robin max availability', value: 'max-availability' },
    ];
    const defaultBookingType = this.selectedConfiguration?.availability?.availability_rules?.availability_method?.toString();
    const hasAdditionalParticipants =
      this.participantOptions &&
      this.participantOptions?.length > 0 &&
      !!this.participantOptions[0].email &&
      !!this.participantOptions[0].name &&
      !!this.participantOptions[0].calendars &&
      this.participantOptions[0].calendars.length > 0;

    // If a default availability method is set in the configuration (developer settings), do not show the booking type picker.
    // Or, if a default availability method is not set and there are no additional participants, do not show the booking type picker.
    if ((defaultBookingType || !hasAdditionalParticipants) && !this.readOnly) {
      return null;
    }

    return (
      <Host>
        <div class="nylas-scheduling-method" part="nsm">
          <div class="header" part="nsm__header">
            <h3>Scheduling method</h3>
          </div>
          <div class="nylas-scheduling-method__dropdown">
            <span class="nylas-scheduling-method__dropdown-label">
              Select a scheduling method for the event.
              <tooltip-component>
                <info-icon slot="tooltip-icon" />
                <span slot="tooltip-content">
                  <strong>Standard Booking</strong>
                  <br />
                  - Allows 1:1 bookings, or n:1 bookings with additional participants.
                  <br />
                  <br />
                  <strong>Round-Robin Maximize Fairness</strong>
                  <br />
                  - Rotates through the list of participants you select, displaying the participant with the fewest recent bookings.
                  <br />
                  <br />
                  <strong>Round-Robin Maximize Availability</strong>
                  <br />
                  - Rotates through the list of participants you select, prioritizing participants with the fewest recent bookings, but making as many time-slots available as
                  possible.
                  <br />
                </span>
              </tooltip-component>
            </span>
            {this.readOnly ? (
              <input-component
                name="availability-method-read-only"
                type="text"
                exportparts="ic__label: nsm__input-label, ic__input: nsm__input, ic__input_wrapper: nsm__input_wrapper"
                readOnly
                defaultValue={bookingTypeOptions.find(cal => cal.value == defaultBookingType)?.label ?? bookingTypeOptions[0].label}
              />
            ) : (
              <select-dropdown
                name={this.name}
                options={bookingTypeOptions}
                withSearch={false}
                exportparts="sd_dropdown: nsm__dropdown, sd_dropdown-button: nsm__dropdown-button, sd_dropdown-content: nsm__dropdown-content"
                defaultSelectedOption={bookingTypeOptions.find(cal => cal.value == defaultBookingType) ?? bookingTypeOptions[0]}
              />
            )}
          </div>
        </div>
      </Host>
    );
  }
}
