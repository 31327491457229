import { debug } from '@/utils/utils';
import { NylasAuthStoreType, NylasAuthType } from '../../..';
import { TokenInfo } from '@nylas/core/lib/auth';
export class NylasAuthConnector {
  private nylasAuth: NylasAuthType;
  private nylasAuthStore: NylasAuthStoreType;

  constructor(nylasAuth: NylasAuthType, nylasAuthStore: NylasAuthStoreType) {
    this.nylasAuth = nylasAuth;
    this.nylasAuthStore = nylasAuthStore;
  }

  /**
   * Validates the current session and updates the store with the token info
   * if the user is authenticated.
   * If the user is not authenticated, the store is reset.
   * @returns {Promise<void>}
   */
  public async validateSession(): Promise<void> {
    const isAuthenticated = await this.isAccessTokenValid();
    if (!isAuthenticated) {
      // Reset the store if the access token is not valid
      this.logout();
    } else {
      // If the user is authenticated, update the store with the token info
      let tokenInfo = await this.nylasAuth.getTokenInfo();
      if (tokenInfo) {
        // If token is expired or about to expire (in 5 minutes), refresh it
        const expireTimeCheck = 5 * 60 * 1000; // 5 minutes
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);

        if (tokenInfo.expires_in && tokenInfo.expires_in - currentTimeInSeconds < expireTimeCheck) {
          debug('[NylasAuthConnectorMixin] Refreshing access token');
          tokenInfo = await this.nylasAuth.refreshAccessToken();
          if (!tokenInfo) {
            debug('[NylasAuthConnectorMixin] Token is expired and could not be refreshed. Logging out.');
            this.logout();
            return;
          }
        }

        this.nylasAuthStore.state.tokenInfo = tokenInfo;
      }
    }
    this.nylasAuthStore.state.isAuthenticated = isAuthenticated;
  }

  /**
   * Returns true if the user is authenticated.
   * @returns {Promise<boolean>}
   */
  public async isAuthenticated(): Promise<boolean> {
    return this.nylasAuth.isAuthenticated();
  }

  /**
   * Returns the token info if the user is authenticated.
   * @returns {Promise<TokenInfo>}
   */
  public async getTokenInfo(): Promise<TokenInfo | null> {
    return this.nylasAuth.getTokenInfo();
  }

  /**
   * Returns true if the access token is valid.
   * @returns {Promise<boolean>}
   */
  public async isAccessTokenValid(): Promise<boolean> {
    return this.nylasAuth.isAccessTokenValid();
  }

  /**
   * Removes the token info from the store and logs the user out.
   * @returns {Promise<void>}
   */
  public async logout(): Promise<void> {
    await this.nylasAuth.logout();
  }

  /**
   * Returns the URL to redirect the user to for authentication.
   * @param scopes An array of scopes to request from the user.
   * @param email The email address of the user to authenticate.
   * @param provider The provider to use for authentication.
   * @returns {Promise<string>}
   */
  public async getHostedAuthRedirectURL(scopes?: string[], email?: string, provider?: string): Promise<string> {
    return this.nylasAuth.getHostedAuthRedirectURL(scopes, email, provider);
  }

  /**
   * Exchanges the auth code for a token info and updates the store.
   * @param currentUrl The current URL of the page.
   * @returns {Promise<TokenInfo | null>}
   */
  public async manageHostedAuthCodeExchange(currentUrl: string): Promise<TokenInfo | null> {
    const url = new URL(currentUrl);
    const code = url.searchParams.get('code');

    if (code) {
      const tokenInfo = await this.nylasAuth.exchangeAuthCodeForTokenInfo(code);
      if (tokenInfo) {
        this.nylasAuthStore.state.tokenInfo = tokenInfo;
        this.nylasAuthStore.state.isAuthenticated = true;
        return tokenInfo;
      }
    }

    return null;
  }
}
