export function Base64EncodeUrl(str) {
    return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}
export function Base64DecodeUrl(str) {
    str = (str + "===").slice(0, str.length + (str.length % 4));
    return str.replace(/-/g, "+").replace(/_/g, "/");
}
export function getTimestamp() {
    return Math.floor(Date.now() / 1000);
}
export async function sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);
    // hash the message
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    // convert bytes to hex string
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
    return hashHex;
}
