import { Fragment, h } from '@stencil/core';
import { Configuration } from '@nylas/core';

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[] ? RecursivePartial<U>[] : T[P] extends object | undefined ? RecursivePartial<T[P]> : T[P];
};

/**
 * The props for the Express Flow component.
 */
export type ExpressFlowProps = {
  currentUser: any;
  calendars: any[];
  selectedConfiguration: RecursivePartial<Configuration>;
};

/**
 * The Express Flow component.
 */
export default function ExpressFlow({ currentUser, calendars }: ExpressFlowProps) {
  const defaultCalendarOption = calendars?.find(c => c.is_primary) || calendars?.[0];
  return (
    <Fragment>
      <nylas-event-info>
        <div slot="inputs">
          <nylas-event-title />
          <nylas-event-duration />
          <nylas-event-location userProvider={currentUser.provider} />
        </div>
      </nylas-event-info>
      <nylas-booking-calendar-picker expressFlow={true} calendars={calendars} currentUser={currentUser} defaultBookingCalendar={defaultCalendarOption?.id} />
      <nylas-scheduling-method exportparts="nsm, nsm__header, nsm__input-label, nsm__dropdown, nsm__dropdown-button, nsm__dropdown-content" />
      <nylas-custom-event-slug name="custom-event-slug" exportparts="nces, nces__body, nces__header, nces__drawer-toggle--container, nces__input-textfield" />
    </Fragment>
  );
}
