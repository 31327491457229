import { TokenInfo } from '@nylas/core/dist/auth';
import { createStore } from '@stencil/store';
import { DataState } from '@/common/types';

export interface NylasAuthStoreState {
  isAuthenticated: boolean;
  tokenInfo: TokenInfo | null;
  state: DataState;
}

export type NylasAuthStoreType = ReturnType<typeof CreateNylasAuthStore>;

export function CreateNylasAuthStore(defaultState: Partial<NylasAuthStoreState> = {}) {
  const defaultNylasStoreState: NylasAuthStoreState = {
    isAuthenticated: false,
    tokenInfo: null,
    state: 'ready',
    ...defaultState,
  };
  const store = createStore<NylasAuthStoreState>(defaultNylasStoreState);

  /**
   * Reset the Nylas store to its default state.
   * There is something wrong with the stencil/store reset method,
   * so we have to do it via this hack.
   */
  store.reset = () => {
    for (const key in defaultNylasStoreState) {
      const value = defaultNylasStoreState[key as keyof typeof defaultNylasStoreState];
      store.set(key as any, value);
    }
  };

  return store;
}
