class LocalStorage {
    get(key) {
        return new Promise((res) => {
            const record = window.localStorage.getItem(key);
            if (!record) {
                res(null);
            }
            else {
                res(JSON.parse(record));
            }
        });
    }
    async remove(key) {
        window.localStorage.removeItem(key);
        return new Promise((res) => {
            res(null);
        });
    }
    set(key, value) {
        window.localStorage.setItem(key, JSON.stringify(value));
        return new Promise((res) => {
            res(null);
        });
    }
}
export default LocalStorage;
