import { debug } from '@/utils/utils';
import { RouteListener, RouteParams, RoutePath, RouterInterface } from './router-interface';

export class PolyFillHashChangeEvent extends Event {
  oldURL?: string;
  newURL?: string;

  constructor(type: string, eventInitDict: HashChangeEventInit) {
    super(type, eventInitDict);
    this.oldURL = eventInitDict.oldURL;
    this.newURL = eventInitDict.newURL;
  }
}

export class HashRouter implements RouterInterface {
  private routeChangeListeners: [route: RoutePath, RouteListener<any>][] = [];

  constructor() {
    debug('HashRouter constructor');
  }

  // Destroy the router
  public destroy(): void {
    debug('HashRouter destroy');
    window.removeEventListener('hashchange', this.onRouteChange.bind(this));
  }

  // Initialize the router
  public init(): void {
    debug('HashRouter init');
    window.addEventListener('hashchange', this.onRouteChange.bind(this));

    // Trigger the initial route change
    if (typeof HashChangeEvent !== 'undefined') {
      const event: HashChangeEvent = new HashChangeEvent('hashchange', { oldURL: '', newURL: window.location.href });
      this.onRouteChange(event);
    } else {
      const event: Event = new PolyFillHashChangeEvent('hashchange', { oldURL: '', newURL: window.location.href });
      this.onRouteChange(event as HashChangeEvent);
    }
  }

  public addRouteChangeListener<Path extends RoutePath>(route: Path, listener: RouteListener<Path>): void {
    debug('HashRouter addRouteChangeListener');
    this.routeChangeListeners.push([route, listener]);
  }

  public navigate(route: string, shallow: boolean): void {
    debug('HashRouter navigate');

    // If we're navigating shallow, don't add a new history entry
    if (shallow) {
      return window.history.pushState(null, '', `#${route}`);
    }

    window.location.hash = route;
  }

  public setRoute(route: string): void {
    debug('HashRouter setRoute');
    window.location.hash = route;
  }

  // Matches a route and executes the callback
  public matchRoute(route: string, path: string): boolean {
    const routeParts = route.split('/');
    const pathParts = path.split('/');

    // If the route is `/` an the hash is empty, match the route
    debug(`HashRouter matchRoute route=${route} path=${path}`, { routeParts, pathParts });
    if (routeParts[1] === '' && pathParts[0] === '') {
      return true;
    }

    if (routeParts.length !== pathParts.length) {
      return false;
    }

    for (let i = 0; i < routeParts.length; i++) {
      const routePart = routeParts[i];
      const pathPart = pathParts[i];
      if (routePart.startsWith(':')) {
        continue;
      }
      if (routePart !== pathPart) {
        return false;
      }
    }

    return true;
  }

  // Parse the route and return the route params
  public getRouteParams<Path extends RoutePath>(route: Path): RouteParams<Path> {
    debug('HashRouter getRouteParams');
    const routeParams: RouteParams<Path> = {} as RouteParams<Path>;
    const routeParts = route.split('/');
    const routeConfigParts = window.location.hash.split('/');
    routeParts.forEach((routePart, index) => {
      if (routePart.startsWith(':')) {
        const key = routePart.substring(1) as keyof RouteParams<Path>;
        routeParams[key] = routeConfigParts[index] as RouteParams<Path>[keyof RouteParams<Path>]; // Cast the value to string
      }
    });
    return routeParams;
  }

  public getRouteWithParams(route: string, routeParams: Record<string, any>): string {
    debug('HashRouter getRouteWithParams');
    let routeWithParams = route;
    Object.keys(routeParams).forEach(key => {
      routeWithParams = routeWithParams.replace(`:${key}`, routeParams[key]);
    });
    return routeWithParams;
  }

  public getRoute(): string {
    debug('HashRouter getRoute');
    return window.location.hash;
  }

  public onRouteChange(event: HashChangeEvent): void {
    this.routeChangeListeners.forEach(async ([route, listener]) => {
      const path = event.newURL.indexOf('#') === -1 ? '' : event.newURL.substring(event.newURL.indexOf('#') + 1);
      debug(`HashRouter onRouteChange route=${route} path=${path}`, { event });
      if (!this.matchRoute(route, path)) {
        debug(`HashRouter onRouteChange route=${route} path=${path} does not match`);
        return;
      }
      const routeParams = this.getRouteParams(route) as RouteParams<RoutePath>;
      await listener(route, path, routeParams);
    });
  }

  public getBasePath(): string {
    debug('HashRouter getBasePath');
    return '';
  }
}
