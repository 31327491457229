import { NylasApiRequest } from '@/common/nylas-api-request';
import { NylasSchedulerConfigStoreType } from '@/stores/scheduler-config-store';
import { debug } from '@/utils/utils';
import { NylasSchedulerConfigAPIConnector } from '../shared/api/scheduler-config';

type NylasSchedulerConfigConnectorOptions = {
  nylasSchedulerConfigStore: NylasSchedulerConfigStoreType;
  nylasApiRequest?: NylasApiRequest;
};

export class NylasSchedulerConfigConnector {
  public nylasApiRequest?: NylasApiRequest;
  public nylasSchedulerConfigStore: NylasSchedulerConfigStoreType;

  private _schedulerConfigConnector: NylasSchedulerConfigAPIConnector | undefined;

  constructor({ nylasApiRequest, nylasSchedulerConfigStore }: NylasSchedulerConfigConnectorOptions) {
    this.nylasApiRequest = nylasApiRequest;
    this.nylasSchedulerConfigStore = nylasSchedulerConfigStore;
  }

  public init() {
    debug('NylasSchedulerConfigConnector init');
  }

  public get schedulerConfigStore() {
    return this.nylasSchedulerConfigStore;
  }

  public get schedulerConfig() {
    if (this._schedulerConfigConnector) {
      return this._schedulerConfigConnector;
    }

    this._schedulerConfigConnector = new NylasSchedulerConfigAPIConnector({
      nylasApiRequest: this.nylasApiRequest,
      nylasSchedulerConfigStore: this.nylasSchedulerConfigStore,
    });
    return this._schedulerConfigConnector;
  }

  public resetStores() {
    debug('NylasSchedulerConfigConnector resetStores');
    this.nylasSchedulerConfigStore.reset();
  }

  public async logout() {
    debug('NylasSchedulerConfigConnector logout');
    this.resetStores();
  }
}

export type PublicMethods<T> = {
  [K in keyof T]: T[K] extends Function ? (string extends K ? never : K) : never;
}[keyof T];

export type NylasSchedulerConfigConnectorPublicMethods = PublicMethods<NylasSchedulerConfigConnector>;
