@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
}

.nylas-reminder-time {
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    display: flex;
    align-items: center;
    color: var(--nylas-base-800);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    /* 21px */
    span.required {
      color: var(--nylas-error, #cc4841);
    }

    span.label-icon {
      margin-left: 4px;

      tooltip-component {
        display: flex;
      }
    }
  }
}

.nylas-reminder-time__wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  input {
    padding: 12px 16px;
    border-width: 1;
    border-radius: 8px;
    border: 1px solid var(--nylas-base-200);
    font-family: var(--nylas-font-family);
    font-size: 16px;
    line-height: 24px;
    width: 48px;
  }

  input-dropdown::part(id_dropdown-input) {
    border: 1px solid var(--nylas-base-200);
    border-radius: var(--nylas-border-radius-2x);
    padding: 14px 16px;
    width: 50px;
  }

  input-dropdown::part(id_dropdown-content) {
    width: 100%;
    min-width: unset;
    height: 160px;
  }

  select-dropdown {
    width: 120px;

    @media #{$mobile} {
      width: 100%;
    }
  }

  select-dropdown::part(sd_dropdown-button) {
    border: 1px solid var(--nylas-base-200);
  }
}
