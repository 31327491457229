import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { debug } from '@/utils/utils';
import { AttachInternals, Component, Host, State, h, Element, Prop, Watch, Event, EventEmitter, Listen } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';
import { Configuration } from '@nylas/core';

/**
 * The `nylas-min-booking-notice` component is a form input for the minimum period of notice (minutes) that a guest must
 * provide to book an event.
 *
 * @part nmbn - The minimum booking notice container
 * @part nmbn__number-dropdown - The number dropdown container
 * @part nmbn__number-dropdown-button - The number dropdown button
 * @part nmbn__number-dropdown-content - The number dropdown content
 * @part nmbn__period-dropdown - The period dropdown container
 * @part nmbn__period-dropdown-button - The period dropdown button
 * @part nmbn__period-dropdown-content - The period dropdown content
 */
@Component({
  tag: 'nylas-min-booking-notice',
  styleUrl: 'nylas-min-booking-notice.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasMinBookingNotice {
  /**
   * @standalone
   * The selected config
   */
  @Prop() selectedConfiguration?: Configuration;
  /**
   * @standalone
   * The name of the min booking notice input.
   */
  @Prop() name: string = 'min-booking-notice';
  /**
   * @standalone
   * The minimum booking notice (minutes) stored in the configuration
   */
  @Prop() minBookingNotice?: number;
  /**
   *
   * The element internals.
   */
  @AttachInternals() internals!: ElementInternals;
  /**
   * The host element <nylas-min-booking-notice>
   */
  @Element() host!: HTMLElement;

  /**
   * The selected number of minutes for the min booking notice.
   */
  @State() selectedNumber;
  /**
   * The selected period for the min booking notice.
   */
  @State() selectedPeriod;

  /**
   * When a name prop is passed, stencil does not automatically set the name attribute on the host element.
   * Since this component is form-associated, the name attribute is required for form submission.
   * This is a workaround to ensure that the name attribute is set on the host element.
   */
  @Watch('name')
  elementNameChangedHandler(newValue: string) {
    debug('nylas-min-booking-notice', 'elementNameChangedHandler', newValue);
    this.host.setAttribute('name', newValue);
  }

  @Watch('selectedConfiguration')
  selectedConfigurationChangedHandler(newVal: Configuration) {
    const minBookingNotice = newVal?.scheduler?.min_booking_notice || this.minBookingNotice || 1;
    if (minBookingNotice) {
      this.updateNumberAndPeriodFromMinutes(minBookingNotice);
    }
    if (typeof this.internals.setFormValue !== 'function') {
      return;
    }
    this.internals.setFormValue(minBookingNotice?.toString(), this.name);
  }

  /**
   * This event is fired when the value of the min booking notice changes.
   */
  @Event() valueChanged!: EventEmitter<{
    value: string;
    name: string;
  }>;

  connectedCallback() {
    debug('nylas-min-booking-notice', 'connectedCallback');
  }

  componentWillLoad() {
    debug('nylas-min-booking-notice', 'componentWillLoad');
    this.host.setAttribute('name', this.name);
  }

  componentDidLoad() {
    debug('nylas-min-booking-notice', 'componentDidLoad');
    if (this.selectedConfiguration) {
      this.selectedConfigurationChangedHandler(this.selectedConfiguration);
    } else {
      this.updateNumberAndPeriodFromMinutes(this.minBookingNotice || 1);
    }
  }

  disconnectedCallback() {
    debug('nylas-min-booking-notice', 'disconnectedCallback');
  }

  @Listen('timePeriodChanged')
  timePeriodChangedHandler(
    event: CustomEvent<{
      number: number;
      period: string;
    }>,
  ) {
    const { number, period } = event.detail;
    let totalMinutes;
    const minutesInHour = 60;
    const minutesInDay = minutesInHour * 24;
    const minutesInWeek = minutesInDay * 7;
    const minutesInMonth = minutesInDay * 30; // Assuming 30 days in a month for simplification

    if (period === 'minute') {
      totalMinutes = number;
    } else if (period === 'hour') {
      totalMinutes = number * minutesInHour;
    } else if (period === 'day') {
      totalMinutes = number * minutesInDay;
    } else if (period === 'week') {
      totalMinutes = number * minutesInWeek;
    } else if (period === 'month') {
      totalMinutes = number * minutesInMonth;
    } else {
      totalMinutes = 0;
    }
    this.valueChanged.emit({ value: totalMinutes, name: 'minBookingNotice' });
    if (typeof this.internals.setFormValue !== 'function') {
      return;
    }
    this.internals.setFormValue(totalMinutes.toString(), this.name);
  }

  private updateNumberAndPeriodFromMinutes(minutes: number): void {
    const minutesInHour = 60;
    const minutesInDay = minutesInHour * 24;
    const minutesInWeek = minutesInDay * 7;
    const minutesInMonth = minutesInDay * 30; // Assuming 30 days in a month for simplification
    let number, period;
    if (minutes >= minutesInMonth) {
      number = Math.floor(minutes / minutesInMonth);
      period = 'month';
    } else if (minutes >= minutesInWeek) {
      number = Math.floor(minutes / minutesInWeek);
      period = 'week';
    } else if (minutes >= minutesInDay) {
      number = Math.floor(minutes / minutesInDay);
      period = 'day';
    } else if (minutes >= minutesInHour) {
      number = Math.floor(minutes / minutesInHour);
      period = 'hour';
    } else {
      number = minutes;
      period = 'minute';
    }
    this.selectedNumber = number;
    this.selectedPeriod = period;
  }

  @RegisterComponent<NylasMinBookingNotice, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-min-booking-notice',
    stateToProps: new Map([['schedulerConfig.selectedConfiguration', 'selectedConfiguration']]),
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    const showTImePeriodSelector = typeof this.selectedNumber == 'number' && typeof this.selectedPeriod == 'string';
    return (
      <Host>
        <div class="nylas-min-booking-notice" part="nmbn">
          <label>
            Minimum booking notice
            <span class="label-icon">
              <tooltip-component>
                <info-icon slot="tooltip-icon" />
                <span slot="tooltip-content">The minimum amount of time in the future that a new booking can be made.</span>
              </tooltip-component>
            </span>
          </label>
          {showTImePeriodSelector && (
            <time-period-selector
              id="min-booking-notice"
              exportparts="tps__number-dropdown: nmbn__number-dropdown, tps__number-dropdown-button: nmbn__number-dropdown-button, tps__number-dropdown-content: nmbn__number-dropdown-content, tps__period-dropdown: nmbn__period-dropdown, tps__period-dropdown-button: nmbn__period-dropdown-button, tps__period-dropdown-content: nmbn__period-dropdown-content"
              timePeriods={['minute', 'hour', 'day', 'week']}
              defaultSelectedNumber={this.selectedNumber}
              defaultSelectedPeriod={this.selectedPeriod}
            />
          )}
        </div>
      </Host>
    );
  }
}
