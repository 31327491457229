import { NylasBaseProvider } from '@/common/abstract-provider';
import { RegisteredComponent } from '@/common/register-component';
import { NylasConnector } from '@/connector/nylas-connector';
import { HashRouter } from '@/routers/hash-router';
import { CreateNylasAuthStore, NylasAuthStoreType } from '@/stores/auth-store';
import { CreateNylasSchedulerStore, NylasSchedulerStoreType } from '@/stores/scheduler-store';
import type { AuthConfig, NylasAuthType } from '@nylas/core';
import { NylasAuth } from '@nylas/core';
import { Component, Element, Event, EventEmitter, Host, Listen, Method, Prop, h } from '@stencil/core';
import type { EventOverride } from '@/common/component-types';
import { debug } from '@/utils/utils';
import { CreateNylasSchedulerConfigStore, NylasSchedulerConfigStoreType } from '@/stores/scheduler-config-store';

/**
 * The Nylas Provider component.
 * This component is used to manage the Nylas Provider.
 * It is used to manage the Nylas Auth instance, the Nylas Connector instance,
 * and the Nylas Store instance.
 */
@Component({
  tag: 'nylas-provider',
  styleUrl: 'nylas-provider.css',
  shadow: true,
})
export class NylasProvider {
  /**
   * Stores to be used by the provider.
   */
  private stores?: {
    auth: NylasAuthStoreType;
    scheduler: NylasSchedulerStoreType;
    schedulerConfig: NylasSchedulerConfigStoreType;
  };

  /**
   * This is a base provide that defines the common methods and properties
   * that all providers should have.
   *
   * Because stencil does not support abstract classes, we have to use a
   * property to store the base provider instance.
   */
  private baseProvider?: NylasBaseProvider<Exclude<typeof this.stores, undefined>>;

  /**
   * The Nylas Auth instance.
   * Used to manage all things authentication with Nylas.
   */
  private nylasAuth?: NylasAuthType;

  /**
   * The Nylas Mailbox Connector instance.
   * The branins of the provider. It manages data fetching and state synchronization.
   */
  private nylasConnector?: NylasConnector;

  /**
   * The host element.
   * Used to manage the host element of the provider.
   */
  @Element() private host!: HTMLNylasProviderElement;

  /**
   * The Nylas Auth configuration.
   * Used to manage all things authentication with Nylas.
   */
  @Prop({ attribute: 'auth-config' }) readonly authConfig?: AuthConfig;

  /**
   * This provides a way to override the default event handlers.
   */
  @Prop() eventOverrides: EventOverride<Exclude<typeof this.nylasConnector, undefined>> = {};

  /**
   * Automatically register components that have the `@RegisterComponent` decorator.
   * If this is set to false, you will need to manually register components using the
   * `registerComponent` method.
   * @default true
   */
  @Prop() automaticComponentRegistration: boolean = true;

  /**
   * This event is fired when the provider is initialized.
   * It can be used to set the initial state of the provider,
   * or to prevent the provider from firing some default behavior.
   */
  @Event({ cancelable: true }) init!: EventEmitter<HTMLNylasProviderElement>;

  /**
   * This event is fired when the the NyalsAuth isAuthenticated state changes
   * to true.
   */
  @Event({ cancelable: true }) loggedIn!: EventEmitter<HTMLNylasProviderElement>;

  /**
   * This event is fired when the the NyalsAuth isAuthenticated state changes
   * to true.
   */
  @Event({ cancelable: true }) loggedOut!: EventEmitter<HTMLNylasProviderElement>;

  connectedCallback() {
    debug('[nylas-provider] connectedCallback');
  }

  async componentWillLoad() {
    debug('[nylas-provider] componentWillLoad');

    this.stores = {
      auth: CreateNylasAuthStore(),
      scheduler: CreateNylasSchedulerStore(),
      schedulerConfig: CreateNylasSchedulerConfigStore(),
    };
    this.baseProvider = new NylasBaseProvider(this.host, this.stores, this.automaticComponentRegistration, this.eventOverrides);

    const router = new HashRouter();

    /**
     * Create the NylasAuth instance.
     * This is used to manage all things authentication with Nylas.
     */
    this.nylasAuth = NylasAuth({
      apiUri: 'https://api.us.nylas.com',
      elementsApiUri: 'https://elements.us.nylas.com',
      storageType: 'indexeddb',
      clientId: 'YOUR_CLIENT_ID',
      defaultScopes: [],
      redirectURI: '/',
      ...this.authConfig,
    });

    const nylasAuthStore = this.baseProvider?.getStore('auth');
    if (!nylasAuthStore) {
      throw new Error('The auth store is not set');
    }

    const nylasSchedulerStore = this.baseProvider?.getStore('scheduler');
    if (!nylasSchedulerStore) {
      throw new Error('The scheduler store is not set');
    }

    const nylasSchedulerConfigStore = this.baseProvider?.getStore('schedulerConfig');
    if (!nylasSchedulerConfigStore) {
      throw new Error('The scheduler config store is not set');
    }

    this.nylasConnector = new NylasConnector(router, this.nylasAuth, nylasAuthStore, nylasSchedulerStore, nylasSchedulerConfigStore);

    const initEvent = this.init.emit(this.host);
    if (!initEvent.defaultPrevented) {
      /**
       * By default, on init, we will validate the session.
       * This will check if the user is logged in and if the session is still valid.
       * If the session is not valid, the user will be logged out.
       */
      await this.nylasConnector?.auth.validateSession();
    }

    /**
     * Listen for changes in the NylasAuth isAuthenticated state.
     * This is used to fire the loggedIn and loggedOut events.
     */
    this.handleAuthChange(nylasAuthStore.state.isAuthenticated);

    this.baseProvider?.componentWillLoad(this.nylasConnector);
  }

  componentDidLoad() {
    const nylasAuthStore = this.baseProvider?.getStore('auth');
    nylasAuthStore?.onChange('isAuthenticated', this.handleAuthChange);

    this.baseProvider?.componentDidLoad();
  }

  componentDisconnected() {
    this.baseProvider?.componentDisconnected();
  }

  /**
   * This is a custom event handler that is used to register a component with the provider.
   * It is used by components that have the `@RegisterComponent` decorator.
   * @param event A custom event that contains the component to register
   * @returns Promise<void>
   */
  @Listen('registerComponent')
  async registerComponentHandler(event: CustomEvent<RegisteredComponent<any, any>>): Promise<void> {
    this.baseProvider?.registerComponent(event.detail);
  }

  /**
   * This is a custom event handler that is used to unregister a component with the provider.
   * It is used by components that have the `@RegisterComponent` decorator.
   * @param event A custom event that contains the component to unregister
   * @returns Promise<void>
   */
  @Listen('unregisterComponent')
  async unregisterComponentHandler(event: CustomEvent<RegisteredComponent<any, any>>): Promise<void> {
    this.baseProvider?.unregisterComponent(event.detail);
  }

  /**
   * This method is used to handle changes in the NylasAuth isAuthenticated state
   * @param isAuthenticated isAuthenticated state
   * @returns void
   */
  private handleAuthChange = async (isAuthenticated: boolean) => {
    if (isAuthenticated) {
      this.loggedIn.emit(this.host);
    } else {
      this.loggedOut.emit(this.host);
    }
  };

  /**
   * This method is used to retrieve the NylasAuth instance
   * @returns The NylasAuth instance
   */
  @Method()
  async getNylasAuth(): Promise<NylasAuthType | undefined> {
    return this.nylasAuth;
  }

  /**
   * This method is used to retrieve the NylasConnector instance
   * @returns The NylasConnector instance
   */
  @Method()
  async getNylasConnector() {
    return this.nylasConnector;
  }

  /**
   * This method is used to retrieve the authStore instance
   * @returns The authStore instance
   */
  @Method()
  async getAuthStore(): Promise<NylasAuthStoreType | undefined> {
    return this.baseProvider?.getStore('auth');
  }

  /**
   * This method is used to retrieve the NylasScheduler instance
   * @returns The NylasScheduler instance
   */
  @Method()
  async getNylasSchedulerStore(): Promise<NylasSchedulerStoreType | undefined> {
    return this.baseProvider?.getStore('scheduler');
  }

  /**
   * This method is used to retrieve the NylasSchedulerConfig instance
   * @returns The NylasSchedulerConfig instance
   */
  @Method()
  async getNylasSchedulerConfigStore(): Promise<NylasSchedulerConfigStoreType | undefined> {
    return this.baseProvider?.getStore('schedulerConfig');
  }

  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }
}
