@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
  width: inherit;
}

.nylas-participants-custom-availability {
  width: inherit;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-radius: var(--nylas-border-radius-2x);
  border: 1px solid var(--nylas-base-200);
  .header {
    padding: 1rem;
    h3 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: var(--nylas-base-900);
      text-align: left;
    }
    p {
      margin: 0.25rem 0 0 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 20px;
      color: var(--nylas-base-600);
      text-align: left;
    }
  }
  .content {
    .participant-container {
      padding-top: 8px;
      border-top: 1px solid var(--nylas-base-200);
      .participant-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 16px 12px;
        p {
          margin: 0;
        }
        .participant-toggle {
          display: flex;
          gap: 24px;
          align-items: center;
          .chevron {
            display: flex;
            align-self: center;
            cursor: pointer;

            &:hover,
            &:active {
              color: var(--nylas-primary);
            }

            &.open {
              transform: rotate(90deg);
            }

            &.closed {
              transform: rotate(270deg);
            }

            &.disabled {
              cursor: not-allowed;
              color: var(--nylas-base-300);
              &:hover {
                color: var(--nylas-base-300);
              }
            }
          }
        }
      }
      nylas-availability-picker::part(nap) {
        margin: 0;
        border: none;
      }
    }
  }
}
