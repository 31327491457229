import { NylasApiRequest } from '@/common/nylas-api-request';
import { NylasSchedulerConfigStoreType } from '@/stores/scheduler-config-store';
import { dataResponse } from '@/utils/utils';
import { Calendar, Configuration, NylasResponse, isNylasErrorResponse } from '@nylas/core';

type NylasSchedulerConfigAPIConnectorOptions = {
  nylasApiRequest?: NylasApiRequest;
  nylasSchedulerConfigStore: NylasSchedulerConfigStoreType;
};

/**
 * Nylas Scheduler Config API Connector
 * This class is responsible for making API requests to the Nylas API for Scheduler Configurations.
 */
export class NylasSchedulerConfigAPIConnector {
  private nylasApiRequest?: NylasApiRequest;
  private nylasSchedulerConfigStore: NylasSchedulerConfigStoreType;

  constructor({ nylasApiRequest, nylasSchedulerConfigStore }: NylasSchedulerConfigAPIConnectorOptions) {
    this.nylasApiRequest = nylasApiRequest;
    this.nylasSchedulerConfigStore = nylasSchedulerConfigStore;
  }

  /**
   * Get the current user.
   * @returns The current user or null if the request is not initialized.
   */
  public async currentUser() {
    if (!this.nylasApiRequest) {
      console.warn('Nylas API Request is not initialized');
      return null;
    }

    return this.nylasApiRequest.currentUser();
  }

  /**
   * Get the current user's calendars.
   * @returns The current user's calendars or an error if the request is not initialized.
   */
  public async getCalendars() {
    if (!this.nylasApiRequest) {
      console.warn('Nylas API Request is not initialized');
      return dataResponse(null, {
        message: 'Error fetching calendars: Nylas API Request is not initialized',
      });
    }

    const [response, error] = await this.nylasApiRequest.request<NylasResponse<Calendar[]>>({
      method: 'GET',
      path: `calendars`,
    });

    if (error) {
      return dataResponse(null, {
        message: 'Error fetching calendars: ' + error.message,
      });
    }

    if (isNylasErrorResponse(response)) {
      return dataResponse(null, {
        message: 'Error fetching calendars: ' + response.error?.message,
      });
    }

    // Set the new calendars as the current calendars
    this.nylasSchedulerConfigStore.state.calendars = response.data;

    return dataResponse(response.data);
  }

  /**
   * Get a configuration by its ID.
   * @param configurationId The ID of the configuration to fetch.
   */
  public async getConfigurationById(configurationId: string, forceFetch: boolean = false) {
    if (!this.nylasApiRequest) {
      console.warn('Nylas API Request is not initialized');
      return dataResponse(null, {
        message: 'Error fetching configuration: Nylas API Request is not initialized',
      });
    }

    // If the configuration is already in the store, return it
    if (!forceFetch) {
      const existingConfig = this.nylasSchedulerConfigStore.state.configurations.find(c => c.id === configurationId);
      if (existingConfig) {
        this.nylasSchedulerConfigStore.state.selectedConfiguration = existingConfig;
        return dataResponse(existingConfig);
      }
    }

    const [response, error] = await this.nylasApiRequest.request<NylasResponse<Configuration>>({
      method: 'GET',
      path: `scheduling/configurations/${configurationId}`,
    });

    if (error) {
      return dataResponse(null, {
        message: 'Error fetching configuration: ' + error.message,
      });
    }

    if (isNylasErrorResponse(response)) {
      return dataResponse(null, {
        message: 'Error fetching configuration: ' + response.error?.message,
      });
    }

    // Set the new configuration as the current configuration
    this.nylasSchedulerConfigStore.state.selectedConfiguration = response.data;

    // If the configuration was updated, update the list of configurations
    // If it doesn't exist, add it to the list
    if (this.nylasSchedulerConfigStore.state.configurations.find(c => c.id === configurationId)) {
      this.nylasSchedulerConfigStore.state.configurations = this.nylasSchedulerConfigStore.state.configurations.map(c => (c.id === configurationId ? response.data : c));
    } else {
      this.nylasSchedulerConfigStore.state.configurations = [...this.nylasSchedulerConfigStore.state.configurations, response.data];
    }

    return dataResponse(response.data);
  }

  /**
   * Get all configurations.
   * @returns The list of configurations or an error if the request is not initialized.
   */
  public async getConfigurations(cursor?: string) {
    if (!this.nylasApiRequest) {
      console.warn('Nylas API Request is not initialized');
      return dataResponse(null, {
        message: 'Error fetching configurations: Nylas API Request is not initialized',
      });
    }
    const limit = 50;
    const [response, error] = await this.nylasApiRequest.request<NylasResponse<Configuration[]>>({
      method: 'GET',
      path: `scheduling/configurations?limit=${limit}${cursor ? `&page_token=${cursor}` : ''}`,
    });

    if (error) {
      return dataResponse(null, {
        message: 'Error fetching configurations: ' + error.message,
      });
    }

    if (isNylasErrorResponse(response)) {
      return dataResponse(null, {
        message: 'Error fetching configurations: ' + response.error?.message,
      });
    }

    // Set the configurations in the store
    this.nylasSchedulerConfigStore.state.configurations = response.data;
    this.nylasSchedulerConfigStore.state.listConfigurationsNextCursor = response.next_cursor || null;
    return dataResponse(response.data);
  }

  /**
   * Create a new configuration.
   * @param configuration The configuration to create.
   * @returns The new configuration or an error if the request is not initialized.
   */
  public async createConfiguration(configuration: Omit<Partial<Configuration>, 'id'>) {
    if (!this.nylasApiRequest) {
      console.warn('Nylas API Request is not initialized');
      return dataResponse(null, {
        message: 'Error creating configuration: Nylas API Request is not initialized',
      });
    }

    const [response, error] = await this.nylasApiRequest.request<NylasResponse<Configuration>>({
      method: 'POST',
      path: `scheduling/configurations`,
      body: configuration,
    });

    if (error) {
      return dataResponse(null, {
        message: 'Error creating configuration: ' + error.message,
      });
    }

    if (isNylasErrorResponse(response)) {
      return dataResponse(null, {
        message: 'Error creating configuration: ' + response.error?.message,
      });
    }

    // Set the new configuration as the current configuration
    this.nylasSchedulerConfigStore.state.selectedConfiguration = response.data;

    // Add the new configuration to the list of configurations
    this.nylasSchedulerConfigStore.state.configurations = [...this.nylasSchedulerConfigStore.state.configurations, response.data];

    return dataResponse(response.data);
  }

  /**
   * Update an existing configuration.
   * @param configuration The configuration to update.
   * @returns The updated configuration or an error if the request is not initialized.
   */
  public async updateConfiguration(configuration: Partial<Configuration>) {
    if (!this.nylasApiRequest) {
      console.warn('Nylas API Request is not initialized');
      return dataResponse(null, {
        message: 'Error updating configuration: Nylas API Request is not initialized',
      });
    }

    const [response, error] = await this.nylasApiRequest.request<NylasResponse<Configuration>>({
      method: 'PUT',
      path: `scheduling/configurations/${configuration.id}`,
      body: configuration,
    });

    if (error) {
      return dataResponse(null, {
        message: 'Error updating configuration: ' + error.message,
      });
    }

    if (isNylasErrorResponse(response)) {
      return dataResponse(null, {
        message: 'Error updating configuration: ' + response.error?.message,
      });
    }

    // Set the new configuration as the current configuration
    this.nylasSchedulerConfigStore.state.selectedConfiguration = response.data;

    // If the configuration was updated, update the list of configurations
    // If it doesn't exist, add it to the list
    if (this.nylasSchedulerConfigStore.state.configurations.find(c => c.id === configuration.id)) {
      this.nylasSchedulerConfigStore.state.configurations = this.nylasSchedulerConfigStore.state.configurations.map(c => (c.id === configuration.id ? response.data : c));
    } else {
      this.nylasSchedulerConfigStore.state.configurations = [...this.nylasSchedulerConfigStore.state.configurations, response.data];
    }

    return dataResponse(response.data);
  }

  /**
   * Delete a configuration by its ID.
   * @param configurationId The ID of the configuration to delete.
   * @returns The deleted configuration or an error if the request is not initialized.
   */
  public async deleteConfiguration(configurationId: string) {
    if (!this.nylasApiRequest) {
      console.warn('Nylas API Request is not initialized');
      return dataResponse(null, {
        message: 'Error deleting configuration: Nylas API Request is not initialized',
      });
    }

    const [response, error] = await this.nylasApiRequest.request<NylasResponse<Configuration>>({
      method: 'DELETE',
      path: `scheduling/configurations/${configurationId}`,
    });

    if (error) {
      return dataResponse(null, {
        message: 'Error deleting configuration: ' + error.message,
      });
    }

    if (isNylasErrorResponse(response)) {
      return dataResponse(null, {
        message: 'Error deleting configuration: ' + response.error?.message,
      });
    }

    // Remove the configuration from the list
    this.nylasSchedulerConfigStore.state.configurations = this.nylasSchedulerConfigStore.state.configurations.filter(c => c.id !== configurationId);

    // If the deleted configuration was the current configuration, reset it
    if (this.nylasSchedulerConfigStore.state.selectedConfiguration?.id === configurationId) {
      this.nylasSchedulerConfigStore.resetSelectedConfiguration();
    }

    return dataResponse(response.data);
  }
}
