import { NylasBaseProvider } from '@/common/abstract-provider';
import { RegisteredComponent } from '@/common/register-component';
import type { SchedulerEventOverride } from '@/common/component-types';
import { NylasSchedulerConnector } from '@/connector/nylas-scheduler-connector';
import { CreateNylasSchedulerStore, NylasSchedulerStoreState, NylasSchedulerStoreType } from '@/stores/scheduler-store';
import {
  NylasSchedulerBookingData,
  ThemeConfig,
  Notification,
  NotificationType,
  NylasSchedulerErrorResponse,
  Timeslot,
  NylasSchedulerResponse,
  NylasSuccessResponse,
} from '@nylas/core';
import { Component, Element, Event, EventEmitter, Host, Listen, Method, Prop, State, Watch, h } from '@stencil/core';
import { addDaysToCurrentDate, capitalizeFirstLetter, compactStringToUUIDs, debug } from '@/utils/utils';
import { SchedulerView } from './scheduler-view';
import { ErrorCategory } from '@/connector/nylas-scheduler-connector/errors';
import { MessageBanner } from './message-banner';
import i18next from '@/utils/i18n';
import { NylasSchedulerBookingDataWithFlatFields, NylasEvent, UISettingsResponse } from '@/common/types';
import { LANGUAGE_CODE, LANGUAGE_CODE_MAP, Locale } from '@/common/constants';
import * as Sentry from '@sentry/browser';

const genericError = { title: i18next.t('genericErrorTitle'), type: NotificationType.Error, category: ErrorCategory.Component };

/**
 * The `nylas-scheduling` component is used to display the Nylas Scheduling UI.
 *
 * @part ndp - [nylas-date-picker] The date picker host.
 * @part ndp__title - [nylas-date-picker] The title.
 * @part ndp__month-header - [nylas-date-picker] The month header.
 * @part ndp__month-button - [nylas-date-picker] The month button.
 * @part ndp__day - [nylas-date-picker] The day.
 * @part ndp__date - [nylas-date-picker] The date.
 * @part ndp__date--selected - [nylas-date-picker] The selected date.
 * @part ndp__date--current-day - [nylas-date-picker] The current day.
 * @part ndp__date--current-month - [nylas-date-picker] The dates in the current month.
 * @part ndp__date--disabled - [nylas-date-picker] The disabled dates.
 * @part ntp - [nylas-timeslot-picker] The timeslot picker component.
 * @part ntp__timeslot - [nylas-timeslot-picker] The timeslot button.
 * @part ntp__timeslot--selected - [nylas-timeslot-picker] The selected timeslot button.
 * @part ntp__button-primary - [nylas-timeslot-picker] The timeslot picker CTA.
 * @part nsec - [nylas-selected-event-card] The selected event card component.
 * @part nsec__card - [nylas-selected-event-card] The card component.
 * @part nsec__icon - [nylas-selected-event-card] The calendar icon.
 * @part nsec__date - [nylas-selected-event-card] The date selected.
 * @part nsec__time - [nylas-selected-event-card] The timeslot selected.
 * @part nbf - [nylas-booking-form] The booking form host.
 * @part nbf__input-textfield - [nylas-booking-form] The input textfield.
 * @part nbf__button-ghost - [nylas-booking-form] The ghost button.
 * @part nbf__button-outline - [nylas-booking-form] The outline button.
 * @part nbf__button-primary - [nylas-booking-form] The primary button.
 * @part nbf__input-wrapper - [nylas-booking-form] The input wrapper.
 * @part nbf__checkbox-component - [nylas-booking-form] The checkbox component.
 * @part nbf__radio-button-group - [nylas-booking-form] The radio button group.
 * @part nbf__textarea-component - [nylas-booking-form] The textarea component.
 * @part nbf__dropdown - [nylas-booking-form] The dropdown component.
 * @part nbf__dropdown-button - [nylas-booking-form] The dropdown button.
 * @part nbf__dropdown-content - [nylas-booking-form] The dropdown content.
 * @part ncec - [nylas-cancelled-event-card] The cancelled event card host.
 * @part ncec__icon - [nylas-cancelled-event-card] The calendar icon.
 * @part ncec__title - [nylas-cancelled-event-card] The title of the cancelled event card.
 * @part ncec__description - [nylas-cancelled-event-card] The description of the cancelled event card.
 * @part ncec__button-outline - [nylas-cancelled-event-card] The close button CTA.
 * @part ncec__card - [nylas-cancelled-event-card] The cancelled event card.
 * @part nbec - [nylas-booked-event-card] The booked event card host.
 * @part nbec__card - [nylas-booked-event-card] The booked event card.
 * @part nbec__title - [nylas-booked-event-card] The title of the booked event card.
 * @part nbec__description - [nylas-booked-event-card] The description of the booked event card.
 * @part nbec__button-outline - [nylas-booked-event-card] The cancel & reschedule button CTA.
 * @part nbec__cancel-cta - [nylas-booked-event-card] The cancel button CTA.
 * @part nbec__reschedule-cta - [nylas-booked-event-card] The reschedule button CTA.
 * @part nls - [nylas-locale-switch] The locale switch container.
 * @part nls__timezone - [nylas-locale-switch] The timezone select container
 * @part nls__timezone-dropdown - [nylas-locale-switch] The timezone dropdown
 * @part nls__timezone-drop-button - [nylas-locale-switch] The timezone dropdown button
 * @part nls__timezone-drop-button-selected-label - [nylas-locale-switch] The timezone dropdown button selected label
 * @part nls__timezone-drop-content - [nylas-locale-switch] The timezone dropdown content
 * @part nls__timezone-drop-label - [nylas-locale-switch] The timezone dropdown label
 * @part nls__language - [nylas-locale-switch] The language select container
 * @part nls__language-dropdown - [nylas-locale-switch] The language dropdown
 * @part nls__language-drop-button - [nylas-locale-switch] The language dropdown button
 * @part nls__language-drop-content - [nylas-locale-switch] The language dropdown content
 * @part nls__language-drop-label - [nylas-locale-switch] The language dropdown label
 * @part ncbf - [nylas-cancel-booking-form] The cancel booking form container.
 * @part ncbf__icon - [nylas-cancel-booking-form] The calendar icon.
 * @part ncbf__title - [nylas-cancel-booking-form] The title of the cancel booking form.
 * @part ncbf__description - [nylas-cancel-booking-form] The description of the cancel booking form.
 * @part ncbf__reason-textarea - [nylas-cancel-booking-form] The reason textarea.
 * @part ncbf__button-cta - [nylas-cancel-booking-form] The cancel booking form CTA button.
 * @part ncbf__button-outline - [nylas-cancel-booking-form] The cancel booking form outline button.
 * @part ncbf__card - [nylas-cancel-booking-form] The cancel booking form card.
 */
@Component({
  tag: 'nylas-scheduling',
  styleUrl: 'nylas-scheduling.scss',
  shadow: true,
})
export class NylasScheduling {
  /**
   * Stores to be used by the provider.
   */
  private stores?: { scheduler: NylasSchedulerStoreType };

  /**
   * This is a base provide that defines the common methods and properties
   * that all providers should have.
   *
   * Because stencil does not support abstract classes, we have to use a
   * property to store the base provider instance.
   */
  private baseProvider?: NylasBaseProvider<Exclude<typeof this.stores, undefined>>;

  /**
   * The Nylas Scheduler Connector instance.
   * The branins of the provider. It manages data fetching and state synchronization.
   */
  private nylasSchedulerConnector?: NylasSchedulerConnector;

  /**
   * The host element.
   * Used to manage the host element of the provider.
   */
  @Element() private host!: HTMLNylasSchedulingElement;

  /**
   * This is used to set the mode for the Nylas Scheduler.
   * The mode can be either `app` or `composable`. The default mode is `app`.
   * - `app`: This mode is used to show the default Nylas Scheduler UI.
   * - `composable`: This mode is used to show the composable Nylas Scheduler UI
   *    by passing the individual scheduler components as children.
   */
  @Prop() readonly mode: 'app' | 'composable' = 'app';

  /**
   * The default scheduler store state.
   * Used to set the initial state of the scheduler store.
   */
  @Prop({ attribute: 'default-scheduler-store-state' }) readonly defaultSchedulerState?: Partial<NylasSchedulerStoreState>;

  /**
   * The session ID for the Nylas Scheduler.
   * This is used to authenticate the user.
   */
  @Prop() readonly sessionId?: string;

  /**
   * The URL for the Nylas Scheduler API. (staging or production URL)
   */
  @Prop() readonly schedulerApiUrl: string = 'https://api.us.nylas.com';

  /**
   * This enables passing the bookingInfo object to the Scheduler UI for direct booking event creation.
   * When used with timeslotConfirmedHandler, it bypasses the additional data page and immediately
   * invokes the handler after booking.
   */
  @Prop() readonly bookingInfo?: NylasSchedulerBookingData;

  /**
   * Booking Ref required for rescheduling flow.
   */
  @Prop() readonly rescheduleBookingRef?: string;

  /**
   * Booking Ref required for cancelling flow.
   */
  @Prop() readonly cancelBookingRef?: string;

  /**
   * Booking Ref required for the manual confirmation flow.
   */
  @Prop() readonly organizerConfirmationBookingRef?: string;

  /**
   * The config ID for the Nylas Scheduler. This should be passed in when using a public config,
   * in which case the sessionId is not required.
   */
  @Prop() readonly configurationId?: string;

  /**
   * The slug of the configuration (public config) to be used for the Nylas Scheduler. This is used in conjunction with the clientId.
   * When the configurationId is not provided, the slug and clientId are used to make requests to the Scheduler API endpoints.
   * If the configurationId is provided, this prop will be ignored.
   */
  @Prop() readonly slug?: string;

  /**
   * The app ID of the configuration (public config) to be used for the Nylas Scheduler. This is used in conjunction with the slug.
   * When the configurationId is not provided, the slug and clientId are used to make requests to the Scheduler API endpoints.
   * If the configurationId is provided, this prop will be ignored.
   */
  @Prop() readonly clientId?: string;

  /**
   * This prop lets you hide the Nylas branding.
   * Default is true.
   */
  @Prop() readonly nylasBranding?: boolean = true;

  /**
   * This provides an easy way to override the default function of the event emitter.
   * An example of this is the `timeslotConfirmed` event. By default, this event will set the scheduler store state for `showBookingForm` to `true` which will
   * show the booking form. However, if you want to override this behavior, you can pass in the prop `eventOverride` like:
   * ```html
   * <nylas-scheduling eventOverride={{"timeslotConfirmed": (event, nylasConnector) => { console.log("Timeslot confirmed event fired!"); } }} />
   * ```
   */
  @Prop({ attribute: 'event-overrides' }) readonly eventOverrides: SchedulerEventOverride = {};

  /**
   * The loading state. This is used to set the loading state for the Nylas Scheduler when fetching data.
   */
  @Prop() readonly isLoading?: boolean;

  /**
   * Theme config, used to automatically generate a theme with color palette and
   * CSS variables to customize the look and feel of the Nylas Scheduler.
   */
  @Prop() readonly themeConfig?: ThemeConfig;

  /**
   * This prop will allow to override the default localization strings for each language.
   * Nylas scheduling page currently support the following language codes: en, es, fr, de, sv, zh, ja, nl.
   */
  @Prop() readonly localization?: Partial<Record<LANGUAGE_CODE, Locale>>;

  /*
   * Displays notifications for errors by default. When enabled (`true`), errors
   * automatically appear as notifications. Disabling this feature (`false`) allows
   * the parent application to capture and manage errors by listening to the
   * `nylasSchedulerError` event, providing flexibility in error handling.
   */
  @Prop() readonly showNotification?: boolean = true;

  @Prop() readonly enableUserFeedback?: boolean = true;

  /**
   * Automatically register components that have the `@RegisterComponent` decorator.
   * If this is set to false, you will need to manually register components using the
   * `registerComponent` method.
   * @default true
   */
  @State() automaticComponentRegistration: boolean = true;

  /**
   * The selected date label for the Nylas Scheduler.
   * This is used to set the initial date label for the Nylas Scheduler.
   */
  @State() selectedDateLabel: string = new Date().toLocaleDateString(undefined, { dateStyle: 'full' }) || `${i18next.t('noDateSelected')}`;

  /**
   * The config id extracted from the rescheduleBookingRef or cancelBookingRef or organizerConfirmationBookingRef.
   */
  @State() refConfigId?: string;

  /**
   * The selected language for the Nylas Scheduler.
   */
  @State() language: string = navigator.language;

  /**
   * The state for showing the feedback modal.
   */
  @State() showFeedbackModal: boolean = false;

  /**
   * The state of bookingInfo after the user has confirmed the booking.
   */
  @State() bookingInfoConfirmed: NylasSchedulerBookingDataWithFlatFields | null = null;

  /**
   * This event is fired when the provider is initialized.
   * It can be used to set the initial state of the provider,
   * or to prevent the provider from firing some default behavior.
   */
  @Event({ cancelable: true }) init!: EventEmitter<HTMLNylasSchedulingElement>;

  /**
   * This event is fired when the scheduler component enters componentWillLoad lifecycle.
   */
  @Event() schedulerWillLoad!: EventEmitter<HTMLNylasSchedulingElement>;

  /**
   * This event is fired when the scheduler component enters componentDidLoad lifecycle.
   */
  @Event() schedulerDidLoad!: EventEmitter<HTMLNylasSchedulingElement>;

  @Event({
    eventName: 'nylasSchedulerError',
  })
  nylasSchedulerError!: EventEmitter<{ notification: Notification; host: HTMLElement }>;

  @Event({
    eventName: 'configSettingsLoaded',
  })
  configSettingsLoaded!: EventEmitter<{ settings: NylasSchedulerResponse<UISettingsResponse> }>;

  /**
   * This event is triggered if either the rescheduleBookingRef or cancelBookingRef or organizerConfirmationBookingRef prop
   * is supplied and the component has been attached to the DOM. It emits the configurationId
   * and bookingId derived from the rescheduleBookingRef or cancelBookingRef or organizerConfirmationBookingRef. Subscribe to this
   * event to obtain the extracted configurationId, which is necessary to generate the sessionID
   * for configurations that are not public.
   */
  @Event() bookingRefExtracted!: EventEmitter<{ configurationId: string; bookingId: string; salt?: string }>;

  /**
   * This event is triggered on successful booking request. It emits the event data.
   */
  @Event() bookedEventInfo!: EventEmitter<NylasSchedulerResponse<NylasEvent>>;

  /**
   * This function is called when the component is connected to the DOM.
   * At this point in the component lifecycle, the base provider has not yet
   * been initialized. So avoid using the base provider in this method or stores
   * in this method.
   */
  connectedCallback() {
    debug(`[nylas-scheduler] connectedCallback`);
  }

  async componentWillLoad() {
    debug(`[nylas-scheduler] Component will load`);

    if (this.enableUserFeedback && !Sentry.isInitialized()) {
      Sentry.init({
        dsn: 'https://9d5731f1c77ca84c9ed3fb9b3ccf7ee1@o74852.ingest.us.sentry.io/4507889638178816',
        release: process.env.PACKAGE_VERSION,
        integrations: integrations => [
          ...integrations.filter(_integration => false), // Removes default integrations (including Breadcrumbs)
          Sentry.feedbackIntegration({
            colorScheme: 'system',
            autoInject: false,
          }),
        ],
        // Disable all other event capture
        autoSessionTracking: false, // Disable session tracking
        beforeSend: () => null, // Prevents error events from being sent
      });
    }

    // Emit the schedulerWillLoad event to allow the parent application to set the initial
    // state of the provider or to prevent the provider from firing some default behavior.
    const schedulerWillLoadEvent = this.schedulerWillLoad.emit(this.host);
    if ('schedulerWillLoad' in this.eventOverrides) {
      await this.eventOverrides.schedulerWillLoad(schedulerWillLoadEvent, this.nylasSchedulerConnector);
      if (schedulerWillLoadEvent.defaultPrevented) {
        return;
      }
    }

    this.stores = {
      scheduler: CreateNylasSchedulerStore({
        bookingInfo: this.bookingInfo,
        nylasBranding: this.nylasBranding,
        themeConfig: this.themeConfig,
        ...this.defaultSchedulerState,
      }),
    };

    this.baseProvider = new NylasBaseProvider(this.host, this.stores, this.automaticComponentRegistration, this.eventOverrides);

    // Set the configId to the reschedule configId if rescheduleBookingRef is provided or
    // Set the configId to the cancel configId if cancelBookingRef is provided
    this.refConfigId = this.configurationId;
    let bookingRefExtractedEvent;
    if (this.rescheduleBookingRef) {
      const [rescheduleConfigId, rescheduleBookingId, _] = compactStringToUUIDs(this.rescheduleBookingRef);
      this.refConfigId = rescheduleConfigId;
      this.stores.scheduler.set('rescheduleBookingId', rescheduleBookingId);
      bookingRefExtractedEvent = this.bookingRefExtracted.emit({ configurationId: rescheduleConfigId, bookingId: rescheduleBookingId });
    } else if (this.cancelBookingRef) {
      const [cancelConfigId, cancelBookingId, _] = compactStringToUUIDs(this.cancelBookingRef);
      this.refConfigId = cancelConfigId;
      this.stores.scheduler.set('cancelBookingId', cancelBookingId);
      bookingRefExtractedEvent = this.bookingRefExtracted.emit({ configurationId: cancelConfigId, bookingId: cancelBookingId });
    } else if (this.organizerConfirmationBookingRef) {
      const [organizerConfirmationConfigId, organizerConfirmationBookingId, salt] = compactStringToUUIDs(this.organizerConfirmationBookingRef);
      this.refConfigId = organizerConfirmationConfigId;
      this.stores.scheduler.set('organizerConfirmationBookingId', organizerConfirmationBookingId);
      this.stores.scheduler.set('organizerConfirmationSalt', salt);
      bookingRefExtractedEvent = this.bookingRefExtracted.emit({ configurationId: organizerConfirmationConfigId, bookingId: organizerConfirmationBookingId, salt: salt });
    }

    if ('bookingRefExtracted' in this.eventOverrides) {
      await this.eventOverrides.bookingRefExtracted(bookingRefExtractedEvent, this.nylasSchedulerConnector);
      if (bookingRefExtractedEvent.defaultPrevented) {
        return;
      }
    }

    if (!this.checkIfSessionIdOrConfigIdExists()) {
      return;
    }

    const nylasSchedulerStore = this.baseProvider?.getStore('scheduler');
    this.nylasSchedulerConnector = new NylasSchedulerConnector({
      schedulerAPIURL: this.schedulerApiUrl,
      sessionId: this.sessionId,
      configId: this.refConfigId,
      slug: this.slug,
      clientId: this.clientId,
      nylasSchedulerStore,
    });

    const initEvent = this.init.emit(this.host);
    if ('init' in this.eventOverrides) {
      await this.eventOverrides.init(initEvent, this.nylasSchedulerConnector);
      if (initEvent.defaultPrevented) {
        return;
      }
    }

    this.baseProvider?.componentWillLoad(this.nylasSchedulerConnector);
    // Override theme
    this.applyThemeConfig(this.themeConfig);
  }

  async componentDidLoad() {
    this.baseProvider?.componentDidLoad();

    const schedulerDidLoadEvent = this.schedulerDidLoad.emit(this.host);
    if ('schedulerDidLoad' in this.eventOverrides) {
      await this.eventOverrides.schedulerDidLoad(schedulerDidLoadEvent, this.nylasSchedulerConnector);
      if (schedulerDidLoadEvent.defaultPrevented) {
        return;
      }
    }

    // If sessionId is not provided, do not fetch availability
    if (!this.checkIfSessionIdOrConfigIdExists()) {
      return;
    }
    this.localizationChanged(this.localization);
    const settingsResponse = await this.nylasSchedulerConnector?.scheduler.getUISettings();
    if (!settingsResponse || 'error' in settingsResponse) {
      this.nylasSchedulerError.emit({ notification: { ...this.getErrorObject(settingsResponse?.error) }, host: this.host });
      return;
    } else {
      if ('data' in settingsResponse && settingsResponse.data?.appearance) {
        console.info(
          'Appearance settings have been returned from the configuration used byt his scheduling page; please remember to grab them from the configSettingsLoaded event and apply them as desired.',
        );
      }
      const configSettingsLoadedEvent = this.configSettingsLoaded.emit({ settings: settingsResponse });
      if ('configSettingsLoaded' in this.eventOverrides) {
        await this.eventOverrides.configSettingsLoaded(configSettingsLoadedEvent, this.nylasSchedulerConnector);
        if (configSettingsLoadedEvent.defaultPrevented) {
          return;
        }
      }
    }

    const nylasSchedulerStore = this.baseProvider?.getStore('scheduler');
    const availableDaysInFuture = nylasSchedulerStore?.get('configSettings')?.scheduler?.available_days_in_future;
    const selectedTimeslot = nylasSchedulerStore?.get('selectedTimeslot');

    if (selectedTimeslot && selectedTimeslot?.start_time) {
      if (!this.isTimeslotValid(selectedTimeslot)) {
        return;
      }

      // If the selected timeslot is already set, do not fetch availability
      if (nylasSchedulerStore?.get('showBookingForm')) {
        nylasSchedulerStore?.set('selectedDate', selectedTimeslot.start_time);
        return;
      }
    }

    const selectedDate = nylasSchedulerStore?.get('selectedDate');
    const availability = nylasSchedulerStore?.get('availability');
    let result;

    if (availability && availability.length > 0) {
      return;
    }

    if (!selectedDate) {
      const today = new Date();
      const { startTime, endTime, endTimeForAvailableDaysInFuture } = this.validateAvailableDaysInFuture(today, availableDaysInFuture || 30);
      if (today.getTime() < endTimeForAvailableDaysInFuture * 1000) {
        result = await this.nylasSchedulerConnector?.scheduler.getAvailability(startTime, endTime);
      }
    } else {
      const { startTime, endTime, endTimeForAvailableDaysInFuture } = this.validateAvailableDaysInFuture(selectedDate, availableDaysInFuture || 30);
      if (selectedDate.getTime() < endTimeForAvailableDaysInFuture * 1000) {
        result = await this.nylasSchedulerConnector?.scheduler.getAvailability(startTime, endTime);
      }
    }

    if (!result || 'error' in result) {
      this.nylasSchedulerError.emit({ notification: { ...this.getErrorObject(result?.error) }, host: this.host });
    }

    if (!selectedDate) {
      let _selectedDate = new Date();
      const firstAvailableDate = nylasSchedulerStore?.get('availability').find((timeslot: any) => new Date(timeslot.start_time) > new Date());
      if (firstAvailableDate) {
        _selectedDate = firstAvailableDate.start_time;
      }
      nylasSchedulerStore?.set('selectedDate', _selectedDate);
    }
  }

  componentDisconnected() {
    this.baseProvider?.componentDisconnected();
  }

  @Watch('bookingInfo')
  bookingInfoChanged(newVal?: NylasSchedulerBookingData) {
    this.stores?.scheduler.set('bookingInfo', newVal);
  }

  @Watch('rescheduleBookingRef')
  rescheduleBookingRefChanged(newVal?: string) {
    if (newVal) {
      const [configId, bookingId] = compactStringToUUIDs(newVal);
      this.stores?.scheduler.set('rescheduleBookingId', bookingId);
      this.refConfigId = configId;
      this.nylasSchedulerConnector?.scheduler.setConfigId(configId);
    }
  }

  @Watch('cancelBookingRef')
  cancelBookingRefChanged(newVal?: string) {
    if (newVal) {
      const [configId, bookingId] = compactStringToUUIDs(newVal);
      this.stores?.scheduler.set('cancelBookingId', bookingId);
      this.refConfigId = configId;
      this.nylasSchedulerConnector?.scheduler.setConfigId(configId);
    }
  }

  @Watch('organizerConfirmationBookingRef')
  organizerConfirmationBookingRefChanged(newVal?: string) {
    if (newVal) {
      const [configId, bookingId] = compactStringToUUIDs(newVal);
      this.stores?.scheduler.set('organizerConfirmationBookingId', bookingId);
      this.refConfigId = configId;
      this.nylasSchedulerConnector?.scheduler.setConfigId(configId);
    }
  }

  @Watch('localization')
  localizationChanged(newVal?: Partial<Record<LANGUAGE_CODE, Locale>>) {
    if (!newVal) {
      return;
    }

    Object.keys(newVal).forEach(lang => {
      if (!(lang in LANGUAGE_CODE)) {
        return;
      }
      i18next.addResourceBundle(
        lang,
        'translation',
        newVal[lang],
        false, // Merge with existing translations
        true, // Replace the existing translations with the same key
      );
      this.language = this.language;
    });
  }

  @Watch('themeConfig')
  themeConfigChanged(newVal?: ThemeConfig) {
    // Set the theme config in the store, hosted page will use this to apply/re-apply the theme
    this.baseProvider?.getStore('scheduler')?.set('themeConfig', newVal);
    this.applyThemeConfig(newVal);
  }

  @Listen('languageChanged')
  languageChanged(event: CustomEvent<string>) {
    const newLanguage = event.detail;
    this.language = newLanguage;
    i18next.changeLanguage(newLanguage);
  }

  /**
   * This is a custom event handler that is used to register a component with the provider.
   * It is used by components that have the `@RegisterComponent` decorator.
   * @param event A custom event that contains the component to register
   * @returns Promise<void>
   */
  @Listen('registerComponent')
  async registerComponentHandler(event: CustomEvent<RegisteredComponent<any, any>>): Promise<void> {
    this.baseProvider?.registerComponent(event.detail);
  }

  /**
   * This is a custom event handler that is used to unregister a component with the provider.
   * It is used by components that have the `@RegisterComponent` decorator.
   * @param event A custom event that contains the component to unregister
   * @returns Promise<void>
   */
  @Listen('unregisterComponent')
  async unregisterComponentHandler(event: CustomEvent<RegisteredComponent<any, any>>): Promise<void> {
    this.baseProvider?.unregisterComponent(event.detail);
  }

  /**
   * This is a custom event handler that is used to refetch the availability when the month is changed.
   */
  @Listen('monthChanged')
  async monthChangedHandler(event: CustomEvent<Date>) {
    const date = event.detail;
    const availableDaysInFuture = this.nylasSchedulerConnector?.schedulerStore?.get('configSettings')?.scheduler?.available_days_in_future;
    const { startTime, endTime, endTimeForAvailableDaysInFuture } = this.validateAvailableDaysInFuture(date, availableDaysInFuture || 30);

    if (startTime < endTimeForAvailableDaysInFuture && date.getTime() < endTimeForAvailableDaysInFuture * 1000) {
      const result = await this.nylasSchedulerConnector?.scheduler.getAvailability(startTime, endTime);

      if (!result || 'error' in result) {
        this.nylasSchedulerError.emit({ notification: { ...this.getErrorObject(result?.error) }, host: this.host });
      }
      const selectableDates = this.stores?.scheduler.get('selectableDates');
      if (selectableDates && selectableDates.length > 0) {
        this.stores?.scheduler.set('selectedDate', selectableDates[0]);
      }
    }
  }

  mapToBookingData(bookingInfo: NylasSchedulerBookingDataWithFlatFields): any {
    const timeslot = this.stores?.scheduler.get('selectedTimeslot');
    const language = this.stores?.scheduler.get('selectedLanguage');
    const timezone = this.stores?.scheduler.get('selectedTimezone');
    const startTime = timeslot?.start_time ? new Date(timeslot?.start_time).getTime() / 1000 : '';
    const endTime = timeslot?.end_time ? new Date(timeslot?.end_time).getTime() / 1000 : '';
    return {
      additional_guests: bookingInfo.guests,
      guest: { ...bookingInfo.primaryParticipant },
      additional_fields: { ...bookingInfo.additionalFields },
      start_time: startTime,
      end_time: endTime,
      email_language: language,
      timezone: timezone,
    };
  }

  /**
   * This is a custom event handler to book an event.
   */
  @Listen('detailsConfirmed')
  async bookButtonClickedHandler(event: CustomEvent<NylasSchedulerBookingDataWithFlatFields>) {
    if (event.defaultPrevented) {
      return;
    }
    const bookingData = event.detail;
    const mappedBookingData = this.mapToBookingData(bookingData);
    this.bookingInfoConfirmed = bookingData;

    const storeRescheduleId = this.nylasSchedulerConnector?.schedulerStore?.get('rescheduleBookingId');
    const rescheduleBookingId = storeRescheduleId;
    if (rescheduleBookingId) {
      const result = await this.nylasSchedulerConnector?.scheduler.rescheduleBooking(rescheduleBookingId, bookingData);
      if (!result || 'error' in result) {
        this.nylasSchedulerError.emit({ notification: { ...this.getErrorObject(result?.error) }, host: this.host });
      }
      const resultData = (result as NylasSuccessResponse<NylasEvent>)?.data;
      const bookedEventInfoEvent = this.bookedEventInfo.emit({ ...result, data: { ...resultData, ...mappedBookingData } });
      if ('bookedEventInfo' in this.eventOverrides) {
        await this.eventOverrides.bookedEventInfo(bookedEventInfoEvent, this.nylasSchedulerConnector);
      }
      return;
    }
    const result = await this.nylasSchedulerConnector?.scheduler.bookTimeslot(bookingData);
    if (!result || 'error' in result) {
      this.nylasSchedulerError.emit({ notification: { ...this.getErrorObject(result?.error) }, host: this.host });
    }
    const resultData = (result as NylasSuccessResponse<NylasEvent>)?.data;
    const bookedEventInfoEvent = this.bookedEventInfo.emit({ ...result, data: { ...resultData, ...mappedBookingData } });
    if ('bookedEventInfo' in this.eventOverrides) {
      await this.eventOverrides.bookedEventInfo(bookedEventInfoEvent, this.nylasSchedulerConnector);
    }
  }

  /**
   * This is a custom event handler to update the selected date label.
   */
  @Listen('dateSelected')
  dateSelectedHandler(event: CustomEvent<Date>) {
    const date = event.detail;
    this.selectedDateLabel = date ? date.toLocaleDateString(undefined, { dateStyle: 'full' }) : `${i18next.t('noDateSelected')}`;
  }

  /**
   * This is an event handler to handle the error (if any) when the
   * cancel booking button is clicked on the cancel booking form.
   */
  @Listen('cancelBookedEventError')
  cancelBookedEventErrorHandler(event: CustomEvent<NylasSchedulerErrorResponse>) {
    this.nylasSchedulerError.emit({ notification: { ...this.getErrorObject(event.detail.error) }, host: this.host });
  }

  @Listen('cancelBookedEventValidationError')
  cancelBookedEventValidationErrorHandler(event: CustomEvent<NylasSchedulerErrorResponse>) {
    this.nylasSchedulerError.emit({ notification: { ...this.getErrorObject(event.detail.error) }, host: this.host });
  }

  /**
   * This is an event handler to handle the error (if any) when the
   * reschedule button is clicked on the booked event card.
   */
  @Listen('rescheduleBookedEventError')
  rescheduleBookedEventErrorHandler(event: CustomEvent<NylasSchedulerErrorResponse>) {
    this.nylasSchedulerError.emit({ notification: { ...this.getErrorObject(event.detail.error) }, host: this.host });
  }

  /**
   * This is an event handler to handle the error (if any) when the
   * manual confirmation button is clicked on the manual confirmation card.
   */
  @Listen('confirmBookingError')
  confirmBookingErrorHandler(event: CustomEvent<NylasSchedulerErrorResponse>) {
    this.nylasSchedulerError.emit({ notification: { ...this.getErrorObject(event.detail.error) }, host: this.host });
  }

  @Listen('feedbackModalClosed')
  feedbackModalClosedHandler() {
    this.showFeedbackModal = false;
  }

  @Listen('feedbackSubmitted')
  feedbackSubmittedHandler(event: CustomEvent<{ feedback: string }>) {
    const eventId = Sentry.captureMessage('Scheduling Page User Feedback');
    const feedback = {
      eventId: eventId,
      message: event.detail.feedback,
    };
    Sentry.captureFeedback(feedback, {
      includeReplay: true,
      captureContext: {
        tags: {
          'nylas-web-element': 'nylas-schduling',
          'nylas-web-element-version': process.env.PACKAGE_VERSION,
        },
        extra: {
          configId: this.configurationId,
          slug: this.slug,
        },
      },
    });
    this.showFeedbackModal = false;
  }

  /**
   * This method is used to retrieve the NylasScheduler instance
   * @returns The NylasScheduler instance
   */
  @Method()
  async getNylasSchedulerStore(): Promise<NylasSchedulerStoreType | undefined> {
    return this.baseProvider?.getStore('scheduler');
  }

  /**
   * This method is used to retrieve the NylasConnector instance
   * @returns The NylasConnector instance
   */
  @Method()
  async getNylasSchedulerConnector() {
    return this.nylasSchedulerConnector;
  }

  @Method()
  async getRef() {
    return this.host;
  }

  private getErrorObject(error: NylasSchedulerErrorResponse['error']): Notification {
    return {
      title: error?.title || genericError.title,
      category: error?.category || genericError.category,
      type: NotificationType.Error,
      description: error?.message || '',
      id: new Date().getTime().toString(),
      ttl: 'none',
    };
  }
  private checkIfSessionIdOrConfigIdExists(): boolean {
    if (!this.sessionId && !this.refConfigId && !(this.slug && this.clientId)) {
      let description = '';

      if (!this.sessionId) {
        description += i18next.t('sessionIdRequiredErrorMessage');
      }

      if (!this.sessionId && !this.refConfigId && !(this.slug && this.clientId)) {
        description = i18next.t('sessionIdRequiredErrorMessage') + ' ' + i18next.t('publicConfigErrorMessage');
      }

      this.nylasSchedulerError.emit({
        notification: {
          title: i18next.t('schedulingComponentErrorTitle'),
          category: ErrorCategory.Component,
          type: NotificationType.Error,
          description: description,
          id: new Date().getTime().toString(),
          ttl: 'none',
        },
        host: this.host,
      });
      return false;
    }
    return true;
  }

  private isTimeslotValid(timeslot: Timeslot): boolean {
    const selectedDate = new Date(timeslot.start_time);
    if (selectedDate < new Date()) {
      this.nylasSchedulerError.emit({
        notification: {
          title: i18next.t('invalidTimeslotErrorTitle'),
          category: ErrorCategory.Component,
          type: NotificationType.Error,
          description: i18next.t('invalidTimeslotErrorMessage'),
          id: new Date().getTime().toString(),
          ttl: 'none',
        },
        host: this.host,
      });
      return false;
    }
    return true;
  }

  private validateAvailableDaysInFuture(
    startDate: Date,
    availableDaysInFuture: number,
  ): {
    startTime: number;
    endTime: number;
    endTimeForAvailableDaysInFuture: number;
  } {
    const today = new Date();
    const startTime = new Date(startDate.getFullYear(), startDate.getMonth(), 1).getTime() / 1000;
    const endTimeForCurrentMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1).getTime() / 1000;
    const endTimeForAvailableDaysInFuture = Math.floor(addDaysToCurrentDate(today, availableDaysInFuture).getTime() / 1000);
    const endTime = Math.min(endTimeForAvailableDaysInFuture, endTimeForCurrentMonth);
    const startTimeWithOffset = startTime < today.getTime() / 1000 ? Math.floor(today.getTime() / 1000) : startTime;
    return { startTime: startTimeWithOffset, endTime, endTimeForAvailableDaysInFuture };
  }

  private applyThemeConfig(themeConfig?: ThemeConfig) {
    if (themeConfig) {
      for (const [key, value] of Object.entries(themeConfig)) {
        this.host.style.setProperty(`${key}`, value);
      }
    }
  }

  onFeedbackClick(e: Event) {
    e.preventDefault();
    this.showFeedbackModal = true;
  }

  redirectToCustomUrl(url: string) {
    window.location.assign(url);
  }

  /**
   * @slot timeslot-picker-cta-label - This slot is used to pass a custom label to the timeslot picker CTA. The default label is "Next".
   * @slot custom-booking-form - This slot is used to pass a custom booking form to the Nylas Scheduling component.
   * @deprecated  Deprecated in favor of using the additional fields defined in the config settings.
   */
  render() {
    const showDefaultScheduler = this.mode === 'app';
    const nylasSchedulerStore = this.baseProvider?.getStore('scheduler');
    const eventInfo = nylasSchedulerStore?.get('eventInfo');
    const showBookingForm = nylasSchedulerStore?.get('showBookingForm');
    const cancelledEventInfo = nylasSchedulerStore?.get('cancelledEventInfo');
    const cancelBookingId = nylasSchedulerStore?.get('cancelBookingId');
    const selectedTimeslot = nylasSchedulerStore?.get('selectedTimeslot') as Timeslot;
    const rescheduleBookingId = nylasSchedulerStore?.get('rescheduleBookingId');
    const organizerConfirmationBookingId = nylasSchedulerStore?.get('organizerConfirmationBookingId');
    const rejectBookingId = nylasSchedulerStore?.get('rejectBookingId');
    const confirmedEventInfo = nylasSchedulerStore?.get('confirmedEventInfo');
    const nylasBranding = nylasSchedulerStore?.get('nylasBranding') || this.nylasBranding;
    const selectedDate = nylasSchedulerStore?.get('selectedDate');
    const redirectUrl = nylasSchedulerStore?.get('configSettings')?.scheduler?.confirmation_redirect_url;
    this.selectedDateLabel =
      selectedDate && this.language
        ? capitalizeFirstLetter(selectedDate.toLocaleDateString(LANGUAGE_CODE_MAP[this.language], { dateStyle: 'full' }))
        : `${i18next.t('noDateSelected')}`;

    if (showDefaultScheduler && (cancelBookingId || rejectBookingId) && !cancelledEventInfo) {
      return (
        <Host>
          <SchedulerView nylasBranding={nylasBranding} isLoading={this.isLoading} showNotification={this.showNotification}>
            <div class="cancel-flow-page" part="cancel-flow-page">
              <nylas-cancel-booking-form
                selectedTimeslot={selectedTimeslot}
                cancelBookingId={cancelBookingId}
                rejectBookingId={rejectBookingId}
                exportparts="ncbf, ncbf__icon, ncbf__title, ncbf__description, ncbf__reason-textarea, ncbf__button-cta, ncbf__button-outline, ncbf__card"
              />
            </div>
          </SchedulerView>
          {this.enableUserFeedback && (
            <button-component id="report-issue" variant={'basic'} onClick={e => this.onFeedbackClick(e)}>
              <feedback-icon />
            </button-component>
          )}
          {this.showFeedbackModal && <nylas-feedback-form></nylas-feedback-form>}
        </Host>
      );
    }

    // Manual confirmation page is shown when the event is manually confirmed
    if (showDefaultScheduler && organizerConfirmationBookingId) {
      return (
        <Host>
          <SchedulerView>
            <div class="manual-confirmation-page" part="manual-confirmation-page">
              <nylas-organizer-confirmation-card
                organizerConfirmationBookingId={organizerConfirmationBookingId}
                exportparts="nmcc, nmcc__title, nmcc__description, nmcc__button-cta, nmcc__button-outline"
              />
            </div>
          </SchedulerView>
          {this.enableUserFeedback && (
            <button-component id="report-issue" variant={'basic'} onClick={e => this.onFeedbackClick(e)}>
              <feedback-icon />
            </button-component>
          )}
          {this.showFeedbackModal && <nylas-feedback-form></nylas-feedback-form>}
        </Host>
      );
    }

    // Cancelled event card is shown when the event is cancelled
    if (showDefaultScheduler && cancelledEventInfo) {
      return (
        <Host>
          <SchedulerView nylasBranding={nylasBranding} isLoading={this.isLoading} showNotification={this.showNotification}>
            <div class="cancelled-event-page" part="cancelled-event-page">
              <nylas-cancelled-event-card
                cancelledEventInfo={cancelledEventInfo}
                exportparts="ncec, ncec__icon, ncec__title, ncec__description, ncec__button-outline, ncec__card"
              />
            </div>
          </SchedulerView>
          {this.enableUserFeedback && (
            <button-component id="report-issue" variant={'basic'} onClick={e => this.onFeedbackClick(e)}>
              <feedback-icon />
            </button-component>
          )}
          {this.showFeedbackModal && <nylas-feedback-form></nylas-feedback-form>}
        </Host>
      );
    }

    // Confirmed event card is shown when the event is confirmed
    if (showDefaultScheduler && confirmedEventInfo) {
      return (
        <Host>
          <SchedulerView nylasBranding={nylasBranding} isLoading={this.isLoading} showNotification={this.showNotification}>
            <div class="cancelled-event-page" part="confirmed-event-page">
              <nylas-confirmed-event-card
                confirmedEventInfo={confirmedEventInfo}
                exportparts="ncec, ncec__icon, ncec__title, ncec__description, ncec__button-outline, ncec__card"
              />
            </div>
          </SchedulerView>
          {this.enableUserFeedback && (
            <button-component id="report-issue" variant={'basic'} onClick={e => this.onFeedbackClick(e)}>
              <feedback-icon />
            </button-component>
          )}
          {this.showFeedbackModal && <nylas-feedback-form></nylas-feedback-form>}
        </Host>
      );
    }

    // Redirected to custom URL when the event is booked and redirect URL is provided
    if (showDefaultScheduler && eventInfo && redirectUrl) {
      const recipient = this.bookingInfoConfirmed?.primaryParticipant;
      const additionalFields = this.bookingInfoConfirmed?.additionalFields || {};
      if (this.bookingInfo && typeof this.bookingInfo?.additionalFields !== 'undefined') {
        const bookingInfoAdditionalFields = this.bookingInfo.additionalFields;
        // Merge additional fields from the bookingInfo with the additional fields from the booking form
        Object.keys(bookingInfoAdditionalFields).forEach(key => {
          if (!additionalFields[key] && bookingInfoAdditionalFields?.[key]) {
            additionalFields[key] = bookingInfoAdditionalFields[key].value;
          }
        });
      }
      const selectedTimeslot = this.stores?.scheduler.get('selectedTimeslot');
      const selectedTimezone = this.stores?.scheduler.get('selectedTimezone');
      const selectedLanguage = this.stores?.scheduler.get('selectedLanguage');
      const configSettings = this.stores?.scheduler.get('configSettings');

      const configurationId = this.configurationId ? this.configurationId : configSettings?.configuration_id;
      const queryString = window.location.search;

      const urlParams = new URLSearchParams(queryString);
      urlParams.set('booking_id', eventInfo.booking_id);
      recipient?.name && urlParams.set('name', recipient.name);
      recipient?.email && urlParams.set('email', recipient.email);
      selectedTimeslot?.start_time && urlParams.set('start_time', (new Date(selectedTimeslot.start_time).getTime() / 1000).toString());
      selectedTimeslot?.end_time && urlParams.set('end_time', (new Date(selectedTimeslot.end_time).getTime() / 1000).toString());
      selectedTimezone && urlParams.set('tz', selectedTimezone);
      selectedLanguage && urlParams.set('language', selectedLanguage);
      additionalFields && urlParams.set('additional_values', JSON.stringify(additionalFields));
      this.slug && urlParams.set('page_slug', this.slug);
      configurationId && urlParams.set('config_id', configurationId);

      this.redirectToCustomUrl(`${redirectUrl}?${urlParams.toString()}`);
      return;
    }

    // Booked event card is shown when the event is booked or booking is sent
    if (showDefaultScheduler && eventInfo) {
      return (
        <Host>
          <SchedulerView nylasBranding={nylasBranding} isLoading={this.isLoading} showNotification={this.showNotification}>
            <div class="booked-event-page" part="booked-event-page">
              <nylas-booked-event-card
                selectedTimeslot={selectedTimeslot}
                eventInfo={eventInfo}
                exportparts="nbec, nbec__title, nbec__card, nbec__description, nbec__button-outline, nbec__cancel-cta, nbec__reschedule-cta"
              />
            </div>
          </SchedulerView>
          {this.enableUserFeedback && (
            <button-component id="report-issue" variant={'basic'} onClick={e => this.onFeedbackClick(e)}>
              <feedback-icon />
            </button-component>
          )}
          {this.showFeedbackModal && <nylas-feedback-form></nylas-feedback-form>}
        </Host>
      );
    }
    // Date and time picker is shown when the event is not booked and no additional data is shown
    if (showDefaultScheduler && (!eventInfo || !!rescheduleBookingId) && !showBookingForm) {
      const timeslotPickerCTALabel = this.host.querySelector('[slot="timeslot-picker-cta-label"]');
      return (
        <Host>
          <SchedulerView nylasBranding={nylasBranding} isLoading={this.isLoading} showNotification={this.showNotification}>
            {rescheduleBookingId && <MessageBanner>{`${i18next.t('rescheduleTitle')}`}</MessageBanner>}
            <div class="select-date-page" part="select-date-page">
              <div class="left-panel">
                <nylas-date-picker exportparts="ndp, ndp__title, ndp__month-header, ndp__month-button, ndp__day, ndp__date, ndp__date--disabled, ndp__date--selected, ndp__date--current-day, ndp__date--current-month"></nylas-date-picker>
                <nylas-locale-switch exportparts="nls, nls__timezone, nls__timezone-dropdown, nls__timezone-drop-button, nls__timezone-drop-button-selected-label, nls__timezone-drop-content, nls__timezone-drop-label, nls__language, nls__language-dropdown, nls__language-drop-button, nls__language-drop-content, nls__language-drop-label"></nylas-locale-switch>
              </div>
              <div class={`right-panel ${rescheduleBookingId ? 'reschedule' : ''}`}>
                <h2>
                  <calendar-icon></calendar-icon>
                  <span id="selectedDate">{this.selectedDateLabel}</span>
                </h2>
                <nylas-timeslot-picker exportparts="ntp, ntp__timeslot, ntp__timeslot--selected, ntp__button-primary">
                  {timeslotPickerCTALabel && (
                    <span slot="timeslot-picker-cta-label">
                      <slot name="timeslot-picker-cta-label"></slot>
                    </span>
                  )}
                </nylas-timeslot-picker>
              </div>
            </div>
          </SchedulerView>
          {this.enableUserFeedback && (
            <button-component id="report-issue" variant={'basic'} onClick={e => this.onFeedbackClick(e)}>
              <feedback-icon />
            </button-component>
          )}
          {this.showFeedbackModal && <nylas-feedback-form></nylas-feedback-form>}
        </Host>
      );
    }
    // Additional data is shown when the time is selected, event is not booked and additional data is shown
    if (showDefaultScheduler && !eventInfo && showBookingForm) {
      return (
        <Host>
          <SchedulerView nylasBranding={nylasBranding} isLoading={this.isLoading} showNotification={this.showNotification}>
            {rescheduleBookingId && <MessageBanner>{`${i18next.t('rescheduleTitle')}`}</MessageBanner>}
            <div class="additional-data-page" part="additional-data-page">
              <div class="left-panel">
                <div class="wrapper">
                  <nylas-selected-event-card exportparts="nsec, nsec__card, nsec__icon, nsec__date, nsec__time, nsec__timezone"></nylas-selected-event-card>
                </div>
              </div>
              <div class={`right-panel ${rescheduleBookingId ? 'reschedule' : ''}`}>
                <nylas-booking-form exportparts="nbf, nbf__input-textfield, nbf__button-ghost, nbf__button-outline, nbf__button-primary, nbf__input-wrapper, nbf__checkbox-component, nbf__radio-button-group, nbf__textarea-component, nbf__dropdown, nbf__dropdown-button, nbf__dropdown-content"></nylas-booking-form>
              </div>
            </div>
          </SchedulerView>
          {this.enableUserFeedback && (
            <button-component id="report-issue" variant={'basic'} onClick={e => this.onFeedbackClick(e)}>
              <feedback-icon />
            </button-component>
          )}
          {this.showFeedbackModal && <nylas-feedback-form></nylas-feedback-form>}
        </Host>
      );
    }

    return (
      <Host>
        <slot />
      </Host>
    );
  }
}
